import React from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

import '../Home/Home.css'

import './Contato.css'

import AllLogosHome from '../../components/AllLogosHome/AllLogosHome'
import Footer from '../../components/Footer/Footer'

import { Link, useHistory } from 'react-router-dom'

import { logout } from '../../services/auth'

import { useEffect, useState } from 'react'
import {useForm} from 'react-hook-form'

import api from '../../services/api'

import Modal from '../../components/Modal/Modal'
import Menu from '../../components/Menu/Menu'

import Regulamento from '../../components/Regulamento/Regulamento'
import MenuMobile from '../../components/MenuMobile/MenuMobile'

import contatoImg from '../../assets/images/sama/contato.png'

import ReCAPTCHA from "react-google-recaptcha";
import spinner from '../../assets/images/spinner.svg'

import {isAuthenticated} from '../../services/auth'

export default function Home(props){
    const history = useHistory()
    const [classNameOpenMenu, setClassNameOpenMenu] = useState("hideMenu")
    const [activeClass, setActiveClass] = useState(false)
    const [activeClassMobile, setActiveClassMobile] = useState(false)


    const {register, setValue, handleSubmit, formState:{errors, isValid} } = useForm({mode:"all"});
    const [profile, setProfile] = useState({});
    const [AddDarkBarMenuMobile, setAddDarkBarMenuMobile] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [isOpenModalSucess, setIsOpenModalSucess] = useState(false)
    const [sucessMessage, setSucessMessage] = useState('')
    const [messageTitile, setMessageTittle] = useState('')
    const [isToggleMobile, setIsToggleMobile] = useState(false);
    const [isOpenRegulamento, setIsOpenRegulamento] = useState(false)
    const [captcha, setCaptcha] = useState(false)



    useEffect(()=>{

        

        if(props.location.pathname === '/contato'){
            window.scrollTo(0, 0)
        }

            
        window.onscroll = () => handleScroll()   

        const token = localStorage.getItem("sama-token");
        const headers = {
            'Authorization': 'Bearer' + token
        }  
        async function loadApiProfle(){


            try{
                const response = await api.get(`/v1/account/profile`,{
                    headers: headers
                })  
                
                setProfile(response.data.data)
                setValue("company", `${response.data.data.company}`)
                setValue("email", `${response.data.data.email}`)
            }catch(e){
                if(e.response?.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            
        }
        loadApiProfle();

        setTimeout(() => {
            const ghostDiv = document.querySelector('.space_fix')
            if (!ghostDiv) {
                const textSlider = document.querySelector('#text-slider')
                textSlider.classList.add('mt-ghost-div')
            }
        }, 500)

    }, [])
    // console.log(profile)

    const handleLogout = function(){
        if(localStorage.getItem("sama-token")){  
            logout()
        }
    }

    function onClickCloseError(){
        setIsOpen(false)
    }
    function onClickOpen(){
        setIsOpen(true)
    }
    function handleScroll() { 
        if (document.documentElement.scrollTop >= 30) {
            setAddDarkBarMenuMobile(true)
            setClassNameOpenMenu('showMenu d-none d-sm-none d-md-block d-lg-block')

         }else{
             setClassNameOpenMenu('hideMenu')
             setAddDarkBarMenuMobile(false)
         }

         if(document.documentElement.scrollTop >= 750 && document.documentElement.scrollTop < 1700){
            setActiveClass(true)
        }else{
            setActiveClass(false)
        }
         if(document.documentElement.scrollTop >= 400 && document.documentElement.scrollTop < 1700){
            setActiveClassMobile(true)
        }else{
            setActiveClassMobile(false)
        }
    }




    const onSubmitContato = async function(data){
        setLoading(true)
        try{
            const response = await api.post(`/v1/contact`, data)

            setIsOpenModalSucess(true)
            setMessageTittle(response.data.title)
            setSucessMessage(response.data.message)
            setValue("message", "")
        }catch(error){
            setMessageTittle(error.response.data.data.message)
            setMessageError(error.response.data.message)
            setIsOpen(true)
        }finally{
            setLoading(false)
        }
    }
    function onClickCloseSucessModal(){
        setIsOpenModalSucess(false)
        

    }


    function onClickOpen(){
        setIsOpenRegulamento(true)
        setIsToggleMobile(prevToggle => !prevToggle)
    }

    function onClickCloseRegulamento(){
        setIsOpenRegulamento(false)
        

    }
    function toggleMobile(){
        setIsToggleMobile(prevToggle => !prevToggle)
    }
    function handleChangeCaptcha() {

        setCaptcha(prevToggle => !prevToggle)
        // do whatever you want with isChecked value
    }


    return(
        <>
        
            {/* MENU MOBILE */}
            {/* <div className={`${AddDarkBarMenuMobile ? 'addDark': 'clearDark'} ${document.documentElement.scrollTop >= 30 ? 'fixed-menu' : ''}`}> */}
            <div className={`${AddDarkBarMenuMobile ? 'addDark': 'clearDark'} fixed-menu`}>
            <MenuMobile>
            <ul className="navbar-nav me-auto mb-lg-0 ">
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <a href="/inicio#como-participar"><span>COMO PARTICIPAR</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li ">
                        <div className="mobile_item">
                            <a  href="/ganhadores"><span>GANHADORES</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item" >
                        <Link onClick={()=> onClickOpen()} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-expanded="true" aria-label="Toggle navigation"><span>REGULAMENTO</span></Link>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li ">
                        <div className="mobile_item">
                            <a className="active" href="/contato"><span>CONTATO</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <Link aria-current="page" to="/seus-numeros-da-sorte"><span>SEUS NÚMEROS DA SORTE</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item" >
                            
                            <Link   onClick={handleLogout} to="/#"><span>SAIR</span></Link>
                        </div>
                    </li>
                </ul>                
            </MenuMobile>
            </div>
            <div className="space_fix d-block d-lg-none"></div>
            <div id="menuHeader" className={`${classNameOpenMenu} d-none d-sm-none d-md-block d-lg-block`}>
                <Menu>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu_home">
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <a className={`menu-link`} aria-current="page" href="/inicio#como-participar"><span>COMO PARTICIPAR</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <a className="menu-link" href="/ganhadores"><span>GANHADORES</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div  className="div-one">
                            <Link className="menu-link" onClick={()=> onClickOpen()}><span>REGULAMENTO</span></Link>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <a className="menu-link active" href="/contato"><span>CONTATO</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <Link className="menu-link" aria-current="page" to="/seus-numeros-da-sorte"><span>SEUS NÚMEROS DA SORTE</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div  className="div-one">
                            
                            <Link className="menu-link" onClick={handleLogout} to="/#"><span>SAIR</span></Link>
                        </div>
                    </li>
                </ul>
                </Menu>
            </div>
            
        <div className="contato">
            <img src={contatoImg} alt="Contato" />
            <h1>Contato</h1>
            <p>Fale com a Sama</p>
            <p><span>Tem alguma dúvida ou sugestão sobre a campanha? <br />Deixe sua mensagem</span></p>
        </div>


            <div className="second-section contato_page">
                <div className="container position-relative">
                <div className="card-form"  id="contato">
                        <div className="contato_form">
                            <form onSubmit={handleSubmit(onSubmitContato)}>
                            <div className="blocks">
                                <label htmlFor="Nome">Razão social*</label>
                                <input 
                                {...register("company", {
  
                                    
                                })}
                                type="text" 
                                name="company"
                                id="company"
                                placeholder="Nome da empresa" />
                            </div>
                            <div className="blocks">
                                <label htmlFor="email">E-mail*</label>
                                <select
                                {...register("email", {
                                
                                })}
                                 name="email"
                                 id="email">
                                     <option value={`${profile.email}`}>{profile.email}</option>
                                     {profile.email_alt && <option value={`${profile.email_alt}`}>{profile.email_alt}</option>}                                    
                                </select>
                            </div>
                            <div className="blocks">
                                <label htmlFor="mensagem">Mensagem*</label>
                                <textarea 
                                {...register("message", {
                                required: "Campo Obrigatório",

                                })}
                                type="text"
                                id="message"
                                name="message"
                                 placeholder="Insira aqui a sua mensagem" />
                                 {errors.message && <span  className="msgs-error-validate">{errors.message.message}</span> }
                            </div>
                            <div className="blocks">
                                <span>*Campos obrigatórios</span>
                            </div>

                            <div className="blocks">
                            <ReCAPTCHA
                            sitekey="6LckXCQcAAAAAM1c-jxoI93qrim_wS0jcNY7EHaY"
                            onChange={handleChangeCaptcha}
                            />
                            </div>

                            <div className="btn-area login">
                            <button
                            disabled={!isValid || !captcha}
                            type="submit"> 
                                {loading ?
                                (<>
                                 <span className="login button-content">{loading ? 'ENVIANDO...' : 'ENVIAR'}</span> {loading && (<><div className="spinner contato_spin"><img className="login" src={spinner} alt="Reveal Password" /><img /></div></>)}
                                </>):
                                (<>
                                 <span className="button-content">ENVIAR</span>
                                </>)}
                               
                             </button>
                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
			<AllLogosHome />
			<Footer />
            <Modal isOpen={isOpenModalSucess} close={onClickCloseSucessModal}>
                <p className="modal-title">{messageTitile}</p>
                <p className="erro-msg">{sucessMessage}</p>
                <div className="btn-area_next">
                            <button type="submit" onClick={onClickCloseSucessModal}>
                                <span className="button-content">FECHAR</span>
                            </button>                           
                        </div>
            </Modal>
            <Modal isOpen={isOpen} close={onClickCloseError}>
                <p className="modal-title">{messageTitile}</p>
                <p className="erro-msg">{messageError}</p>
                <div className="btn-area_next">
                            <button type="submit" onClick={onClickCloseError}>
                                <span className="button-content">FECHAR</span>
                            </button>                           
                </div>
            </Modal>
            <Modal isOpen={isOpenRegulamento} close={onClickCloseRegulamento}>
                <div className="btn-area_next regulamento">
                    <div className="texto-regulamento">
                    <Regulamento/>
                    </div>
                    <button type="submit" onClick={onClickCloseRegulamento}>
                        <span className="button-content">FECHAR</span>
                    </button>                           
                </div>
            </Modal>
		</>
	)
}