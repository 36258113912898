import React from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

import '../Home/Home.css'

import './Cupons.css'

import AllLogosHome from '../../components/AllLogosHome/AllLogosHome'
import Footer from '../../components/Footer/Footer'

import { Link, useHistory } from 'react-router-dom'

import { logout } from '../../services/auth'

import { useEffect, useState } from 'react'
import api from '../../services/api'

import Modal from '../../components/Modal/Modal'
import Menu from '../../components/Menu/Menu'

import Regulamento from '../../components/Regulamento/Regulamento'
import MenuMobile from '../../components/MenuMobile/MenuMobile'

import BannerHome from '../../assets/images/sama/banner-home.png'
import BannerHomeMobile767 from '../../assets/images/sama/banner767.png'

import desempenhoIcone from '../../assets/images/sama/cupom.png'

import OwlCarousel from 'react-owl-carousel2'
import  'react-owl-carousel2/src/owl.theme.default.css'
import Olho from '../../assets/images/cupons/olho.png'
import vejaCupons from '../../assets/images/cupons/veja_cupons.png'

import spinner from '../../assets/images/sama/spinner.gif'

import {isAuthenticated} from '../../services/auth'
export default function Home(props){
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false)
    const [classNameOpenMenu, setClassNameOpenMenu] = useState("hideMenu")
    const [profile, setProfile] = useState({});
    const [AddDarkBarMenuMobile, setAddDarkBarMenuMobile] = useState(false)
    const [activeClass, setActiveClass] = useState(false)
    const [activeClassMobile, setActiveClassMobile] = useState(false)




    const [month, setMonth] = useState([]);
    const [monthName, setMonthName] = useState('')
    const [isOpenModalCupons, setIsOpenModalCupons] = useState(false);
    const [msgCuponsCatch, setMsgCuponsCatch] = useState('')
    const [isOpenModalCuponsError, setIsOpenModalCuponsError] = useState(false)

    const [isOpenModalExtrato, setIsOpenModalExtrato] = useState(false);


    const [september, setSeptember] = useState([]);
    const [october, setOctober] = useState([]);
    const [november, setNovember] = useState([]);
    const [december, setDecember] = useState([]);
    const [january, setJanuary] = useState([]);
    const [february, setFebruary] = useState([]);
    const [march, setMarch] = useState([]);
    const [april, setApril] = useState([]);
    const [may, setMay] = useState([]);
    const [june, setJune] = useState([]);
    const [july, setJuly] = useState([]);

    const [monthTotal, setMonthTotal] = useState('');
    const [ext, setExt] = useState([]);
    const [monthClicked, setMonthClicked] = useState('');
    const [isOpenModalDevolucao, setIsOpenModalDevolucao] = useState(false);
    const [devolucoes, setDevolucoes] = useState([]);
    const [totalChargeBack, setTotalChargeBack] = useState('');

    const [isLoadCupons, setIsLoadCupons] = useState(false);

    const [isLoadCuponsSeptember, setIsLoadCuponsSeptember] = useState(false);
    const [isLoadCuponsOctober, setIsLoadCuponsOctober] = useState(false);
    const [isLoadCuponsNovember, setIsLoadCuponsNovember] = useState(false);
    const [isLoadCuponsDecember, setIsLoadCuponsDecember] = useState(false);
    const [isLoadCuponsJanuary, setIsLoadCuponsJanuary] = useState(false);
    const [isLoadCuponsFebruary, setIsLoadCuponsFebruary] = useState(false);
    const [isLoadCuponsMarch, setIsLoadCuponsMarch] = useState(false);
    const [isLoadCuponsApril, setIsLoadCuponsApril] = useState(false);
    const [isLoadCuponsMay, setIsLoadCuponsMay] = useState(false);
    const [isLoadCuponsJune, setIsLoadCuponsJune] = useState(false);
    const [isLoadCuponsJuly, setIsLoadCuponsJuly] = useState(false);

    const [isSeptemberLoaded, setIsSeptemberLoaded] = useState(false);
    const [isOctoberLoaded, setIsOctoberLoaded] = useState(false);
    const [isNovemberLoaded, setIsNovemberLoaded] = useState(false);
    const [isDecemberLoaded, setIsDecemberLoaded] = useState(false);
    const [isJanuaryLoaded, setIsJanuaryLoaded] = useState(false);
    const [isFebruaryLoaded, setIsFebruaryLoaded] = useState(false);
    const [isMarchLoaded, setIsMarchLoaded] = useState(false);
    const [isAprilLoaded, setIsAprilLoaded] = useState(false);
    const [isMayLoaded, setIsMayLoaded] = useState(false);  
    const [isJuneLoaded, setIsJuneLoaded] = useState(false);
    const [isJulyLoaded, setIsJulyLoaded] = useState(false);

    const [loadExtrato, setLoadExtrato] = useState(false)

    function getMonthSaldo(){
        var dt = new Date();
        var monthSaldo = dt.getMonth() + 1;
        return monthSaldo;
    }
    function getYEarSaldo(){
        var getYear = new Date();
        var yearSaldo = getYear.getFullYear();
        return yearSaldo;
    }


    useEffect(()=>{

        

        if(props.location.pathname === '/seus-numeros-da-sorte'){
            window.scrollTo(0, 0)
        }
       
        window.onscroll = () => handleScroll()        
        const token = localStorage.getItem("sama-token");
        const headers = {
            'Authorization': 'Bearer' + token
        }  
        async function loadApiProfle(){

            try{
                setIsSeptemberLoaded(true)
                setIsLoadCuponsSeptember(true)
                const response = await api.get(`/v1/statement/weeks/september`,{
                    headers: headers
                })  
                setSeptember([response.data.data.months.september])
            }catch(e){
                setIsLoadCuponsSeptember(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }finally{
                setIsLoadCuponsSeptember(false)

            }

            try{
                setIsOctoberLoaded(true)
                setIsLoadCuponsOctober(true)
                const response = await api.get(`/v1/statement/weeks/october`,{
                    headers: headers
                })  
                setOctober([response.data.data.months.october])

            }catch(e){
                setIsLoadCuponsOctober(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }finally{
                setIsLoadCuponsOctober(false)
            }

            try{
                setIsNovemberLoaded(true)
                setIsLoadCuponsNovember(true)
                const response = await api.get(`/v1/statement/weeks/november`,{
                    headers: headers
                })  
                setNovember([response.data.data.months.november])

            }catch(e){
                setIsLoadCuponsNovember(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }finally{
                setIsLoadCuponsNovember(false)
            }
            
            try{
                setIsDecemberLoaded(true)
                setIsLoadCuponsDecember(true)
                const response = await api.get(`/v1/statement/weeks/december`,{
                    headers: headers
                })  
                setDecember([response.data.data.months.december])

            }
            catch(e){
                setIsLoadCuponsDecember(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            finally{
                setIsLoadCuponsDecember(false)
            }

            try{
                setIsJanuaryLoaded(true)
                setIsLoadCuponsJanuary(true)
                const response = await api.get(`/v1/statement/weeks/january`,{
                    headers: headers
                })  
                setJanuary([response.data.data.months.january])

            }
            catch(e){
                setIsLoadCuponsJanuary(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            finally{
                setIsLoadCuponsJanuary(false)
            }

            try{
                setIsFebruaryLoaded(true)
                setIsLoadCuponsFebruary(true)
                const response = await api.get(`/v1/statement/weeks/february`,{
                    headers: headers
                })  
                setFebruary([response.data.data.months.february])

            }
            catch(e){
                setIsLoadCuponsFebruary(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            finally{
                setIsLoadCuponsFebruary(false)
            }

            try{
                setIsMarchLoaded(true)
                setIsLoadCuponsMarch(true)
                const response = await api.get(`/v1/statement/weeks/march`,{
                    headers: headers
                })  
                setMarch([response.data.data.months.march])

            }
            catch(e){
                setIsLoadCuponsMarch(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            finally{
                setIsLoadCuponsMarch(false)
            }

            try{
                setIsAprilLoaded(true)
                setIsLoadCuponsApril(true)
                const response = await api.get(`/v1/statement/weeks/april`,{
                    headers: headers
                })  
                setApril([response.data.data.months.april])

            }
            catch(e){
                setIsLoadCuponsApril(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            finally{
                setIsLoadCuponsApril(false)
            }

            try{
                setIsMayLoaded(true)
                setIsLoadCuponsMay(true)
                const response = await api.get(`/v1/statement/weeks/may`,{
                    headers: headers
                })  
                setMay([response.data.data.months.may])

            }
            catch(e){
                setIsLoadCuponsMay(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            finally{
                setIsLoadCuponsMay(false)
            }

            try{
                setIsJuneLoaded(true)
                setIsLoadCuponsJune(true)
                const response = await api.get(`/v1/statement/weeks/june`,{
                    headers: headers
                })  
                setJune([response.data.data.months.june])

            }
            catch(e){
                setIsLoadCuponsJune(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            finally{
                setIsLoadCuponsJune(false)
            }


            try{
                setIsJulyLoaded(true)
                setIsLoadCuponsJuly(true)
                const response = await api.get(`/v1/statement/weeks/july`,{
                    headers: headers
                })  
                setJuly([response.data.data.months.july])

            }
            catch(e){
                setIsLoadCuponsJuly(false)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            finally{
                setIsLoadCuponsJuly(false)
            }


        }

        async function loadUser(){
            try{
                const response = await api.get(`/v1/account/profile`,{
                    headers: headers
                })  
                setProfile(response.data.data)
            }catch(e){
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
        }
        loadUser();
        loadApiProfle();

        setTimeout(() => {
            const ghostDiv = document.querySelector('.space_fix')
            if (!ghostDiv) {
                const textSlider = document.querySelector('#text-slider')
                textSlider.classList.add('mt-ghost-div')
            }
        }, 500)

    }, [])
    // console.log(profile)



    useEffect(()=>{
        async function loadExtratos(){
            setLoadExtrato(true)
            try{
                
                if(monthClicked === 'september'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.september.statement])
                    setMonthTotal(response.data.data.months.september.resume.total)
                }else if(monthClicked === 'october'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.october.statement])
                    setMonthTotal(response.data.data.months.october.resume.total)                    
                }
                else if(monthClicked === 'november'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.november.statement])
                    setMonthTotal(response.data.data.months.november.resume.total)                     
                
                }else if(monthClicked === 'december'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.december.statement])
                    setMonthTotal(response.data.data.months.december.resume.total)                     
                
                }else if(monthClicked === 'january'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.january.statement])
                    setMonthTotal(response.data.data.months.january.resume.total)                       
                }
                else if(monthClicked === 'february'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.february.statement])
                    setMonthTotal(response.data.data.months.february.resume.total)                       
                }
                else if(monthClicked === 'march'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.march.statement])
                    setMonthTotal(response.data.data.months.march.resume.total)                       
                }
                else if(monthClicked === 'april'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.april.statement])
                    setMonthTotal(response.data.data.months.april.resume.total)                       
                }
                else if(monthClicked === 'may'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.may.statement])
                    setMonthTotal(response.data.data.months.may.resume.total)                       
                }
                else if(monthClicked === 'june'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.june.statement])
                    setMonthTotal(response.data.data.months.june.resume.total)                       
                }
                else if(monthClicked === 'july'){
                    const response = await api.get(`/v1/statement/weeks/${monthClicked}`)  
                    setExt([response.data.data.months.july.statement])
                    setMonthTotal(response.data.data.months.july.resume.total)                       
                }
                else{
                    return
                }
                

            }catch(e){
                console.log(e)
            }         
            setLoadExtrato(false)   
            
        }

        loadExtratos();
    },[monthClicked])


    const handleLogout = function(){
        if(localStorage.getItem("sama-token")){  
            logout()
        }
    }

    function onClickCloseError(){
        setIsOpen(false)
    }
    function onClickOpen(){
        setIsOpen(true)
    }
    function handleScroll() { 
        if (document.documentElement.scrollTop >= 30) {
            setAddDarkBarMenuMobile(true)
            setClassNameOpenMenu('showMenu d-none d-sm-none d-md-block d-lg-block')

         }else{
             setClassNameOpenMenu('hideMenu')
             setAddDarkBarMenuMobile(false)
         }

         if(document.documentElement.scrollTop >= 750 && document.documentElement.scrollTop < 1700){
            setActiveClass(true)
        }else{
            setActiveClass(false)
        }
         if(document.documentElement.scrollTop >= 400 && document.documentElement.scrollTop < 1700){
            setActiveClassMobile(true)
        }else{
            setActiveClassMobile(false)
        }
    }




    const optionsCupons = {
        items:2,
        nav: false,
        rewind: true,
        autoplay: false,
        dots:true,
        responsive:{
            0:{
                items:1,
                nav:false,
                dots:true,
                dotsEach: 1
            },
            600:{
                items:1,
                nav:false,
                dots:true,
                dotsEach: 1
            },
            1000:{
                items:2,
                nav:false,
                dots:true,
                dotsEach: 1
            }
        }

    };


        //DEVOLUCAO
        const result = []
        function onClickOpenModalDevolucao(id){
            setIsOpenModalDevolucao(true)
            const dets = ext[0].map((e) => e)
            dets[id].detail.map((det)=> result.push(det))
    
            const total =  dets[id].chargeback;
    
            // try{
                    
            //     const response = await api.get(`/v1/coupons/${monthClicked}`,{
            //     })  
            //     setMonth([response.data.data])
    
            //     setIsOpenModalCupons(true)
            // }catch(e){
            //     console.log(e)
            //     setIsOpenModalCuponsError(true)
            // }
            const resultEstorno = result.filter((r)=> r.type=='ESTORNO')
            setDevolucoes(resultEstorno)
            setTotalChargeBack(total)
        }
        
        function onClickCloseModalDevolucao(){
            setIsOpenModalDevolucao(false)
        }

        function onClickCloseCuponsError(){
            setIsOpenModalCuponsError(false)
        }

        function onClickCloseModalCupons(){
            setIsOpenModalCupons(false)
        }
    
        function onClickCloseModalExtrato(){
            setIsOpenModalExtrato(false)
        }


    const getDayAndMonth = function(){
        const data = new Date();
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        return dia + '/' + mes
        
    }


    async function onClickOpenModalCupons(monthClicked){
        
        try{
                
            const response = await api.get(`/v1/coupons/${monthClicked}`,{
            })  
            setMonth([response.data.data])
            setMonthName(monthClicked)
            setIsOpenModalCupons(true)
        }catch(e){
            console.log(e)
            setMsgCuponsCatch(e.response.data.message)
            setMonthName(monthClicked)
            setIsOpenModalCuponsError(true)
        }
    }

    function onClickCloseModalExtrato(){
        setIsOpenModalExtrato(false)
    }
    function onClickOpenModalExtrato(){
        setIsOpenModalExtrato(true)
        setMonthClicked('september')
    }

    const getDayAndMonthFull = function(){
        const data = new Date();
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        return ano + '-' + mes +'-' + dia
        
    }

    return(
        <>
        
            {/* MENU MOBILE */}
            {/* <div className={`${AddDarkBarMenuMobile ? 'addDark': 'clearDark'} ${document.documentElement.scrollTop >= 30 ? 'fixed-menu' : ''}`}> */}
            <div className={`${AddDarkBarMenuMobile ? 'addDark': 'clearDark'} fixed-menu`}>
            <MenuMobile>
            <ul className="navbar-nav me-auto mb-lg-0 ">
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <a  href="/inicio#como-participar"><span>COMO PARTICIPAR</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <a  href="/ganhadores"><span>GANHADORES</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item" >
                        <Link onClick={()=> onClickOpen()} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-expanded="true" aria-label="Toggle navigation"><span>REGULAMENTO</span></Link>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item">
                            <a href="/contato"><span>CONTATO</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <Link aria-current="page" className="active"  to="/seus-numeros-da-sorte"><span>SEUS NÚMEROS DA SORTE</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item" >
                            
                            <Link   onClick={handleLogout} to="/#"><span>SAIR</span></Link>
                        </div>
                    </li>
                </ul>                
            </MenuMobile>
            </div>
            <div className="space_fix d-block d-lg-none"></div>
            <div id="menuHeader" className={`${classNameOpenMenu} d-none d-sm-none d-md-block d-lg-block`}>
                <Menu>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu_home">
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <a className={`menu-link`} aria-current="page" href="/inicio#como-participar"><span>COMO PARTICIPAR</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <a className={`menu-link`}  href="/ganhadores"><span>GANHADORES</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div  className="div-one">
                            <Link className="menu-link" onClick={()=> onClickOpen()}><span>REGULAMENTO</span></Link>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <a className="menu-link" href="/contato"><span>CONTATO</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <Link className="menu-link active" aria-current="page" to="/seus-numeros-da-sorte"><span>SEUS NÚMEROS DA SORTE</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div  className="div-one">
                            
                            <Link className="menu-link" onClick={handleLogout} to="/#"><span>SAIR</span></Link>
                        </div>
                    </li>
                </ul>
                </Menu>
            </div>

            <div className="cupons_pagina">
                <img src={desempenhoIcone} alt="Contato" />
                <h1>Seus números da sorte</h1>
                <p className="welcome-text">Olá, {profile.company}</p>
                <p className="info-text">Veja informações detalhadas sobre as compras realizadas pela <br className="d-none d-sm-block d-lg-block" /> sua empresa e todos os números da sorte conquistados até o momento.</p>
                {profile.group ? ( <p className="grupo">SUA EMPRESA ESTÁ NO GRUPO {profile.group}</p>):('')}
             </div>


            <div className="second-section cupons_page">
                <div className="container position-relative">
                <div className="row">
                        <div className="row card_cupons_detalhado">
                        <OwlCarousel  options={optionsCupons}>
     

                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>SETEMBRO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                    {september[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{september[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        } 
                                        
                                    </div>
                                    <div className="saldo">
                                    {isSeptemberLoaded ? (<>
                                        {getMonthSaldo() >= 9 ? 
                                            (<>
                                                {isLoadCuponsSeptember ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{september[0]?.resume.total ? (<>R${september[0].resume.total !== 0 ?  (september[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                            </>) 
                                            : 
                                            (<>
                                                {isLoadCuponsSeptember ? (<img style={{width:100}} src={spinner} /> ):(<h4>{september[0]?.resume.total ? (<>R${september[0].resume.total !== 0 ?  (september[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                            </>)}
                                    
                                    </>) 
                                    
                                    : (<><><h4><span className="tr_cinza">-</span></h4></></>)}
                                    </div>
                                    {september[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {september[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('september')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>OUTUBRO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                    {october[0]?.is_current ?
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{october[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        } 
                                        
                                    </div>
                                    <div className="saldo">
                                    {
                                        isOctoberLoaded ? (<>
                                        
                                        {getMonthSaldo() >= 10 ? 
                                            (<>
                                                {isLoadCuponsOctober ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{october[0]?.resume.total ? (<>R${october[0].resume.total !== 0 ?  (october[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                            </>) 
                                            : 
                                            (<>
                                                {isLoadCuponsOctober ? (<img style={{width:100}} src={spinner} /> ):(<h4>{october[0]?.resume.total ? (<>R${october[0].resume.total !== 0 ?  (october[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                            </>)}
                                        </>) 
                                        : (<><h4><span className="tr_cinza">-</span></h4></>)
                                    }
                                    </div>
                                    {october[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {october[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus Cupons" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('october')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>NOVEMBRO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {november[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{november[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                    {isNovemberLoaded ? (<>
                                        {getMonthSaldo() >= 11 ? 
                                            (<>
                                                {isLoadCuponsNovember ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{november[0]?.resume.total ? (<>R${november[0].resume.total !== 0 ?  (november[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                            </>) 
                                            : 
                                            (<>
                                                {isLoadCuponsNovember ? (<img style={{width:100}} src={spinner} /> ):(<h4>{november[0]?.resume.total ? (<>R${november[0].resume.total !== 0 ?  (november[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                            </>)}
                                    </>)
                                    
                                    :(<><h4><span className="tr_cinza">-</span></h4></>)}
                                    </div>
                                    {november[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {november[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('november')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>DEZEMBRO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {december[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{december[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                        {isDecemberLoaded ? (<>                                        
                                            {getMonthSaldo() >= 12 ? 
                                                (<>
                                                    {isLoadCuponsDecember ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{december[0]?.resume.total ? (<>R${december[0].resume.total !== 0 ?  (december[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                </>) 
                                                : 
                                                (<>
                                                    {isLoadCuponsDecember ? (<img style={{width:100}} src={spinner} /> ):(<h4>{december[0]?.resume.total ? (<>R${december[0].resume.total !== 0 ?  (december[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                </>)}
                                        </>)
                                        :
                                        (<><h4><span className="tr_cinza">-</span></h4></>) }
                                    </div>
                                    {december[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {december[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('december')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>JANEIRO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {january[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{january[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                        {isJanuaryLoaded ? (<>
                                        
                                            {getYEarSaldo() === 2022 ? 
        
                                            (<>
                                                {getMonthSaldo() >= 1 ? 
                                                (<>
                                                    {isLoadCuponsJanuary ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{january[0]?.resume.total ? (<>R${january[0].resume.total !== 0 ?  (january[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                </>) 
                                                : 
                                                (<>
                                                    {isLoadCuponsJanuary ? (<img style={{width:100}} src={spinner} /> ):(<h4>{january[0]?.resume.total ? (<>R${january[0].resume.total !== 0 ?  (january[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                </>)}
                                            </>)
                                             :
                                             (<>
                                             {isLoadCuponsJanuary ? (<img style={{width:100}} src={spinner} /> ):(<h4>{january[0]?.resume.total ? (<>R${january[0].resume.total !== 0 ?  (january[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                             </>)
                                             }
                                        </>)
                                        :
                                        (<><h4><span className="tr_cinza">-</span></h4></>)}
                                    
                                    
                                    </div>
                                    {january[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {january[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('january')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>FEVEREIRO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {february[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{february[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                        {isFebruaryLoaded ? (<>                                        
                                            {getYEarSaldo() === 2022 ? 
        
                                                (<>
                                                    {getMonthSaldo() >= 2 ? 
                                                    (<>
                                                        {isLoadCuponsFebruary ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{february[0]?.resume.total ? (<>R${february[0].resume.total !== 0 ?  (february[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                    </>) 
                                                    : 
                                                    (<>
                                                        {isLoadCuponsFebruary ? (<img style={{width:100}} src={spinner} /> ):(<h4>{february[0]?.resume.total ? (<>R${february[0].resume.total !== 0 ?  (february[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                    </>)}
                                                </>)
                                                :
                                                (<>
                                                {isLoadCuponsFebruary ? (<img style={{width:100}} src={spinner} /> ):(<h4>{february[0]?.resume.total ? (<>R${february[0].resume.total !== 0 ?  (february[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                </>)
                                                }
                                        </>)
                                        :
                                        (<><h4><span className="tr_cinza">-</span></h4></>)}
                                    </div>
                                    {february[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {february[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('february')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>MARÇO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {march[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{march[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                        {isMarchLoaded ? (<>                                        
                                            {getYEarSaldo() === 2022 ? 
        
                                            (<>
                                                {getMonthSaldo() >= 3 ? 
                                                (<>
                                                    {isLoadCuponsMarch ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{march[0]?.resume.total ? (<>R${march[0].resume.total !== 0 ?  (march[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                </>) 
                                                : 
                                                (<>
                                                    {isLoadCuponsMarch ? (<img style={{width:100}} src={spinner} /> ):(<h4>{march[0]?.resume.total ? (<>R${march[0].resume.total !== 0 ?  (march[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                </>)}
                                            </>)
                                            :
                                            (<>
                                            {isLoadCuponsMarch ? (<img style={{width:100}} src={spinner} /> ):(<h4>{march[0]?.resume.total ? (<>R${march[0].resume.total !== 0 ?  (march[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                            </>)
                                            }
                                        </>)
                                        :
                                        (<><h4><span className="tr_cinza">-</span></h4></>)}
                                    </div>
                                    {march[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {march[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('march')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>



                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>ABRIL</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {april[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{april[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                        {isAprilLoaded ? (<>
                                            {getYEarSaldo() === 2022 ? 
        
                                                (<>
                                                    {getMonthSaldo() >= 4 ? 
                                                    (<>
                                                        {isLoadCuponsApril ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{april[0]?.resume.total ? (<>R${april[0].resume.total !== 0 ?  (april[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                    </>) 
                                                    : 
                                                    (<>
                                                        {isLoadCuponsApril ? (<img style={{width:100}} src={spinner} /> ):(<h4>{april[0]?.resume.total ? (<>R${april[0].resume.total !== 0 ?  (april[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                    </>)}
                                                </>)
                                                :
                                                (<>
                                                {isLoadCuponsApril ? (<img style={{width:100}} src={spinner} /> ):(<h4>{april[0]?.resume.total ? (<>R${april[0].resume.total !== 0 ?  (april[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                </>)
                                                }
                                        </>)
                                        :
                                        (<><h4><span className="tr_cinza">-</span></h4></>)}
                                    
                                    </div>
                                    {april[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {april[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('april')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>MAIO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {may[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{may[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                        {isMayLoaded ? (<>                                        
                                            {getYEarSaldo() === 2022 ? 
        
                                                (<>
                                                    {getMonthSaldo() >= 5 ? 
                                                    (<>
                                                        {isLoadCuponsMay ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{may[0]?.resume.total ? (<>R${may[0].resume.total !== 0 ?  (may[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                    </>) 
                                                    : 
                                                    (<>
                                                        {isLoadCuponsMay ? (<img style={{width:100}} src={spinner} /> ):(<h4>{may[0]?.resume.total ? (<>R${may[0].resume.total !== 0 ?  (may[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                    </>)}
                                                </>)
                                                :
                                                (<>
                                                {isLoadCuponsMay ? (<img style={{width:100}} src={spinner} /> ):(<h4>{may[0]?.resume.total ? (<>R${may[0].resume.total !== 0 ?  (may[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                </>)
                                                }
                                        </>)
                                        :
                                        (<><h4><span className="tr_cinza">-</span></h4></>)}
                                    </div>
                                    {may[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {may[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('may')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>JUNHO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {june[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{june[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                        {isJuneLoaded ? (<>                                        
                                            {getYEarSaldo() === 2022 ? 
        
                                                (<>
                                                    {getMonthSaldo() >= 6 ? 
                                                    (<>
                                                        {isLoadCuponsJune ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{june[0]?.resume.total ? (<>R${june[0].resume.total !== 0 ?  (june[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                    </>) 
                                                    : 
                                                    (<>
                                                        {isLoadCuponsJune ? (<img style={{width:100}} src={spinner} /> ):(<h4>{june[0]?.resume.total ? (<>R${june[0].resume.total !== 0 ?  (june[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                    </>)}
                                                </>)
                                                :
                                                (<>
                                                {isLoadCuponsJune ? (<img style={{width:100}} src={spinner} /> ):(<h4>{june[0]?.resume.total ? (<>R${june[0].resume.total !== 0 ?  (june[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                </>)
                                                }
                                        </>)
                                        :
                                        (<><h4><span className="tr_cinza">-</span></h4></>)}
                                    </div>
                                    {june[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {june[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('june')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>


                            <div className="card_box item">
                                <div className="linha_topo"></div>
                                <div className="separador">
                                    <div className="mes">
                                        <p>JULHO</p>
                                    </div>
                                    <div className="texto-box_saldo">
                                        {july[0]?.is_current ? 
                                        
                                        (<p>ATÉ o dia <span className="dia_mes">{getDayAndMonth()}</span>, você tem </p>):
                                        (<p>{july[0]?.resume.total !== 0 ? 'O SALDO DA SUA EMPRESA FOI DE':"" }</p>)
                                        
                                        }  
                                        
                                    </div>
                                    <div className="saldo">
                                        {isJulyLoaded ? (<>                                        
                                            {getYEarSaldo() === 2022 ? 
        
                                                (<>
                                                    {getMonthSaldo() >= 7 ? 
                                                    (<>
                                                        {isLoadCuponsJuly ? (<img style={{width:100}} src={spinner} /> ):(<h4 className="redValue">{july[0]?.resume.total ? (<>R${july[0].resume.total !== 0 ?  (july[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : 'R$00,00'}</>): (<><span className="tr_cinza redValue">R$00,00</span></>)}</h4>)}
                                                    </>) 
                                                    : 
                                                    (<>
                                                        {isLoadCuponsJuly ? (<img style={{width:100}} src={spinner} /> ):(<h4>{july[0]?.resume.total ? (<>R${july[0].resume.total !== 0 ?  (july[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                    </>)}
                                                </>)
                                                :
                                                (<>
                                                {isLoadCuponsJuly ? (<img style={{width:100}} src={spinner} /> ):(<h4>{july[0]?.resume.total ? (<>R${july[0].resume.total !== 0 ?  (july[0].resume.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : '-'}</>): (<><span className="tr_cinza">-</span></>)}</h4>)}
                                                </>)
                                                }
                                        </>)
                                        :
                                        (<><h4><span className="tr_cinza">-</span></h4></>)}
                                    </div>
                                    {july[0]?.is_current && <p className="current_text-p">Para geração de números da sorte</p> }
                                    {july[0]?.is_current && <p className="current_text-p_dois">*Valor sujeito a revisão após o fechamento do mês</p> }
                                </div>
                                <div className="ver_cupons">
                                    <img src={vejaCupons} alt="Veja seus números da sorte" />
                                    <a href="#" onClick={()=>onClickOpenModalCupons('july')}><span>Veja seus números da sorte</span></a>
                                </div>
                            </div>
                            
                        </OwlCarousel>
                        </div>
                    </div>
                    
                    <div className="row">
                            <div className="box-btn_extrato">
                                <span className="text-span-btn">Lembre-se</span>
                                <p>Quanto mais sua empresa comprar na Sama. <br className="d-none d-lg-block" /> mais perto você fica dessa experiência incrível.</p>
                                <div className="red-shape-bt home">
                                        <div>
                                            <Link to="#" onClick={()=> onClickOpenModalExtrato()}>VEJA O EXTRATO COMPLETO <br className="d-md-block d-lg-none" /> DA SUA EMPRESA</Link>
                                        </div>
                                </div>
                            </div>
                    </div>



                </div>
            </div>

			<Modal isOpen={isOpen} close={onClickCloseError}>
				<div className="btn-area_next regulamento">
					<div className="texto-regulamento">
						<Regulamento />
					</div>
					<button type="submit" onClick={onClickCloseError}>
						<span className="button-content">FECHAR</span>
					</button>
				</div>
			</Modal>



            
            <Modal isOpen={isOpenModalDevolucao} close={onClickCloseModalDevolucao}>
                <div className="cupons-modal_info">
                    <div className="title-cupons">
                        <h5>DEVOLUÇÕES</h5>
                    </div>
                    <div className="tabela_extrato d-none d-lg-block">
                    <div className="extrato_box">
                        <div className="linhas head_extrato">
                            <div>DATA</div>
                            <div>NÚMERO DA NF</div>
                            <div>VALOR</div>
                        </div>
                        <div className="corpo-extrato_full">
                        {devolucoes.map((e, i)=>(
                        <div className="linhas corpo_extrato">
                            <div className="devol_data">{e.date.split('-').reverse().join('/')}</div>
                            <div>{e.number}</div>
                            <div className="devol_vermelho">{e.amount.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</div>
                        </div>
                            ))}
                        </div>
                        <div className="box_total_footer">
                            <div className="box_total_ver">TOTAL</div>
                            <div className="total_seg"></div>
                            <div className="total_seg"><span>{totalChargeBack.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} </span></div>
                        </div>
                    </div>
                    </div>
                    <div className="row d-lg-none">

                    <div class="accordion" id="accordionExample2">
                    {devolucoes?.map((e, i)=>(
                    <div class="accordion-item">
                        <h2 class="accordion-header" id={`heading-${i}`}>
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse2-${i}`} aria-expanded={i === 0 ? 'true':'false'} aria-controls={`collapse2-${i}`}>
                        {e.date.split('-').reverse().join('/')}
                        </button>
                        </h2>
                        <div id={`collapse2-${i}`} class={i === 0 ? 'accordion-collapse collapse':'accordion-collapse collapse'} aria-labelledby={`heading-${i}`} data-bs-parent="#accordionExample2">
                        <div class="accordion-body">

                            <div className="linha_extrato_mobile border_bottom">
                            {e.number === 0 ? 
                            (<div><span className="item_title_m">Número da NF: </span> - </div>):
                            (<div><span className="item_title_m">Número da NF: </span> {e.number} </div>)}
                            </div>

                        <div className="linha_extrato_mobile">
                                {e.amount === 0 ? 
                                (<div><span className="item_title_m">Valor: </span> -</div>):
                                (<div><span className="item_title_m">Valor: </span> {e.amount.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</div>)}  
                            </div>

                        </div>
                        </div>
                    </div>
                    ))}
                    </div>  
                    </div>
                    <div className="btn_cupons extrato_mobile_voltar"><div className="reset_shape"><a href="#" onClick={()=>onClickCloseModalDevolucao()}>VOLTAR</a></div></div>                       
                </div>
            </Modal>




			<AllLogosHome />
			<Footer />
            <Modal isOpen={isOpenModalExtrato} close={onClickCloseModalExtrato}>
                <div className="cupons-modal_info">
                    <div className="title-cupons">
                        <h5>VEJA O EXTRATO <br /> COMPLETO DA SUA EMPRESA</h5>
                    </div>
                    <div className="extrato_sel_mes">
                        <div className="texto-cinza">
                            <p>Selecione o mês</p>
                        </div>
                        <div className="mes_modal">
                            <div className="reset-text">
                                <select
                                onChange={(e)=>{
                                    const selectedMonth = e.target.value;
                                    setMonthClicked(selectedMonth)
                                }}>
                                    <option value="">SELECIONE</option>
                                    <option value="september" selected>Setembro</option>
                                    <option value="october">Outubro</option>
                                    <option value="november">Novembro</option>
                                    <option value="december">Dezembro</option>
                                    <option value="january">Janeiro</option>
                                    <option value="february">Fevereiro</option>
                                    <option value="march">Março</option>
                                    <option value="april">Abril</option>
                                    <option value="may">Maio</option>
                                    <option value="june">Junho</option>
                                    <option value="july">Julho</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="tabela_extrato d-none d-lg-block">
                    <div className="extrato_box">
                        <div className="linhas head_extrato">
                            <div>SEMANA</div>
                            <div>COMPRAS ($)</div>
                            <div>DEVOLUÇÕES </div>
                            <div>TOTAL ($)</div>
                        </div>
                        {loadExtrato ? (<h1 style={{color:'#666', fontSize:20, padding:20}}>CARREGANDO...</h1>): (<>
                        {ext[0]?.map((e, i)=>(
                        <div className="linhas corpo_extrato">
                            <div>{e.week[0].split('-').reverse().join('/')} a {e.week[1].split('-').reverse().join('/')}</div>
                            {e.entry === 0 ? 
                            (<div> - </div>):
                            (<div>{e.entry.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</div>)}
                            {e.chargeback === 0 ?
                             (<div> - </div>):
                             (<div className="devol_vermelho">{e.chargeback.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} <a href="#" onClick={()=> onClickOpenModalDevolucao(i)}><img src={Olho} alt="" /></a></div>)}
                             {e.total === 0 ? 
                             (<div>-</div>):
                             (<div>{e.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</div>)}
                            
                        </div>
                            ))}
                        </>)}
                        <div className="box_total_footer">
                            <div className="box_total_ver">TOTAL MENSAL</div>
                            <div className="box_total_ver"></div>
                            <div className="total_seg"><span> {monthTotal !== 0 ? (monthTotal.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')):(<>-</>)}</span></div>
                        </div>
                    </div>

                    </div>
                    

                    <div className="row d-lg-none">
                    <div class="accordion" id="accordionExample">
                    {ext[0]?.map((e, i)=>(
                    <div class="accordion-item">
                        <h2 class="accordion-header" id={`heading-${i}`}>
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${i}`} aria-expanded={i === 0 ? 'true':'false'} aria-controls={`collapse-${i}`}>
                        {e.week[0].split('-').reverse().join('/')} a {e.week[1].split('-').reverse().join('/')}
                        </button>
                        </h2>
                        <div id={`collapse-${i}`} class={i === 0 ? 'accordion-collapse collapse':'accordion-collapse collapse'} aria-labelledby={`heading-${i}`} data-bs-parent="#accordionExample">
                        <div class="accordion-body">

                            <div className="linha_extrato_mobile border_bottom">
                            {e.entry === 0 ? 
                            (<div><span className="item_title_m">Compras: </span> - </div>):
                            (<div><span className="item_title_m">Compras: </span> {e.entry.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</div>)}
                            </div>

                        <div className="linha_extrato_mobile border_bottom">
                            {e.chargeback === 0 ?
                                (<div><span className="item_title_m">Devoluções: </span> - </div>):
                                (<div className="devol_vermelho"><span className="item_title_m">Devoluções: </span> {e.chargeback.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} <a href="#" onClick={()=> onClickOpenModalDevolucao(i)}><img src={Olho} alt="" /></a></div>)}
                            </div>

                        <div className="linha_extrato_mobile">
                                {e.total === 0 ? 
                                (<div><span className="item_title_m">Total: </span> -</div>):
                                (<div><span className="item_title_m">Total: </span> {e.total.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</div>)}  
                            </div>

                        </div>
                        </div>
                    </div>
                    ))}
                    <div className="total_mobile_extrato">
                            <div className="box_total_ver">TOTAL MENSAL</div>
                            <div className="total_seg"><span> {monthTotal !== 0 ? (monthTotal.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')):(<>-</>)}</span></div>
                            
                    </div>
                    <p className="info_mobile_devolucoes"><img src={Olho} alt="" /> Ver as devoluções no período</p>
                    </div>  
                    </div>
                    <div className="btn_cupons extrato_mobile_voltar"><div className="reset_shape"><a onClick={onClickCloseModalExtrato} href="#">VOLTAR</a></div></div>                    
                </div>
            </Modal>
            <Modal isOpen={isOpenModalCupons} close={onClickCloseModalCupons}>
                <div className="cupons-modal_info">
                    
                    <div className="title-cupons">
                        <h5>NÚMEROS DA SORTE</h5>
                        <h6>
                            {monthName === 'august' ? 'AGOSTO':''}
                            {monthName === 'september' ? 'SETEMBRO':''}
                            {monthName === 'october' ? 'OUTUBRO':''}
                            {monthName === 'november' ? 'NOVEMBRO':''}
                            {monthName === 'december' ? 'DEZEMBRO':''}
                            {monthName === 'january' ? 'JANEIRO':''}
                            {monthName === 'february' ? 'FEVEREIRO':''}
                            {monthName === 'march' ? 'MARÇO':''}
                            {monthName === 'april' ? 'ABRIL':''}
                            {monthName === 'may' ? 'MAIO':''}
                            {monthName === 'june' ? 'JUNHO':''}
                            {monthName === 'july' ? 'JULHO':''}
                        </h6>
                        
                        {
                            <div className="box_cupons_colors">

                            <div> {month[0]?.coupons.filter((i)=>{
                                return(i.type === 'default')}).length > 0 ? <>
                                <span>Números da sorte padrões </span>
                                <span>{month[0]?.coupons.filter((i)=>{return(i.type === 'default')}).length}</span></>:''}
                            </div>

                            <div> {month[0]?.coupons.filter((i)=>{
                                return(i.type === 'weekly')}).length > 0 ? <>
                                <span>Números da sorte <span className="bns_semanal">marca bônus semanal</span> </span>
                                <span className="bns_semanal">{month[0]?.coupons.filter((i)=>{return(i.type === 'weekly')}).length}</span></>:''}
                            </div>

                            <div> {month[0]?.coupons.filter((i)=>{
                                return(i.type === 'monthly')}).length > 0 ? <>
                                <span>Números da sorte <span className="bns_mensal">marca bônus mensal</span> </span>
                                <span className="bns_mensal">{month[0]?.coupons.filter((i)=>{return(i.type === 'monthly')}).length}</span></>:''}
                            </div>

                            <div> {month[0]?.coupons.filter((i)=>{
                                return(i.type === 'terminal')}).length > 0 ? <>
                                <span>Números da sorte <span className="bns_pronto">clientes Prontos</span> </span>
                                <span className="bns_pronto">{month[0]?.coupons.filter((i)=>{return(i.type === 'terminal')}).length}</span></>:''}
                            </div>

                            <div><span className="bns_total">Total</span> <span className="bns_total">{month[0] ?  month[0].coupons.length:''}</span></div>

                            </div>
                        }

                    </div>
                    {/* <div className="dia-sorteio_infos">
                        <h4>NÚMERO DOS CUPONS</h4>

                         {
                            getDayAndMonthFull() <= month[0]?.raffle ?
                            <p>O sorteio do trimestre acontecerá em {month[0]? month[0].raffle.split('-').reverse().join('/') : ''}</p>: 
                            <p>O sorteio foi realizado no dia {month[0]? month[0].raffle.split('-').reverse().join('/') : ''}</p>
                        }
                    </div> */}
                    <div className="cupons_numeros">
                    {month[0] ? 
                    (
                        <>
                            {month[0].coupons.map((e)=>(

                            <div className={`numero ${e.type}`}>{e.serie}-{e.number}</div>

                            ))}
                        </>
                    ):
                    (
                        <>
                            <h1>Sem números da sorte!</h1>
                        </>
                    )}

                
                    </div>
                    <div className="msg_data_sorteio">
                            {monthName === 'september' || monthName === 'october' || monthName === 'november' ? <p className="no_cupons">O sorteio do primeiro trimestre (setembro, outubro e novembro) acontecerá em 15/12/2021*.</p>: ''}
                            {monthName === 'december' || monthName === 'january' || monthName === 'february' ? <p className="no_cupons">O sorteio do segundo trimestre (dezembro, janeiro e fevereiro) acontecerá em 16/03/2022*.</p>: ''}
                            {monthName === 'march' || monthName === 'april' || monthName === 'may' || monthName === 'june' || monthName === 'july' ? <p className="no_cupons">O sorteio final acontecerá em 20/08/2022*.</p>: ''}
                            
                        <p>*Se a sua empresa não mantiver a frequência mensal de R$5 mil em compras durante cada mês do trimestre, ela não participará do sorteio trimestral e os cupons gerados serão utilizados no sorteio final em 20/08/2022. O mesmo acontecerá se ela participar do sorteio trimestral e não for contemplada.</p>
                    </div>
                    <div className="btn_cupons extrato_mobile_voltar link-voltar_cupons">
                        <a href="#" onClick={onClickCloseModalCupons}>Voltar</a>
                    </div>
                                             
                </div>
            </Modal>

            <Modal isOpen={isOpenModalCuponsError} close={onClickCloseCuponsError}>
            <div className="cupons-modal_info">
            
                    <div className="title-cupons">
                        <h5>SEUS NÚMEROS DA SORTE</h5>
                        <h6>
                            {monthName === 'august' ? 'AGOSTO':''}
                            {monthName === 'september' ? 'SETEMBRO':''}
                            {monthName === 'october' ? 'OUTUBRO':''}
                            {monthName === 'november' ? 'NOVEMBRO':''}
                            {monthName === 'december' ? 'DEZEMBRO':''}
                            {monthName === 'january' ? 'JANEIRO':''}
                            {monthName === 'february' ? 'FEVEREIRO':''}
                            {monthName === 'march' ? 'MARÇO':''}
                            {monthName === 'april' ? 'ABRIL':''}
                            {monthName === 'may' ? 'MAIO':''}
                            {monthName === 'june' ? 'JUNHO':''}
                            {monthName === 'july' ? 'JULHO':''}
                        </h6>
                        {/* <p>{msgCuponsCatch}</p> */}
                        {/* <p>0 números da sorte</p> */}
                    </div>
                    <div className="msg_data_sorteio">
                    
                   
                    
                    {monthName === 'september' ? (<>{september[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'october' ? (<>{october[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'november' ? (<>{november[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'december' ? (<>{december[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'january' ? (<>{january[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'february' ? (<>{february[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'march' ? (<>{march[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'april' ? (<>{april[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'may' ? (<>{may[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'june' ? (<>{june[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    {monthName === 'july' ? (<>{july[0]?.is_current ? (<p>SUA EMPRESA AINDA NÃO POSSUI <br />NÚMEROS DA SORTE PARA ESTE MÊS.</p>) : (<p>SUA EMPRESA NÃO OBTEVE <br />NÚMEROS DA SORTE NESTE MÊS.</p>) }</>) : (<></>)}
                    
                            {/* {monthName === 'september' || monthName === 'october' || monthName === 'november' ? <p className="no_cupons">O sorteio do primeiro trimestre (setembro, outubro e novembro) acontecerá em 15/12/2021*.</p>: ''}
                            {monthName === 'december' || monthName === 'january' || monthName === 'february' ? <p className="no_cupons">O sorteio do segundo trimestre (dezembro, janeiro e fevereiro) acontecerá em 16/03/2022*.</p>: ''}
                            {monthName === 'march' || monthName === 'april' || monthName === 'may' || monthName === 'june' || monthName === 'july' ? <p className="no_cupons">O sorteio final acontecerá em 20/08/2022*.</p>: ''} */}
                            
                    </div>
                    {/* <div className="text-data_cupons">
                        
                        <p>*Se a sua empresa não mantiver a frequência mensal de R$5 mil em compras durante cada mês do trimestre, ela não participará do sorteio trimestral e os cupons gerados serão utilizados no sorteio final em 20/08/2022. O mesmo acontecerá se ela participar do sorteio trimestral e não for contemplada.</p>
                    </div> */}
                    <div className="btn_cupons extrato_mobile_voltar link-voltar_cupons">
                        <a href="#" onClick={onClickCloseCuponsError}>Voltar</a>
                    </div>

                </div>
            </Modal>
		</>
	)
}