import React, { useState, useEffect } from 'react';

import Banner from '../../../components/Banner/Banner';
import olhoSvg from '../../../assets/images/color.svg';
import ocultPassword from '../../../assets/images/hidepass.svg';
import RevealPasswordButton from '../../../components/RevealPasswordButton/RevealPasswordButton';
import Modal from '../../../components/Modal/Modal'
import './RecuperarSenhaPage.css'
import CarouselMobile from '../../../components/CarouselMobile/CarouselMobile';

import {useForm} from 'react-hook-form'
import { useHistory } from 'react-router-dom';

import api from '../../../services/api'

import BarraLogo from '../../../components/BarraLogo/BarraLogo'

export default function RecuperarSenhaPage(props){

    const [isHidden, setIsHidden] = useState(null)
    const [isHiddenRePass, setIsHiddenRePass] = useState(null)
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [erroMsg, setErroMsg] = useState('')
    const [isOpenModalError, setIsOpenModalError] = useState(false)
    const history = useHistory()
    const [isOpenModalSucess, setIsOpenModalSucess] = useState(false)
    const [sucessMessage, setSucessMessage] = useState('')
    const [messageTitle,setMessageTitle] = useState('')
    const {watch, trigger, register, handleSubmit, formState:{errors, isValid} } = useForm({mode:"all"});
    const [lowResolution, setLowResolution] = useState(false);

    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE == true){
        var ie = '';
        console.log('sim IE')
        
    }else{
        var ie = '';
        console.log('nao IE')
    }

    useEffect(()=> {
        if(window.innerHeight <= 670){
            setLowResolution(true)
            }
        }, [])


    function showHidePassword(){
        const inputType = document.getElementById("password")
        if(inputType.type === "password"){
            inputType.type = "text"
            setIsHidden(true)
        }else{
            inputType.type = "password"
            setIsHidden(false)
        }
    }
    function showHidePasswordRepassword(){
        const inputTypeRePassword = document.getElementById("rePassword")
        if(inputTypeRePassword.type === "password"){
            inputTypeRePassword.type = "text"
            setIsHiddenRePass(true)
        }else{
            inputTypeRePassword.type = "password"
            setIsHiddenRePass(false)
        }
    }

    async function onSubmit(){

        const data = {
            token: props.match.params.id,
            password
        }
        
        console.log('oi')
        try {

            const response = await api.post(`/v1/password/reset`, data)
            console.log(response.status)
            setIsOpenModalSucess(true)
            setMessageTitle(response.data.title)
            setSucessMessage(response.data.message)

        }catch(err){
            console.log(err)
            setIsOpenModalError(true)
            setMessageTitle(err.response.data.title)
            setErroMsg(err.response.data.message)
        }   

    }

    function onClickClosErrorModal(){
        setIsOpenModalError(false)
        history.push('/')

    }
    function onClickCloseSucessModal(){
        setIsOpenModalSucess(false)
        history.push('/')
        

    }
    return(
        <div className="container-logon-cad col-xs-12 col-sm-12">
            <BarraLogo />
            <Banner />
            <div className="content-side">
                <div className="content">
                    <div className="text-header">
                        <p className="d-none d-lg-block">REDEFINA <br /> SUA SENHA</p>
                        <p className="d-block d-lg-none">REDEFINA SUA SENHA</p>
                    </div>
                    <div className="">
                        
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form">
                    <div className="input-form display_flex-area">
                            <div className="label-span">
                            <label htmlFor="passowrd">Digite uma nova senha</label>
                            <RevealPasswordButton  showHidePassword={()=> showHidePassword()}>
                                {isHidden ?
                                 (<img className="redefine reveal-password" src={ocultPassword} alt="Reveal Password" />)
                                 :
                                 (<img className="redefine reveal-password" src={olhoSvg} alt="Reveal Password" />) 
                                 }
                            </RevealPasswordButton>
                            </div>
                            <input 
                            style={{ borderBottom: errors.password ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("password", {
                                required: "Campo Obrigatório",
                                minLength:{value:6, message:"Senha curta demais"},
                                maxLength:{value:16, message:"Senha grande demais"}
                                
                            })}
                            type="password"
                            id="password"
                            name="password" 
                            placeholder="*********"
                            
                            value={password}
                            onChange={text => setPassword(text.target.value)}
                            /> 
                            {errors.password && <span  className="msgs-error-validate">{errors.password.message}</span>}
                        </div>
                        <div className="input-form display_flex-area">
                            <div className="label-span">
                                <label htmlFor="rePassword">Confirme a nova senha</label>
                                <RevealPasswordButton  showHidePassword={()=> showHidePasswordRepassword()}>
                                    {isHiddenRePass ?
                                    (<img className="redefine reveal-password" src={ocultPassword} alt="Reveal Password" />)
                                    :
                                    (<img className="redefine reveal-password" src={olhoSvg} alt="Reveal Password" />) 
                                    }
                                </RevealPasswordButton>
                            </div>
                            <input 
                            style={{ borderBottom: errors.rePassword ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("rePassword", {
                                required: "Campo Obrigatório",
                                validate: (value) => value === watch('password') || "Ops! As senhas informadas estão diferentes." 
                            })}                            
                            type="password"
                            id="rePassword"
                            name="rePassword" 
                            placeholder="*********" 
                            /> 
                            {errors.rePassword && <span className="msgs-error-validate">{errors.rePassword.message}</span> }
                        </div>

                        <div className="btn-area_next display_flex-area">
                        <button
                        type="submit" 
                        disabled={isIE ? (isValid) : (!isValid)}>
                                <span className="button-content">ENVIAR</span>
                        </button>
                        </div>
          
                    </div>
                    </form>
                    <div className="d-none d-lg-block text-footer responsive-txt" style={{position: lowResolution ? 'relative' : 'absolute'}}>
                        Dúvidas? Escreva para: <a className="link-blue" href="mailto:atendimento@sama100anos.com.br">atendimento@sama100anos.com.br</a>
                        <p className="text-footer responsive-txt last-text">
                            Promoção válida de 01/09/2021 a 31/07/2022. <br />
                            Certificado de autorização SECAP nº 04.014803/2021. <br />
                            Imagens meramente ilustrativas.

                        </p>
                    </div> 
                </div>
            </div>
        </div>
        <div className="d-lg-none col-12 col-sm-12 col-md-12 col-lg-12">
            <CarouselMobile />
        </div>
        <Modal isOpen={isOpenModalSucess} close={onClickCloseSucessModal}>
                <p className="modal-title">{messageTitle}</p>
                <p className="erro-msg">{sucessMessage}</p>
                <div className="btn-area_next">
                            <button type="submit" onClick={onClickCloseSucessModal}>
                                <span className="button-content">FECHAR</span>
                            </button>                           
                        </div>
        </Modal> 
        <Modal isOpen={isOpenModalError} close={onClickClosErrorModal}>
                <p className="modal-title">{messageTitle}</p>
                <p className="erro-msg">{erroMsg}</p>
                <div className="btn-area_next">
                    <button type="submit" onClick={onClickClosErrorModal}>
                        <span className="button-content">FECHAR</span>
                    </button>                           
                </div>
        </Modal> 
        </div>    
    )
}