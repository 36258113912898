import React, { useEffect, useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import Banner from '../../components/Banner/Banner';
import olhoSvg from '../../assets/images/color.svg';
import ocultPassword from '../../assets/images/hidepass.svg';
import RevealPasswordButton from '../../components/RevealPasswordButton/RevealPasswordButton';
// import api from '../../services/api'
import Modal from '../../components/Modal/Modal'
import {useForm} from 'react-hook-form'
import InputMask from 'react-input-mask'
import spinner from '../../assets/images/spinner.svg'
import ReCAPTCHA from "react-google-recaptcha";

import './Registrar.css';

import Regulamento from '../../components/Regulamento/Regulamento';
import CarouselMobile from '../../components/CarouselMobile/CarouselMobile';

import BarraLogo from '../../components/BarraLogo/BarraLogo'

import api from '../../services/api'

export default function NewRegister(props){

    const [isOpenModalSucess, setIsOpenModalSucess] = useState(false)
    const [isOpenModalError, setIsOpenModalError] = useState(false)
    const [isOpenModalErrorAtivado, setIsOpenModalErrorAtivado] = useState(false)

    const [isOpenCNPJIvalid, setIsOpenCNPJIvalid] = useState(false)

    const [formStep, setFormStep] = useState(0);
    
    const {watch, setValue, getValues, register, setFocus, handleSubmit, formState:{errors, isValid} } = useForm({mode:"all", reValidateMode:"onBlur"});
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [accept, setAccept] = useState(false)
    const [isActive, setIsActive] = useState(false);
    const history = useHistory();
    const [cnpjUser, setCnpjUser] = useState(null)
    const [companyUser, setCompanyUser] = useState(null)
    const [emailUser, setEmailUser] = useState(null)
    const [erroMsg, setErroMsg] = useState('')
    const [sucessMessage, setSucessMessage] = useState('')
    const [tokenUser, setTokenUser] = useState('')
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [branches, setBranches] = useState([])
    const [messageTitle, setMessageTitle] = useState('')
    const [cnpjInvalidMsg, setCnpjInvalidMsg] = useState('')

    const [lowResolution, setLowResolution] = useState(false);
    
    const [captcha, setCaptcha] = useState(false);

    const [emptyCompany, setEmptyCompany] = useState(true);

    const [isReadOnlyEmail, setIsReadOnlyEmail] = useState(true);


    function validateEmail(email) {
        const re = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
        return re.test(email);
    }

    useEffect(()=> {
        if(window.innerHeight <= 670){
            setLowResolution(true)
        }

        let companyEmpty = document.getElementById("company").value
        if(companyEmpty === null || companyEmpty === ''){
            setEmptyCompany(true)
        }else{
            setEmptyCompany(false)
        }

        // async function loadBranchs(){
        //     const responseBranch = await api.get(`v1/branches`);
        //     setBranches(responseBranch.data.data)
        // }
        // loadBranchs();

        async function loadData(){

            if(props.match.params.id){
                
                const token = props.match.params.id
                setTokenUser(props.match.params.id)
                try {
        
                    const responseUser = await api.get(`v1/account/recovery/${token}`)
                    if(validarCNPJ(responseUser.data.data.cnpj) === false){
                        setIsOpenCNPJIvalid(true)
                        return;
                    }else{
                        if(responseUser.data.data.email === "" || responseUser.data.data.email === null || validateEmail(responseUser.data.data.email) === false) {
                            setIsReadOnlyEmail(false)
                            setFocus('email')
                            setFocus('password')
                        }else{
                            setIsReadOnlyEmail(true)
                        }
                        setValue("cnpj", `${responseUser.data.data.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")}`)
                        setValue("company", `${responseUser.data.data.company}`)
                        
                        setValue("email", `${responseUser.data.data.email}`)
                        setValue("branch", `${responseUser.data.data.city}`)
                        setIsReadOnly(true)
                    }
                    
                    
                    
                }catch(error){
                    // console.log(error)
                    setMessageTitle(error.response.data.title)
                    setErroMsg(error.response.data.message)
                    setIsOpenModalError(true)
                    
                } 
                
            }
            
        }
        loadData()
        
    }, [])


    //Avancar e Voltar Steps do Form
    const completeFormStep = () => {
        setFormStep(cur => cur + 1)
    }

    const goBackSteps = () => {
        setFormStep(cur => cur - 1)
    }

    const [formValues, setFormValues] = useState({
        cnpj: "",
        email: "",
        branch:"",
        company: "",
        email_alt: "",
        password: "",
        cellphone: "",
        whatsapp_optin:"",
        type: "",
        
      });
    
      //Pegar Valores dos inputs do form cnpj e aplica máscara
      function handleInputChangeClearMask(event) {
        const { name, value } = event.target;
        setFormValues((prevState) => ({
          ...prevState,
          [name]:  value.replace(/[^\d]+/g,''),
          
        }));
      }

      //Pegar Valores dos inputs do form 
      function handleInputChange(event) {
        const { name, value } = event.target;
        setFormValues((prevState) => ({
          ...prevState,
          [name]: value,
    
        }));
      }

    //Mostrar e esconder os numeros digitados nos campos de senha
    const [isHidden, setIsHidden] = useState(null)
    const [isHiddenRePass, setIsHiddenRePass] = useState(null)
    function showHidePassword(){
        const inputType = document.getElementById("password")
        if(inputType.type === "password"){
            inputType.type = "text"
            setIsHidden(true)
        }else{
            inputType.type = "password"
            setIsHidden(false)
        }
    }
    function showHidePasswordRepassword(){
        const inputTypeRePassword = document.getElementById("confirmPassword")
        if(inputTypeRePassword.type === "password"){
            inputTypeRePassword.type = "text"
            setIsHiddenRePass(true)
        }else{
            inputTypeRePassword.type = "password"
            setIsHiddenRePass(false)
        }
    }

    
    function handleCheckOpen(event){
        setIsDisabled(!event.target.checked)
        if(event.target.defaultValue === '1'){
            setAccept(true)  
            
        }
        if(event.target.defaultValue === '0'){
            setAccept(false)
        }
    }


    //Pegar valores do campo radio no step 2 do form
    function handleCheck(event){
        setFormValues({
            ...formValues,
            whatsapp_optin:event.target.value})
    }

    //Tirar o disable do butão apos marcar o input step 2
    function handleCheckAccept(event){
        setIsActive(event.target.checked)
    }


    //Fechar modal de sucesso e erro
    function onClickCloseSucessModal(){
        setIsOpenModalSucess(false)
        history.push('/')

    }
    function onRedirectToFilialList(){
        window.open('https://distribuidora.com.br/sama/filiais/', '_blank')
    }
    function onClickCloseErrorModal(){
        setIsOpenModalError(false)
        history.push('/')

    }
    function onClickCloseErrorModalAtivado(){
        setIsOpenModalErrorAtivado(false)
        history.push('/')

    }
    function onClickCloseCNPJ(){
        setIsOpenCNPJIvalid(false)
        history.push('/')

    }

    const renderButton = () => {
        if(formStep > 3){
            return undefined

        }else if(formStep === 2){
            return
        //Abre os botões no corpo do prórpio step no form
        }else if(formStep === 1){
            return(
         
                <div className="btn-area_next">
                    <p className="d-none d-sm-none d-md-block">
                        <span className="backArrow">&larr;</span>
                        <Link className="link-blue" onClick={goBackSteps} to="#">Voltar</Link>
                    </p>
                    <button 
                    disabled={!isValid}
                    onClick={completeFormStep}
                    type="button">
                        <span className="button-content">PRÓXIMO</span>
                    </button>
                    <p className="d-block d-sm-block d-md-none">
                        <span className="backArrow">&larr;</span>
                        <Link className="link-blue" onClick={goBackSteps} to="#">Voltar</Link>
                    </p>

                </div>
        )
        }
        else{
            return(
         
                    <div className="btn-area_next ">
                        <p className="d-none d-sm-none d-md-block">
                            <span className="backArrow">&larr;</span>
                            <Link className="link-blue"  to="/">Voltar</Link>
                        </p>
                        <button 
                        disabled={!isValid || !captcha}
                        onClick={completeFormStep}
                        type="button">
                            <span className="button-content">PRÓXIMO</span>
                        </button>
                        <p className="d-block d-sm-block d-md-none">
                            <span className="backArrow">&larr;</span>
                            <Link className="link-blue"  to="/">Voltar</Link>
                        </p>
                    </div>
            )
        }
    }   
    function validarCNPJ(cnpj) {
        let tamanho,soma,pos,i, resultado, numeros, digitos;
        cnpj = cnpj.replace(/[^\d]+/g,'');
       
        if(cnpj == '') return false;
         
        if (cnpj.length != 14)
            return false;
       
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999")
            return false;
             
        // Valida DVs
        tamanho = cnpj.length - 2
        numeros = cnpj.substring(0,tamanho);
        digitos = cnpj.substring(tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
             
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
              return false;
               
        return true;
        
       }
      


    async function getDatafromCnpj(ev){
        const {value} = ev.target;
        const getCnpj = value.replace(/[^\d]+/g,'');
        
        

        if(getCnpj?.length !== 14 || validarCNPJ(value) === false){
            setIsOpenCNPJIvalid(true)
            return;
        }
        try {
            const responseCnpj = await api.get(`/v1/account/cnpj/${getCnpj}`)
            // console.log(responseCnpj)
            setValue("company", `${responseCnpj.data.data.company}`)
            setValue("cnpj", `${responseCnpj.data.data.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")}`)
            setValue("email", `${responseCnpj.data.data.email}`)
            setValue("branch", `${responseCnpj.data.data.city ? responseCnpj.data.data.city : ''}`)
            setTokenUser(responseCnpj.data.data.confirm_hash)
            setIsReadOnly(true)
            if(responseCnpj.data.data.email === "" || responseCnpj.data.data.email === null || validateEmail(responseCnpj.data.data.email) === false) {
                setIsReadOnlyEmail(false)
                setFocus('email')
                setFocus('password')
            }else{
                setIsReadOnlyEmail(true)
            }
        }catch(error){

            setMessageTitle(error.response.data.title)
            setErroMsg(error.response.data.message)
            
            if(error.response.status === 404){
                setIsOpenModalErrorAtivado(true)
            }else if(error.response.status === 409){
                setIsOpenModalError(true)
            }
        }
        
        

        
    }
    const submitForm = async (data) => {

        if(tokenUser || props.match.params.id){
            const {cellphone, email_alt, password, whatsapp_optin, token} = data;
            setLoading(true);
        
            try{
                
                const response = await api.post(`v1/account/confirm`, {
                    cellphone: data.cellphone?.replace(/[^\d]+/g,''),
                    email_alt,
                    password,
                    whatsapp_optin,
                    token: tokenUser
                })
                // console.log(response.status)
                // console.log(data );
    
    
                if(response.status === 201){
                    console.log(response.status)
                    setIsOpenModalSucess(true)
                    setMessageTitle(response.data.title)
                    setSucessMessage(response.data.message)
                    
                }
    
            }catch(err){
                setIsOpenModalError(true)
                setMessageTitle(err.response.data.title)
                setErroMsg(err.response.data.message)
                // console.log(err)
            }




        }else{
            const {cnjp, company, branch, cellphone, email_alt,email, password, whatsapp_optin, type} = data;
            setLoading(true);
        
            try{
                
                const response = await api.post(`/v1/register`, {
                    cnpj: data.cnpj.replace(/[^\d]+/g,''),
                    company,
                    branch,
                    cellphone: data.cellphone?.replace(/[^\d]+/g,''),
                    email,
                    email_alt,
                    password,
                    whatsapp_optin,
                    type
                })
                // console.log(response.status)
                // console.log(data );
    
    
                if(response.status === 201){
                    // console.log(response.status)
                    setIsOpenModalSucess(true)
                    setMessageTitle(response.data.title)
                    setSucessMessage(response.data.message)
                }
    
            }catch(err){
                setIsOpenModalError(true)
                setMessageTitle(err.response.data.title)
                setErroMsg(err.response.data.message)
                setCnpjInvalidMsg(err.response.data.data.cnpj ? err.response.data.data.cnpj : '')
                // console.log(err)
            }
        }

    }
    function handleChangeCaptcha() {

        setCaptcha(prevToggle => !prevToggle)
        // do whatever you want with isChecked value
    }
    return(
        <div className="container-logon-cad new-register-section col-xs-12 col-sm-12">
            <BarraLogo/>
            <Banner />
            <div className="content-side">
                <div className="content">
                    <form onSubmit={handleSubmit(submitForm)}>
                    {formStep >= 0 && (
                    <div className={formStep === 0 ? 'block':'hidden'}>
                    <div className="text-header">
                        <p>FINALIZE O SEU CADASTRO<br />PARA PARTICIPAR DA CAMPANHA</p>
                    </div>
                    <div className="form">
                        <div className="input-form display_flex-area">
                            <label htmlFor="cnpj">*CNPJ da sua empresa</label>
                            
                            {props.match.params.id ? 
                            (<>
                            <input 
                            style={{ borderBottom: errors.cnpj ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("cnpj", {
                                required: "Campo Obrigatório",
                            })}
                            type="text"
                            id="cnpj"
                            name="cnpj" 
                            readOnly
                            placeholder="00.000.000/0000-00"
                            

                            />                            
                            </>):
                            (<>
                            <InputMask 
                            style={{ borderBottom: errors.cnpj ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("cnpj", {
                                required: "Campo Obrigatório",
                                minLength:{value:18, message:"CNPJ inválido"}
                            })}
                            mask="99.999.999\/9999-99" 
                            onBlur={getDatafromCnpj}
                            maskChar=""
                            type="text"
                            id="cnpj"
                            name="cnpj" 
                            placeholder="00.000.000/0000-00"
                            
                            />
                            </>)}
                            {errors.cnpj && <span  className="msgs-error-validate">{errors.cnpj.message}</span> }

                        </div>
                        <div className="input-form display_flex-area">
                            <div className="label-span">
                                <label htmlFor="email">*Razão Social</label>
                            </div>
                           {props.match.params.id ? 
                           (<>
                           <input 
                           style={{ borderBottom: errors.company ? '1px solid red' : '1px solid #7A7A7A' }}
                           className="readOnly"
                           {...register("company", {
                               required: "Campo Obrigatório",
                           })}
                           type="text"
                           id="company"
                           name="company" 
                           readOnly={isReadOnly}
                           placeholder="Nome da Empresa"
                           readOnly
                           /> 
                           
                           </>) :

                           (<>
                            <input 
                            style={{ borderBottom: errors.company ? '1px solid red' : '1px solid #7A7A7A' }}
                           {...register("company", {
                               required: "Campo Obrigatório",
                           })}
                           type="text"
                           id="company"
                           name="company" 
                           readOnly={isReadOnly}
                           placeholder="Nome da Empresa"
                           /> 
                           </>)}


                             {errors.company && <span  className="msgs-error-validate">{errors.company.message}</span> }
                        </div>   
                        {/* <div className="input-form display_flex-area">
                            <div className="label-span">
                                <label htmlFor="email">*Qual filial te atende?</label>
                            </div>
                           
                           <>
                           <select 
                           name="branch" 
                           id="branch"
                           readOnly={isReadOnly}
                           {...register("branch", {
                                required: "Campo Obrigatório",
                           })}
                           >
                               <option  value=""></option>
                               {branches.sort((a, b) => {
                                    if (a.name < b.name)
                                        return -1;
                                    if (a.name > b.name)
                                        return 1;
                                    return 0;
                                }).map((item,i) =>{
                                   return(
                                       <>
                                       <option key={i} value={`${item.name}`}>{item.name}</option>
                                       </>
                                   )
                               })}
                           </select>
                           </>

                             {errors.branch && <span  className="msgs-error-validate">{errors.branch.message}</span> }
                        </div>                       */}
                        <div className="input-form display_flex-area">
                            <div className="label-span">
                                <label htmlFor="branch">*Qual filial te atende?</label>
                            </div>
                            <input 
                            style={{ borderBottom: errors.branch ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("branch", {
                                required: "Campo Obrigatório",
                              })}
                            type="branch"
                            id="branch"
                            name="branch" 
                            placeholder=""
                            readOnly={isReadOnly}
                            /> 
                             {errors.branch && <span  className="msgs-error-validate">{errors.branch.message}</span> }
                        </div>
                        
                        <div className="input-form display_flex-area">
                            <div className="label-span">
                                <label htmlFor="email">*E-mail</label>
                            </div>
                            <input 
                            style={{ borderBottom: errors.email ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("email", {
                                required: "Email Obrigatório",
                                pattern: {
                                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                    message: "Email inválido!"
                                  }
                              })}
                            type="email"
                            id="email"
                            name="email" 
                            placeholder="exemplodeemail@email.com.br"
                            readOnly={isReadOnlyEmail}
                            /> 
                             {errors.email && <span  className="msgs-error-validate">{errors.email.message}</span> }
                        </div>
                        <div className="input-form display_flex-area">
                            <div className="label-span">
                                <label htmlFor="otherEmail">Deseja cadastrar outro email?</label>
                            </div>
                            <input 
                            {...register("email_alt", {
                                pattern: {
                                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                    message: "Email inválido!"
                                  }
                              })}
                            type="email"
                            id="email_alt"
                            name="email_alt" 
                            placeholder="seuemail@email.com.br"
                            value={formValues.email_alt}
                            onChange={handleInputChange}
                            /> 
                            {errors.email_alt && <span  className="msgs-error-validate">{errors.email_alt.message}</span> }
                        </div>
                        <div className="double-inputs display_flex-area">
                        <div className="input-form">
                            <div className="label-span">
                                <label htmlFor="passowrd">Senha</label>
                                <RevealPasswordButton  showHidePassword={()=> showHidePassword()}>
                                    {isHidden ?
                                    (<img className="newRegister reveal-password" src={ocultPassword} alt="Reveal Password" />)
                                    :
                                    (<img className="newRegister reveal-password" src={olhoSvg} alt="Reveal Password" />) 
                                    }
                                </RevealPasswordButton>
                            </div>
                            <input 
                            style={{ borderBottom: errors.password ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("password", {
                                required: "Campo Obrigatório",
                                minLength:{value:6, message:"Senha curta demais"},
                                maxLength:{value:16, message:"Senha grande demais"}
                            })}
                            type="password"
                            id="password"
                            name="password" 
                            placeholder="**********" 
                            /> 
                            {errors.password && <span  className="msgs-error-validate">{errors.password.message}</span> }
                        </div>
                        <div className="input-form">
                            <div className="label-span">
                                <label htmlFor="confirmPassword">Confirmar senha</label>
                                <RevealPasswordButton  showHidePassword={()=> showHidePasswordRepassword()}>
                                    {isHiddenRePass ?
                                    (<img className="newRegister reveal-password" src={ocultPassword} alt="Reveal Password" />)
                                    :
                                    (<img className="newRegister reveal-password" src={olhoSvg} alt="Reveal Password" />) 
                                    }
                                </RevealPasswordButton>
                            </div>
                            <input 
                            style={{ borderBottom: errors.confirmPassword ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("confirmPassword", {
                                required: "Campo Obrigatório",
                                validate: (value) => value === watch('password') || "Ops! As senhas informadas estão diferentes." 
                                })}
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword" 
                            placeholder="**************" 

                            /> 
                            {errors.confirmPassword && <span className="msgs-error-validate">{errors.confirmPassword.message}</span> }
                        </div>                                                
                        </div>
                        <div className="required-text display_flex-area">
                           <span>*Campos obrigatórios</span>
                        </div>
                           <ReCAPTCHA
                            sitekey="6LckXCQcAAAAAM1c-jxoI93qrim_wS0jcNY7EHaY"
                            onChange={handleChangeCaptcha}
                        />
                    </div>
                    </div>
                        )}
                        {formStep >= 1 && (
                            <div className={formStep === 1 ? 'block':'hidden'}>
                            <div className="text-header_whapp margin-bt">
                                <p className="whats_sama">WHATSAPP SAMA 100 ANOS</p>
                            </div>
                            <div>
                            <p className="header_second_pOne">Seu canal direto conosco</p>
                            </div>
                            <div className="text-header_second">
                                <p className="header_second_pTwo">Receba todas as novidades da campanha <br /> na palma da sua mão.</p>
                            </div>
                            <div className="form">
                            <div className="accpet_orNot display_flex-area wthsapp_box" onChange={handleCheck}>
                            <span > 
                            <input
                            style={{ borderBottom: errors.whatsapp_optin ? '1px solid red' : '1px solid #7A7A7A' }}
                                {...register("whatsapp_optin", {
                                    required: "CNPJ Obrigatório",
                                    })}
                            type="radio"
                             id="notAccpet"  name="whatsapp_optin" value="0" checked={formValues.whatsapp_optin == "0"}  onClick={handleCheckOpen}/>  <label className="accpetornot" htmlFor="notAccpet">Não aceito receber</label> </span>
                            <span>
                            <input
                            style={{ borderBottom: errors.whatsapp_optin ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("whatsapp_optin", {
                                required: "CNPJ Obrigatório",
                              })}
                             type="radio" id="accept" name="whatsapp_optin" value="1" checked={formValues.whatsapp_optin == "1"} onClick={handleCheckOpen}/> <label className="accpetornot" htmlFor="accept">Aceito receber</label></span>
                            </div>
                            
                            {accept && 
                            <div className="input-form">
                                <label htmlFor="cpng">Cadastre seu número</label>
                                <br />
                                <InputMask
                                style={{ borderBottom: errors.cellphone ? '1px solid red' : '1px solid #7A7A7A' }}
                                mask="99 99999-9999"
                                maskChar="" 
                                {...register("cellphone", {
                                    required: "Informe número do celular",
                                    validate: () => watch('whatsapp_optin') === "1"
                                    })}
                                type="text"
                                id="cellphone"
                                name="cellphone" 
                                placeholder="(XX) XXXXX-XXXX" 
                                /> 
                                <br />
                                 {errors.cellphone && <span  className="msgs-error-validate">{errors.cellphone.message}</span> }
                            </div>
                            }
                            </div>
                            </div>
                            )}

                    {formStep >= 2 && (
                        <div className={formStep === 2 ? 'block':'hidden'}>
                        <div className="form" >
                            <div className="text-header_whapp">
                                <p className="finalizar_text">PARA FINALIZAR SEU CADASTRO, <br /> LEIA O REGULAMENTO E DÊ O ACEITE</p>
                            </div>
                            <div className="text-header_second regulations_box display_flex-area">
                               <Regulamento />
                            </div>
                            <div className="accpet_orNot display_flex-area">
                                <span>
                                <input 
                                type="checkbox"
                                onChange={event => handleCheckAccept(event)}
                                name="regulations" 
                                checked={isActive}
                                id="regulations" />
                                <label htmlFor="regulations">Declaro que li o regulamento completo e estou de acordo com todos os seus termos.</label> </span>
                            </div>
                        </div>
                        <div className="btn-area_next">
                    <p className="d-none d-sm-none d-md-block">
                        <span className="backArrow">&larr;</span>
                        <Link className="link-blue" onClick={goBackSteps} to="#">Voltar</Link>
                    </p>
                    <button
                            disabled={!isActive}
                            type="submit"> 
                                {loading ?
                                (<>
                                 <span className="login button-content">{loading ? 'ENVIANDO...' : 'ENVIAR'}</span> {loading && (<><div className="spinner"><img className="login" src={spinner} alt="Reveal Password" /></div></>)}
                                </>):
                                (<>
                                 <span className="button-content">ENVIAR</span>
                                </>)}
                               
                             </button>
                    <p className="d-block d-sm-block d-md-none">
                        <span className="backArrow">&larr;</span>
                        <Link className="link-blue" onClick={goBackSteps} to="#">Voltar</Link>
                    </p>

                    </div>
                        </div>
                        )}

 
                        {renderButton()}


                        {/* {loading && <h1 className="loader">Enviando...</h1>} */}
                    </form>
                    <div className="d-none d-lg-block text-footer responsive-txt" style={{position: lowResolution ? 'relative' : 'relative'}}>
                            Dúvidas? Escreva para: <a className="link-blue" href="mailto:atendimento@sama100anos.com.br">atendimento@sama100anos.com.br</a>
                            <p className="text-footer responsive-txt last-text">
                            Promoção válida de 01/09/2021 a 31/07/2022. <br />
                            Certificado de autorização SECAP nº 04.014803/2021. <br />
                            Imagens meramente ilustrativas.

                            </p>
                        </div> 
                </div>
            </div>

            <div className="d-lg-none col-12 col-sm-12 col-md-12 col-lg-12">
                <CarouselMobile />
            </div>
            
            <Modal isOpen={isOpenModalSucess} close={onClickCloseSucessModal}>
                <p className="modal-title">{messageTitle}</p>
                <p className="erro-msg">{sucessMessage}</p>
                <div className="btn-area_next">
                            <button type="submit" onClick={onClickCloseSucessModal}>
                                <span className="button-content">FECHAR</span>
                            </button>                           
                        </div>
            </Modal>
            <Modal isOpen={isOpenModalError} close={onClickCloseErrorModal} classNome="modalcnpj">
            <div className="modal_cnpj_erro">
                <p className="modal-title">{messageTitle}</p>
                {cnpjInvalidMsg && <p className="modal-title">{cnpjInvalidMsg}</p>}
                
                <p className="erro-msg">{erroMsg}</p>
                
                <div className="btn-area_next">
                            <button className="fechar_registro" type="submit" onClick={onClickCloseSucessModal}>
                                <span className="button-content">FECHAR</span>
                            </button>                           
                            {/* <button className="link_filiais" type="submit" onClick={onRedirectToFilialList}>
                                <span className="button-content">VEJA A LISTA DE FILIAIS</span>
                            </button>                            */}
                        </div>
            </div>
            </Modal>
            <Modal isOpen={isOpenModalErrorAtivado} close={onClickCloseErrorModalAtivado} classNome="modalcnpj">
            <div className="modal_cnpj_erro">
                <p className="modal-title">{messageTitle}</p>
                {cnpjInvalidMsg && <p className="modal-title">{cnpjInvalidMsg}</p>}
                
                <p className="erro-msg">{erroMsg}</p>
                
                <div className="btn-area_next">
                            <button className="fechar_registro" type="submit" onClick={onClickCloseSucessModal}>
                                <span className="button-content">FECHAR</span>
                            </button>                                                     
                        </div>
            </div>
            </Modal>
            <Modal isOpen={isOpenCNPJIvalid} close={onClickCloseCNPJ} classNome="modalcnpj">
            <div className="modal_cnpj_erro">
                <p className="modal-title">DADOS INVÁLIDOS</p>
                {/* <p className="modal-title">Revisar CNPJ</p> */}
                
                <p className="erro-msg">REVISAR CNPJ</p>
                
                <div className="btn-area_next">
                            <button className="fechar_registro" type="submit" onClick={onClickCloseSucessModal}>
                                <span className="button-content">FECHAR</span>
                            </button>                                                     
                        </div>
            </div>
            </Modal>
        </div>  
             
    )
}