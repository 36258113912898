import React from 'react'
import './App.css';

import 'bootstrap/dist/js/bootstrap.bundle'

import Routes from './routes'

function App() {
  return (
    <Routes />
  );
}

export default App;
