import React, { useEffect } from 'react';
import { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import Banner from '../../../components/Banner/Banner';

import Modal from '../../../components/Modal/Modal'
import {useForm} from 'react-hook-form'
import InputMask from 'react-input-mask'
import spinner from '../../../assets/images/spinner.svg'

import CarouselMobile from '../../../components/CarouselMobile/CarouselMobile';

import BarraLogo from '../../../components/BarraLogo/BarraLogo'

import api from '../../../services/api'



import './RecuperarSenhaCnpj.css'
export default function EmailPage(){


    const history = useHistory()
    const [isOpenModalSucess, setIsOpenModalSucess] = useState(false)
    const [isOpenModalError, setIsOpenModalError] = useState(false)
    const [erroMsg, setErroMsg] = useState('')
    const {getValues,setValue, register, handleSubmit, formState:{errors, isValid} } = useForm({mode:"all", reValidateMode:"onSubmit"});
    const [cnpj, setCnpj] = useState('');
    const [loading, setLoading] = useState(false)
    const [messageTitle,setMessageTitle] = useState('')
    const [sucessMessage, setSucessMessage] = useState('')
    const [lowResolution, setLowResolution] = useState(false);

    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE == true){
        var ie = '';
        console.log('sim IE')
        
    }else{
        var ie = '';
        console.log('nao IE')
    }

        useEffect(()=> {
        if(window.innerHeight <= 670){
            setLowResolution(true)
            }
        }, [])



    function onClickCloseSucessModal(){
        setIsOpenModalSucess(false)
        history.push('/')

    }
    
    function onClickClosErrorModal(){
        setIsOpenModalError(false)
        history.push('/')

    }

    async function onSubmit(data){
        
        setLoading(true)
        console.log(data)
        try {

            const response = await api.get(`/v1/password/recovery/${data.cnpj.replace(/[^\d]+/g,'')}`)
            console.log(response.status)
            setIsOpenModalSucess(true)
            setMessageTitle(response.data.title)
            setSucessMessage(response.data.message)

        }catch(err){
            console.log(err)
            setIsOpenModalError(true)
            setMessageTitle(err.response.data.title)
            setErroMsg(err.response.data.message)
        }finally{
            setLoading(false)
        }

    }

    return(
        <div className="container-logon-cad new-pass col-xs-12 col-sm-12">
            <BarraLogo />
            <Banner />
            <div className="content-side">
                <div className="content">
                    <div className="text-header">
                    <p className="d-none d-lg-block text-desk">DIGITE O SEU CNPJ CADASTRADO<br />PARA REDEFINIR SUA SENHA</p>
                    <p className="d-block d-lg-none text-mobile">DIGITE O SEU CNPJ CADASTRADO<br />PARA REDEFINIR SUA SENHA</p>
                    </div>
                    <div className="">
                        
                        <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form">

                        <div className="input-form display_flex-area">
                            <label htmlFor="cpng">CNPJ da sua empresa</label>
                            <InputMask
                            style={{ borderBottom: errors.cnpj ? '1px solid red' : '1px solid #7A7A7A' }}
                            mask="99.999.999\/9999-99" 
                            maskChar=""
                            type="text"
                            {...register("cnpj", {
                                required: "Campo obrigatório",
                                minLength:{value:18, message:"CNPJ inválido"}
                            })}
                            id="cnpj"
                            name="cnpj"
                            placeholder="00.000.000/0000-00" 
                            // value={cnpj}
                            // onChange={(e) => setCnpj(maskCNPJ(e.target.value))}
                            /> 
                            {errors.cnpj && <span  className="msgs-error-validate">{errors.cnpj.message}</span> }
                        </div>
                        <div className="btn-area_next display_flex-area">
                            <p className="d-none d-sm-none d-md-block">
                                <span class="backArrow">←</span>
                                <Link className="link-blue" to="/">Voltar</Link>
                            </p>
                            <button
                            type="submit"
                            disabled={isIE ? (isValid) : (!isValid)}>
                                {loading ? 
                                (<><span className="button-content">ENVIANDO....</span> {loading && (<><div className="spinner"><img className="login" src={spinner} alt="Reveal Password" /></div></>)}</>):
                                (<><span className="button-content">ENVIAR</span></>)}
                                

                            </button>
                            <p className="d-block d-sm-block d-md-none">
                                <span className="backArrow">&larr;</span>
                                <Link className="link-blue" to="/">Voltar</Link>
                            </p> 
                        </div>

                    </div>
                        </form>
                        <div className="d-none d-lg-block text-footer responsive-txt" style={{position: lowResolution ? 'relative' : 'absolute'}}>
                            Dúvidas? Escreva para: <a className="link-blue" href="mailto:atendimento@sama100anos.com.br">atendimento@sama100anos.com.br</a>
                            <p className="text-footer responsive-txt last-text">
                            Promoção válida de 01/09/2021 a 31/07/2022. <br />
                            Certificado de autorização SECAP nº 04.014803/2021. <br />
                            Imagens meramente ilustrativas.

                            </p>
                        </div> 
                </div>
            </div>
        </div>    
        <div className="d-lg-none col-12 col-sm-12 col-md-12 col-lg-12">
            <CarouselMobile />
        </div>
        <Modal isOpen={isOpenModalSucess} close={onClickCloseSucessModal}>
                <p className="modal-title">{messageTitle}</p>
                <p className="erro-msg">{sucessMessage}</p>
                <div className="btn-area_next">
                    <button 
                    type="submit" onClick={onClickCloseSucessModal}>
                        <span className="button-content">FECHAR</span>
                    </button>                           
                </div>
        </Modal>
        <Modal isOpen={isOpenModalError} close={onClickClosErrorModal}>
                <p className="modal-title">{messageTitle}</p>
                <p className="erro-msg">{erroMsg}</p>
                
                <div className="btn-area_next">
                    <button type="submit" onClick={onClickClosErrorModal}>
                        <span className="button-content">FECHAR</span>
                    </button>                           
                </div>
        </Modal>
        </div>    
    )
}