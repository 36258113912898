import React, {useEffect, useState } from 'react';


import 'react-owl-carousel2/lib/styles.css'

import './Logon.css';
import {Link, useHistory } from 'react-router-dom'
import olhoSvg from '../../assets/images/color.svg'
import spinner from '../../assets/images/spinner.svg'
import ocultPassword from '../../assets/images/hidepass.svg'
import Banner from '../../components/Banner/Banner'
import RevealPasswordButton from '../../components/RevealPasswordButton/RevealPasswordButton'
import InputMask from 'react-input-mask'

import CarouselMobile from '../../components/CarouselMobile/CarouselMobile'

import BarraLogo from '../../components/BarraLogo/BarraLogo'

// import api from '../../services/api';
// import {login} from '../../services/auth'
import ReCAPTCHA from "react-google-recaptcha";
import {useForm} from 'react-hook-form'

import Modal from '../../components/Modal/Modal'

import api from '../../services/api';
import {login} from '../../services/auth'

export default function Logon(){
    const {register, handleSubmit, formState:{errors, isValid} } = useForm({mode:"all", reValidateMode:"onSubmit"});
    const [isOpen, setIsOpen] = useState(false);
    const [messageError, setMessageError] = useState('');
    const history = useHistory();
    const [isHidden, setIsHidden] = useState(null);
    const [cnpj, setCnpj] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [messageTitle,setMessageTitle] = useState('');

    const [captcha, setCaptcha] = useState(false);
    
    const [lowResolution, setLowResolution] = useState(false);

    const [isOpenExpiredRegistration, setIsOpenExpiredRegistration] = useState(false);


    var isIE = /*@cc_on!@*/false || !!document.documentMode;


    useEffect(()=> {
        if(window.innerHeight <= 790){
            setLowResolution(true)
        }
    }, [])


    function handleChangeCaptcha() {

        setCaptcha(prevToggle => !prevToggle)
        // do whatever you want with isChecked value
    }
  
    //   console.log(keepLogged)
      
    const onSubmitLogin = async function(data){
   
        setLoading(true)
        // console.log(data)
        try{
            const response = await api.post(`/v1/login`, {
                cnpj: data.cnpj.replace(/[^\d]+/g,''),
                password: data.password
            })
            login(response.data.data.token);
            history.push("/inicio");
            // console.log(response.data.data.token)

        }catch(error){
            setMessageTitle(error.response.data.title)
            setMessageError(error.response.data.message)
            setIsOpen(true)
            // console.log(error.response.data.message)
        }finally{
            setLoading(false)
        }

    }

    function showHidePassword(){
        const inputType = document.getElementById("password")
        if(inputType.type === "password"){
            inputType.type = "text"
            setIsHidden(true)
        }else{
            inputType.type = "password"
            setIsHidden(false)
        }
    }

    function onClickCloseError(){
        setIsOpen(false)

    }
    function onCloseExpiredRegistration(){
        setIsOpenExpiredRegistration(false)

    }

    async function hanlderAccessRegister(){
        try{
            const response = await api.get(`/v1/block`)
            
            history.push("/registrar");
            

        }catch(error){
            setMessageTitle(error.response.data.title)
            setMessageError(error.response.data.message)
            setIsOpen(true)
            // console.log(error.response.data.message)
        }
    }





    return(
        <div className="container-logon-cad col-xs-12 col-sm-12">
            <BarraLogo/>
            <Banner />

            <div className="content-side">
                <div className="content">
                    <div className="text-header">
                        <p>FAÇA O SEU LOGIN PARA ACESSAR <br /> O SITE DA CAMPANHA</p>
                    </div>
                        <form onSubmit={handleSubmit(onSubmitLogin)}>
                        <div className="form">
                        <div className="input-form display_flex-area">
                            <label htmlFor="cnpj">CNPJ da sua empresa</label>
                            <InputMask 
                            style={{ borderBottom: errors.cnpj ? '1px solid red' : '1px solid #7A7A7A' }}
                            mask="99.999.999\/9999-99" 
                            maskChar=""
                            {...register("cnpj", {
                                required: "Insira seu CNPJ",
                                minLength:{value:18, message:"CNPJ inválido"}
                            })}
                            type="text"
                            id="cnpj"
                            name="cnpj" 
                            placeholder="XX.XXX.XXX/XXXX-XX" 
                            // value={cnpj}
                            // onChange={text => setCnpj(text.target.value)}
                            /> 
                            {errors.cnpj && <span  className="msgs-error-validate">{errors.cnpj.message}</span> }
                        </div>
                        <div className="input-form display_flex-area">
                            <div className="label-span">
                                <label htmlFor="passowrd">Senha</label>
                                
                            </div>
                            <RevealPasswordButton  showHidePassword={()=> showHidePassword()}>
                                {isHidden ?
                                 (<img className="login reveal-password" src={ocultPassword} alt="Reveal Password" />)
                                 :
                                 (<img className="login reveal-password" src={olhoSvg} alt="Reveal Password" />) 
                                 }
                            </RevealPasswordButton>

                            
                            <input 
                            style={{ borderBottom: errors.password ? '1px solid red' : '1px solid #7A7A7A' }}
                            {...register("password", {
                                required: "Insira sua senha",
                                minLength:{value:6, message:"Senha curta demais"},
                                maxLength:{value:16, message:"Senha grande demais"}
                                
                            })}                        
                            type="password"
                            id="password"
                            name="password" 
                            placeholder="*********" 
                            // value={password}
                            // onChange={text => setPassword(text.target.value)}
                            /> 
                            {errors.password && <span  className="msgs-error-validate">{errors.password.message}</span> }
                        </div>
                        <span className="responsive-txt"><Link className="link-blue hover_btn" to="/recuperar-senha-cnpj">Esqueceu sua senha?</Link></span>
                        <div className="keep-loggedin display_flex-area">
                            {/* <div className="keep-loggedin_box">
                                <input type="checkbox" id="keepLogged" name="keepLogged" onChange={e => handleChange(e)} />
                                <label htmlFor="keepLogged">Manter logado</label>
                            </div> */}
                            <ReCAPTCHA
                            sitekey="6LckXCQcAAAAAM1c-jxoI93qrim_wS0jcNY7EHaY"
                            onChange={handleChangeCaptcha}
                        />
                        </div>
                        <div className="btn-area login">
                            <button
                             disabled={isIE ?  (!captcha): (!isValid || !captcha)}
                            type="submit"> 
                                {loading ?
                                (<>
                                 <span className="login button-content">
                                     {loading ? 'ENTRANDO...' : 'ENTRAR'}
                                </span> 
                                {loading && (<><div className="spinner"><img className="login" src={spinner} alt="Reveal Password" /></div></>)}
                                </>):
                                (<>
                                 <span className="button-content">ENTRAR</span>
                                </>)}
                               
                             </button>
                            <p className="">Ainda não fez o cadastro?</p>
                            <p className="responsive-txt"><Link className="link-blue text-login_bold hover_btn" to="" onClick={()=> hanlderAccessRegister()}>Faça agora!</Link></p>
                        </div>
                        </div>
                        </form>
                        <div className="d-none d-lg-block text-footer responsive-txt" style={{position: lowResolution ? 'relative' : 'absolute'}}>
                            Dúvidas? Escreva para: <a className="link-blue" href="mailto:atendimento@sama100anos.com.br">atendimento@sama100anos.com.br</a>
                            <p className="text-footer responsive-txt last-text">
                            Promoção válida de 01/09/2021 a 31/07/2022. <br />
                            Certificado de autorização SECAP nº 04.014803/2021. <br />
                            Imagens meramente ilustrativas.

                            </p> 
                        </div> 
                </div>
            </div>
            
            <div className="d-lg-none col-12 col-sm-12 col-md-12 col-lg-12">
                <CarouselMobile />
            </div>

            <Modal isOpen={isOpen} close={onClickCloseError} classNome="login_modal">
                <p className="modal-title">{messageTitle}</p>
                <p className="erro-msg">{messageError}</p>
                <div className="btn-area_next">
                            <button type="submit" onClick={onClickCloseError}>
                                <span className="button-content">FECHAR</span>
                            </button>                           
                        </div>
            </Modal>
        </div>

    )
}