import React, {Component}  from 'react'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'

import Logon from './pages/Logon/Logon'
import Registrar from './pages/Registrar/Registrar'
import RecuperarSenhaCnpj from './pages/RecuperarSenha/RecuperarSenhaCnpj/RecuperarSenhaCnpj'
import RecuperarSenhaPage from './pages/RecuperarSenha/RecuperarSenhaPage/RecuperarSenhaPage'

import Home from './pages/Home/Home'
import Cupons from './pages/Cupons/Cupons'
import Contato from './pages/Contato/Contato'
import Ganhadores from './pages/Ganhadores/Ganhadores'
import Encerramento from './pages/Encerramento/Encerramento'
import BarraLogo from './components/BarraLogo/BarraLogo'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import {isAuthenticated} from './services/auth'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

const PublicRoute = ({ component: Component, ...rest }) => (
<Route
    {...rest}
    render={props =>
    isAuthenticated() ? (
        <Redirect to={{ pathname: "/inicio", state: { from: props.location } }} />
    ) : (
        <Component {...props} />
    )
    }
/>

);

export default function Routes(){
    return(
        
        <BrowserRouter>
     
            <Switch>
                <PublicRoute path="/" exact component={Logon}/>
                <PublicRoute path="/registrar" exact component={Registrar}/>
                <PublicRoute path="/registrar/:id" exact component={Registrar}/>
                <PublicRoute path="/recuperar-senha-cnpj" exact component={RecuperarSenhaCnpj}/>
                <PublicRoute path="/recuperar-senha/:id" exact component={RecuperarSenhaPage}/>
                <PrivateRoute path="/ganhadores" exact component={Ganhadores}/>
                <PrivateRoute path="/inicio" exact component={Home}/>
                <PrivateRoute path="/seus-numeros-da-sorte" exact component={Cupons}/>
                <PrivateRoute path="/contato" exact component={Contato}/>
                <PrivateRoute path="/encerramento" exact component={Encerramento}/>
            </Switch>
        </BrowserRouter>
    )
}