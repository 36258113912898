import React from 'react'
import calendarioIcone from '../../assets/images/sama/calendario.png'
export default function Calendario(){
    return(
        <div className="texto-regulamento">
            <div className="tri_titulo">
                <div className="">
                    <img src={calendarioIcone} alt="" />
                </div>  
                <div className="tabela_titulo">
                    <p className="tabela_titulo-p">CALENDÁRIO BÔNUS SEMANAIS E MENSAIS</p>
                </div>  
                <div className="subTitulo">
                    <div>
                    <p className="tabela_sub-p">MARCAS BÔNUS MENSAIS</p>
                    <div className="tabela_sub_span">
                        <p className="calendario_texto_az"><span>Outubro/2021 </span></p>
                        <p><span>Tenneco (Monroe Axios) </span></p>
                        <p className="calendario_texto_az"><span>Dezembro/2021  </span></p>
                        <p><span>Timken </span></p>
                        <p className="calendario_texto_az"><span>Fevereiro/2022  </span></p>
                        <p><span>Lumileds (Philips e Haloway) </span></p>
                        <p className="calendario_texto_az"><span>Abril/2022  </span></p>
                        <p><span>Dana (Spicer) </span></p>
                        <p className="calendario_texto_az"><span>Junho/2022   </span></p>
                        <p><span>Valeo</span></p>
                        <p className="calendario_texto_az"><span>Julho/2022   </span></p>
                        <p><span>Viemar </span></p>
                    </div>
                    </div>
                    <div>
                    <p className="tabela_sub-p">MARCAS CORINGAS SEMANAIS</p>
                    <div className="tabela_sub_span">
                        <p className="texto_amarelo_tabela">Outubro/2021</p>
                        <p className="calendario_texto_az"><span>04 a 09/10/2021</span></p>
                        <p><span>DYNA</span></p>
                        <p className="calendario_texto_az"><span>11 a 16/10/2021</span></p>
                        <p><span>HENKEL (LOCITE)</span></p>
                        <p className="calendario_texto_az"><span>18 a 23/10/2021</span></p>
                        <p><span>BINS</span></p>
                    </div>
                    <div className="tabela_sub_span">
                        <p className="texto_amarelo_tabela">Novembro e Dezembro/2021</p>
                        <p className="calendario_texto_az"><span>01 a 06/11/2021</span></p>
                        <p><span>MOTOPEÇAS</span></p>
                        <p className="calendario_texto_az"><span>08 a 13/11/2021</span></p>
                        <p><span>FLUID SYSTEM (URBA E BROSOL)</span></p>
                        <p className="calendario_texto_az"><span>22 a 27/11/2021 </span></p>
                        <p><span>APLIC</span></p>
                        <p className="calendario_texto_az"><span>29/11/2021 a 04/12/2021 </span></p>
                        <p><span>KOSTAL</span></p>
                        <p className="calendario_texto_az"><span>06 a 11/12/2021 </span></p>
                        <p><span>AUTHOMIX GRUPO 721K / 721B</span></p>
                        <p className="calendario_texto_az"><span>13 a 18/12/2021 </span></p>
                        <p><span>AUTO LINEA</span></p>
                    </div>
                    <div className="tabela_sub_span">
                        <p className="texto_amarelo_tabela">Janeiro e Fevereiro/2022</p>
                        <p className="calendario_texto_az"><span>03 a 08/01/2022 </span></p>
                        <p><span>3-RHO</span></p>
                        <p className="calendario_texto_az"><span>10 a 15/01/2022 </span></p>
                        <p><span>KIT E CIA</span></p>
                        <p className="calendario_texto_az"><span>17 a 22/01/2022 </span></p>
                        <p><span>BOMBER ALTO FALANTE</span></p>
                        <p className="calendario_texto_az"><span>31/01/22 a 05/02/2022 </span></p>
                        <p><span>AMPRI</span></p>
                        <p className="calendario_texto_az"><span>07 a 12/02/2022 </span></p>
                        <p><span>AUTHOMIX GRUPO 721W</span></p>
                        <p className="calendario_texto_az"><span>14 a 19/02/2022 </span></p>
                        <p><span>IGASA</span></p>
                    </div>
                    <div className="tabela_sub_span">
                        <p className="texto_amarelo_tabela">Março/2022</p>
                        <p className="calendario_texto_az"><span>07 a 12/03/2022</span></p>
                        <p><span>COLAR REBITES (DRP)</span></p>
                        <p className="calendario_texto_az"><span>14 a 19/03/2022 </span></p>
                        <p><span>TANCLICK</span></p>
                        <p className="calendario_texto_az"><span>21 a 26/03/2022 </span></p>
                        <p><span>GARRETT</span></p>
                        <p className="calendario_texto_az"><span>28/03/22 a 02/04/2022</span></p>
                        <p><span>IMA</span></p>
                    </div>
                    <div className="tabela_sub_span">
                        <p className="texto_amarelo_tabela">Abril/2022</p>
                        <p className="calendario_texto_az"><span>04 a 09/04/2022 </span></p>
                        <p><span>TIPH (MAX GEAR)</span></p>
                        <p className="calendario_texto_az"><span>11 a 16/04/2022 </span></p>
                        <p><span>AUTHOMIX GRUPO 721L / 721U</span></p>
                        <p className="calendario_texto_az"><span>25 a 30/04/2022</span></p>
                        <p><span>RESERPLASTIC</span></p>
                    </div>
                    <div className="tabela_sub_span">
                        <p className="texto_amarelo_tabela">Maio/2022</p>
                        <p className="calendario_texto_az"><span>02 a 07/05/2022 </span></p>
                        <p><span>NYTRON</span></p>
                        <p className="calendario_texto_az"><span>09 a 14/05/2022  </span></p>
                        <p><span>3M</span></p>
                        <p className="calendario_texto_az"><span>16 a 21/05/2022 </span></p>
                        <p><span>VETORE</span></p>
                        <p className="calendario_texto_az"><span>23 a 28/05/2022 </span></p>
                        <p><span>CORTECO</span></p>
                    </div>
                    <div className="tabela_sub_span">
                        <p className="texto_amarelo_tabela">Junho e Julho/2022</p>
                        <p className="calendario_texto_az"><span>06 a 11/06/2022 </span></p>
                        <p><span>AUTHOMIX GRUPO 721S</span></p>
                        <p className="calendario_texto_az"><span>27/06 a 02/07/2022 </span></p>
                        <p><span>RIOSULENSE</span></p>
                        <p className="calendario_texto_az"><span>04 a 09/07/2022  </span></p>
                        <p><span>FANIA</span></p>
                        <p className="calendario_texto_az"><span>11 a 15/07/2022 </span></p>
                        <p><span>BORGWARNER</span></p>
                        <p className="calendario_texto_az"><span>18 a 22/07/2022  </span></p>
                        <p><span>AGROSTAHL</span></p>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    )
}