import React from 'react'
import './Regulamento.css'
import Tabela13 from '../../assets/images/sama/tabela13.png'

import Tabela3 from '../../assets/images/regulamento/tabela3.png'
import Tabela43 from '../../assets/images/regulamento/tabela4-3.png'
import Tabela44 from '../../assets/images/regulamento/tabela4-4.png'
import seta from '../../assets/images/seta-regulamento.svg'
import tabela_mobile from '../../assets/images/tabela_regulamento.png'

import tab21 from '../../assets/images/sama/tab21.png'
import tab22 from '../../assets/images/sama/tab22.png'
import tab23 from '../../assets/images/sama/tab23.png'
import tab24 from '../../assets/images/sama/tab24.png'

import tab44 from '../../assets/images/sama/tab44.png'

import tab5 from '../../assets/images/sama/tab5.png'
import tab53 from '../../assets/images/sama/tab53.png'
import tab56 from '../../assets/images/sama/tab56.png'

import hip1 from '../../assets/images/sama/hip1.jpg'
import hip2 from '../../assets/images/sama/hip2.jpg'
import hip3 from '../../assets/images/sama/hip3.png'
import hip4 from '../../assets/images/sama/hip4.png'
import hip5 from '../../assets/images/sama/hip5.png'
import hip6 from '../../assets/images/sama/hip6.png'

import empresas from '../../assets/images/sama/tabelaempresasaderentes.png'


export default function Regulamento(){
    return(
        <div className="container-fluid container_regulamento">
            <div className="reg_title">
                <h1>REGULAMENTO PROMOÇÃO <br /> "SAMA 100 ANOS"</h1>
                <h2 className="infos_title">DISTRIBUIDORA AUTOMOTIVA S/A.</h2>
                <p className="endereco"> Rua Ernesto de Castro, 37 - Brás <br />  São Paulo/SP - CEP 03042-010 <br /> CNPJ/ME: 61.490.561/0001-00 <span className="d-none">EMPRESAS ADERENTES</span></p>
            </div>
            <div className="reg_title">
                <p className="endereco">EMPRESAS ADERENTES</p>
                <p className="endereco">Todas as empresas aderentes estão relacionadas no Anexo I, <br /> parte integrante e indissociável deste Regulamento.</p>
            </div>



        <div className="topic">
            <div className="topic_title">
                <h5>1. CONSIDERAÇÕES INICIAIS</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">1.1.</span>A campanha de incentivo intitulada “<span className="texto_bold">SAMA 100 ANOS</span>” será realizada na <span className="texto_bold">modalidade assemelhada a sorteio</span> pela <span className="texto_bold">DISTRIBUIDORA AUTOMOTIVA S/A (“DASA”)</span>, detentora das marcas SAMA e LAGUNA, doravante denominada simplesmente <span className="texto_bold">DASA</span>, na qualidade de Promotora Mandatária, em parceria com as <span className="texto_bold">Empresas Aderentes indicadas no Anexo I</span> deste Regulamento, na qualidade de Promotoras Aderentes, doravante em conjunto denominadas apenas “Promotoras, sendo válida em todo território nacional.</p>
                <p><span className="texto_bold">1.2.</span>	A participação nesta ação é facultativa e destinada <span className="texto_bold">exclusivamente às Pessoas Jurídicas elegíveis</span>, ou seja, aquelas que adquirem e comercializam produtos do segmento de autopeças, das marcas as quais a Distribuidora Automotiva é distribuidora, com inscrição regular no Cadastro Nacional de Pessoas Jurídica (CNPJ), sediadas em território nacional e que cumprirem todas as condições de participação estabelecidas nesse Regulamento. </p>
                <p><span className="texto_bold">1.2.1</span> As pessoas jurídicas participantes serão divididas de acordo com sua faixa de compras em <span className="texto_bold">3 (três) grupos distintos: (i) Grupo A</span> - valor mensal de compra '&gt;' (acima de) R$ 25.000,00 (vinte e cinco mil reais); <span className="texto_bold">(ii) Grupo B</span> - valor mensal de compra '&lt;' (entre) R$ 24.999,99 (vinte e quatro mil novecentos e noventa e nove reais noventa a nove centavos) e 10.000 (dez mil reais); e <span className="texto_bold">(iii) Grupo C</span> - valor mensal de compra '&gt;' (acima de)  R$ 5.000,00 (cinco mil reais) e '&lt;' (abaixo de) R$ 9.999,99 (nove mil novecentos e noventa e nove reais noventa a nove centavos). </p>
                <p><span className="texto_bold">1.3.</span>	O período de participação que inclui a <span className="texto_bold">compra das marcas comercializadas pela DASA, o cadastro dos dados cadastrais da empresa</span>, nos termos deste Regulamento, via hotsite da Promoção  <a className="link-blue" href="https://www.sama100anos.com.br">www.sama100anos.com.br</a>, será dividido em 4 (quatro) períodos, a saber:</p>
                <p><span>Quadro I – Período de Participação</span></p>
                <div className="col-12">
                    <img className="img-fluid" src={Tabela13} alt="Tabela 1.3" />
                </div>
                <p><span className="texto_bold">1.3.1.</span>	Esta campanha poderá ser encerrada antecipadamente, caso todos os números da sorte disponibilizados sejam integralmente distribuídos aos participantes antes da data prevista para o seu término. Nessa hipótese, todos os interessados serão devidamente informados a respeito do encerramento da promoção através do site <a className="link-blue" href="https://www.sama100anos.com.br">www.sama100anos.com.br</a>.</p>
                <p><span className="texto_bold">1.4.</span>	O acesso à Internet é necessário para realização do cadastro para a participação nesta ação e sua qualidade pode variar de acordo com a modalidade e tipo de conexão, do aparelho utilizado para acessar a Internet e da disponibilidade momentânea da rede. </p>
                <p><span className="texto_bold">1.4.1.</span>	As Promotoras não se responsabilizam por eventual impossibilidade de acesso via Internet e/ou pelo desempenho dos computadores, tablets e smartphones que não contenham a configuração mínima ou versões atuais para acessar o hotsite da Promoção.</p>
                <p><span className="texto_bold">1.5.</span>	As dúvidas acerca da participação nesta campanha poderão ser esclarecidas por meio do Fale Conosco disponibilizado no hotsite da ação e/ou pelo e-mail  <a className="link-blue" href="mailto:atendimento@sama100anos.com.br">atendimento@sama100anos.com.br</a></p>
                <p><span className="texto_bold">1.6.	Para participar, os representantes legais da empresa interessada deverão ler e aceitar todos os termos deste Regulamento, estando cientes que ao realizar o seu cadastro, aderem, de forma integral, aos seus referidos termos, caso contrário, não deverão participar desta promoção.</span></p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>2.	CONDIÇÕES E FORMA DE PARTICIPAÇÃO</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">2.1.</span>	Para participar desta Promoção, a pessoa jurídica interessada deverá <span className="texto_bold">(i) efetuar compras mensais de produtos das marcas comercializadas pela Distribuidora Automotiva, SAMA e LAGUNA, no valor mínimo de R$5.000,00 (cinco mil reais) por mês, com frequência mínima de 3 (três) meses seguidos para participar do 1° ao 3º Sorteio Trimestral, (ii) realizar o cadastro no site </span><a className="link-blue" href="https://www.sama100anos.com.br">www.sama100anos.com.br</a> até no máximo, às 23h59min do dia 31/03/2022, informando os seguintes dados: <span className="texto_bold">(a)</span> razão social do estabelecimento, <span className="texto_bold">(b)</span> CNPJ, <span className="texto_bold">(c)</span> telefone comercial (com DDD) e <span className="texto_bold">(d)</span> endereço comercial de e-mail válido. Deverá criar, ainda, uma senha para acessar a sua conta no hotsite da campanha. O login será o CNPJ utilizado para o cadastro.</p>
                <p><span className="texto_bold">2.2.</span>	Na mesma ocasião, o interessado deverá aderir aos termos do Regulamento, conforme o disposto no item 1.6 supra, sendo que o não preenchimento de qualquer campo obrigatório impedirá a conclusão do cadastro e, consequentemente, implicará no impedimento da participação nessa campanha.</p>
                <p><span className="texto_bold">2.3.</span>	Assim, para se cadastrar nesta promoção, os interessados deverão <span className="texto_bold">(i) aceitar</span> os termos do presente Regulamento e <span className="texto_bold">(ii) aderir a este Regulamento</span>, de forma que a <span className="texto_bold">Promotora</span>, a fim de executar esta promoção, utilizará os seus dados cadastrais para fins de controle da distribuição da premiação; formação de banco de dados visando analisar as participações havidas nesta promoção; prestar contas à SECAP; enviar-lhe informativos sobre sua participação e/ou contemplação nesta promoção e sobre promoções em geral e/ou ofertas de seus serviços e produtos de forma personalizada ou não, via e-mail, SMS, WhatsApp e/ou Redes Sociais.</p>
                <p><span className="texto_bold">2.3.1.	A ausência do aceite e adesão ao Regulamento até o último dia do mês de março de 2022 resultará na impossibilidade da efetivação da participação e no consequente recebimento dos Números da Sorte para concorrer no sorteio. Da mesma forma, o não preenchimento de qualquer campo obrigatório impedirá a conclusão do cadastro e, consequentemente, implicará o impedimento da participação.</span></p>
                <p><span className="texto_bold">2.4</span> O registro dos dados cadastrais nesta ação deverá ser realizado 1 (uma) única vez durante todo o período de vigência da campanha, sendo este controle vinculado ao número de inscrição no CNPJ, número do celular e e-mail informados pelo participante.</p>
                <p><span className="texto_bold">2.5</span>	É imprescindível que os participantes forneçam os dados corretos e os mantenham atualizados, completos e verídicos no ato do cadastro, uma vez que tais dados serão utilizados para contato e, consequentemente, entrega do prêmio. Assim, as Promotoras não serão responsáveis quando, em razão do fornecimento de dados incompletos, incorretos ou inverídicos, ficar impossibilitada de realizar a entrega do prêmio.</p>
                <p><span className="texto_bold">2.6</span>	Todos os dados cadastrais poderão ser verificados no momento da validação da participação dos potenciais ganhadores. Todavia, havendo qualquer indício de fraude, as Promotoras poderão desclassificar os participantes que agirem desta forma a qualquer momento.</p>
                <p><span>A.	RECEBIMENTO DE NÚMEROS DA SORTE</span></p>
                <p><span className="texto_bold">2.7</span> Durante o período de participação, <span className="texto_bold underline">a cada</span> <span className="texto_bold">R$5.000,00 (cinco mil reais)</span> <span className="texto_bold underline">e seus múltiplos</span> <span className="texto_bold">em compras de produtos* das marcas comercializadas pela DASA</span>, os Participantes divididos em Grupos A, B, C de acordo com sua faixa de compras, terão direito de receber pelo menos 1 (um) Número da Sorte, dependendo do valor  da compra mensal , desde que mantida a frequência de compras no valor mínimo de R$5.000,00 (cinco mil reais), por mês, em produtos das marcas comercializadas pela <span className="texto_bold">DASA</span>.
	            <br/>*exceto Bosch Diesel </p>
                <p><span className="texto_bold">2.7.1</span> Para participação nos <span className="texto_bold underline">sorteios trimestrais</span>, os participantes deverão manter a frequência mensal de compras no valor mínimo estipulado no item 2.7, <span className="texto_bold underline">por 3 (três) meses consecutivos do período de participação (item 1.3, Quadro I)</span>, para ter direito à quantidade de números da sorte compatível com o valor mensal das compras de cada mês no trimestre. <span className="texto_bold">Exemplo</span>: Se o participante comprou R$ 20.000,00 em set/21, R$ 5.000,00 em out/21 e R$ 18.000,00 em nov/21 (1º trimestre), terá direito a 8 (oito) números da sorte para concorrer no sorteio trimestral desse período de participação (sendo 4 referentes a set/21, 1 referente a out/21 e 3 referentes a nov/21). O “saldo” de R$ 3.000,00 (três mil reais) da compra do último mês será descartado e desconsiderado para o próximo período. </p>
                <p><span className="texto_bold">2.8.</span> Fica desde já estabelecido, que as compras mensais para participação nos sorteios trimestrais devem ser ininterruptas e trimestrais, sendo que, a eventual quebra da continuidade da compra no valor mínimo estabelecido por mês, implicará na invalidação dos números da sorte relativos ao sorteio trimestral cuja frequência de 03 (três) meses não tiver sido mantida. </p>
                <p><span className="texto_bold">2.9.</span> Para o <span className="texto_bold underline">Sorteio Final</span>, não há obrigatoriedade de frequência de compra, nem de 11 (onze) meses, nem tampouco no trimestre. Entretanto, o participante terá direito à quantidade de números da sorte para concorrer ao sorteio final de acordo com o valor de suas compras a cada mês, desde que respeitado o mínimo de compra de R$ 5.000,00 (cinco mil reais). Exemplo: Cliente comprou, durante todo o período da promoção, o total de R$10.000,00 (dez mil reais) em jan/22. <span className="texto_bold">Terá direito a 2 Números da Sorte para concorrer apenas no Sorteio Final.</span></p>
                <p><span className="texto_bold">2.10.</span> Na hipótese de haver devoluções de produtos, os valores desses serão descontados do total da compra e para fins de participação nesta Promoção. <span className="underline">Exemplo:</span> A compra total foi de R$ 5.100,00 (cinco mil e cem reais), mas houve uma devolução de produtos referente aquele pedido de R$ 300,00 (trezentos reais). Dessa forma, para receber o Número da Sorte, faltará o valor de R$ 200,00 (duzentos reais) em compras.</p>
                <p><span className="texto_bold">2.11.</span> Novos clientes serão admitidos nesta Campanha, desde que façam a adesão aos termos deste Regulamento até o mês de março/2022, e cumpram todas as condições estabelecidas neste Regulamento, sendo elegíveis somente aos Sorteios subsequentes ao cadastro, observadas as condições acima.</p>
                <p><span className="texto_bold">2.12. Todos os participantes que cumprirem as condições supra previstas, receberão a quantidade de números da sorte a que fizerem jus mensalmente de acordo com o volume de compras. Porém, só poderão concorrer aos sorteios, os números da sorte cujos titulares cumprirem a condição de frequência mínima de compra estabelecida no item 2.7 supra, sendo desclassificados e invalidados os números da sorte que não cumprirem tal condição.</span></p>
                <p><span className="texto_bold">Exemplificando:</span></p>
                <p>• Cliente comprou R$5.000,00 <span className="texto_bold">por mês</span>, nos meses de setembro/2021 a janeiro/2022 = 3 (três) números da sorte válidos para concorrer ao 1º sorteio trimestral a ser realizado no dia 15/12/2021, e caso não seja contemplado, terá 5 (cinco) para o sorteio final a ser realizado no dia 20/8/2022;</p>
                <p>•	Cliente comprou R$4.000,00 em setembro/2021 e R$5.000,00 <span className="texto_bold">por mês</span>, nos meses de outubro/2021 a janeiro/2022 = 4 números da sorte válidos apenas para concorrer no sorteio final do dia 20/8/2022, uma vez que não cumpriu a meta dos 3 sorteios trimestrais, mas tão somente a meta mensal dos meses de outubro/2021 a janeiro/2022. </p>
                <p>•	Cliente comprou R$15.000,00 <span className="texto_bold">por mês</span>, nos meses de setembro a novembro/2021 = 9 números da sorte válidos para concorrer no 1° sorteio trimestral a ser realizado no dia 15/12/2021. Caso não seja contemplado, ele também participará no sorteio final a ser realizado no dia 20/8/2022, com 9 números da sorte;</p>
                <p>•	Cliente comprou R$5.000,00 no mês de setembro/2021, R$ 20.000,00 <span className="texto_bold">por mês</span> nos meses de outubro e novembro/2021, R$ 10.000,00 <span className="texto_bold">por mês</span> em dezembro/2021 e janeiro/2022, e R$ 5.000,00 em fevereiro/2022 = <span className="underline">14 números da sorte (1 número referente a set/21; 4 referentes a out/21, 4 para nov/21; 2 referentes a dez/21, 2 referentes a jan22 e 1 referente a fev/22), sendo 9 números da sorte para o 1° sorteio trimestral do dia 15/12/2021 e 5 para o 2º sorteio trimestral do dia 16/03. Caso não seja contemplado em nenhum dos sorteios trimestrais que participou, o cliente concorrerá com 14 números da sorte no sorteio final.</span></p>
                <p>•	Cliente comprou R$10.000,00 unicamente no mês de julho/22 = 2 números da sorte para concorrer unicamente no Sorteio Final, a ser realizado no dia 20/8/2022 (considerando que ele tenha feito o cadastro até o dia 31/3/2022). </p>
                <p><span className="texto_bold">2.13. A efetiva contemplação será condicionada à validação da participação, nos termos deste Regulamento.</span></p>
                <p><span className="texto_bold">2.14.</span>	Concluído o mês, a  <span className="texto_bold">DASA</span> analisará o relatório de vendas elaborado pelo seu próprio sistema e gerará imediatamente e de forma automática, pelo menos, 1 (um) Número da Sorte, dependendo do valor das compras realizadas para concorrer aos sorteios trimestrais e final de acordo com a Extração da Loteria Federal, nos termos deste Regulamento. A validação da participação (dados cadastrais) poderá ser feita a qualquer momento e, em especial, no ato da validação dos potenciais ganhadores. </p>
                <p><span className="texto_bold">2.15.</span>	As informações acerca da participação nesta ação e os Números da Sorte que os participantes terão direito, poderão ser consultadas no site <a className="link-blue" href="https://www.sama100anos.com.br">www.sama100anos.com.br</a>, bastando que façam o acesso através de seu login e senha.</p>
                <p><span>B. NÚMEROS DA SORTE EXTRAS – “MARCAS CORINGA”</span></p>
                <p><span className="texto_bold">2.16.	Independentemente de sua categoria, o participante que tiver cumprido a condição essencial para participação nos sorteios trimestrais dessa Campanha, ou seja, que tiver comprado pelo menos R$ 5.000,00 (cinco mil reais) por mês em produtos comercializados pela DASA no trimestre</span>, conforme condição estabelecida no item 2.7 desse Regulamento, poderá, ainda, receber números da sorte <span className="texto_bold underline">extras</span>, da seguinte forma:</p>
                <p><span className="texto_bold">2.16.1.	“marca coringa do mês”:</span> <span className="texto_bold underline">respeitada a condição do sorteio trimestral descrita acima</span>, o participante que efetuar compra da “marca coringa do mês”, no valor mínimo de R$3.000,00 (três mil reais) dentro do mínimo dos R$ 5.000,00 cinco mil reais) gastos no mês, conforme o cronograma descrito no item 2.18. abaixo, terá direito a um cupom extra; </p>
                <p><span className="texto_bold">2.16.2.	 “marca coringa da semana”:</span> <span className="texto_bold underline">respeitada a condição do sorteio trimestral descrita acima</span>, o participante que efetuar compra da “marca coringa da semana”, no valor mínimo de R$1.000,00 (mil reais), dentro do mínimo dos R$ 5.000,00 (cinco mil reais) gastos no mês, conforme o cronograma descrito no item <span className="texto_bold">2.19.</span> abaixo, terá direito a um cupom extra; </p>
                <p><span className="texto_bold">2.17.</span> Caso os Números da Sorte <span className="texto_bold underline">extras</span> decorrentes das compras das <span className="texto_bold">marcas coringa do mês e/ou da semana</span> não tenha(m) sido sorteado(s) no <span className="texto_bold">sorteio trimestral</span> nos termos descritos nos itens 2.12, 2.12.1 e 2.12.2, estes irão participar do <span className="texto_bold">Sorteio Final</span>. E ainda, mesmo não sendo cumprida a condição do sorteio trimestral (frequência mínima dos 3 meses do valor mínimo de R$ 5.000,00 em compras), os participantes que tiverem adquirido as marcas coringa da semana e/ou do mês, mas que não participaram do sorteio trimestral, participarão com os respectivos Números da Sorte <span className="texto_bold">tão somente do Sorteio Final.</span></p>
                <p><span className="texto_bold">2.18.</span>	As <span className="texto_bold">“marcas coringa mensais”</span> abaixo descritas deverão ser observadas pelos participantes conforme calendário a seguir, contendo o mês e a marca coringa:</p>
                <p>•  Outubro/2021 - Tenneco (Monroe Axios); <br />
                •	Dezembro/2021 - Timken; <br />
                •	Fevereiro/2022 - Lumileds (Philips e Haloway); <br />
                •	Abril/2022 - Dana (Spicer); <br />
                •	Junho/2022 - Valeo; <br />
                •	Julho/2022 - Viemar.</p>
                <p><span className="texto_bold">2.19.</span>	Da mesma forma, as “marcas coringa da semana” abaixo descritas deverão ser observadas pelos participantes conforme calendário a seguir, contendo a semana, o mês e a marca coringa:</p>
                <p>•	<span className="underline">Outubro/2021</span> <br />
                04 a 09/10/2021 – DYNA <br />
                11 a 16/10/2021 – HENKEL (LOCITE) <br />
                18 a 23/10/2021 – BINS </p>

                <p>•	<span className="underline">Novembro e Dezembro/2021</span> <br />
                01 a 06/11/2021 – MOTOPEÇAS <br />
                08 a 13/11/2021 – FLUID SYSTEM (URBA E BROSOL) <br />
                22 a 27/11/2021 – APLIC <br />
                29/11 a 04/12/2021 – KOSTAL <br />
                06 a 11/12/2021 – AUTHOMIX GRUPO 721K / 721B <br />
                13 a 18/12/2021 – AUTO LINEA</p>

                <p>•	<span className="underline">Janeiro e Fevereiro/2022</span> <br />
                    03 a 08/01/2022 – 3-RHO <br />
                    10 a 15/01/2022 – KIT E CIA
                    17 a 22/01/2022 – BOMBER ALTO FALANTE <br />
                    31/01/22 a 05/02/2022 – AMPRI <br />
                    07 a 12/02/2022 – AUTHOMIX GRUPO 721W <br />
                    14 a 19/02/2022 – IGASA</p>

                <p>•	<span className="underline">Março/2022</span> <br />
                    07 a 12/03/2022 – COLAR REBITES (DRP) <br />
                    14 a 19/03/2022 – TANCLICK <br />
                    21 a 26/03/2022 – GARRETT <br />
                    28/03/22 a 02/04/2022 – IMA</p>
                
                <p>•	<span className="underline">Abril/2022</span> <br />
                    04 a 09/04/2022 – TIPH (MAX GEAR) <br />
                    11 a 16/04/2022 – AUTHOMIX GRUPO 721L / 721U <br />
                    25 a 30/04/2022 – RESERPLASTIC</p>

                <p>•	<span className="underline">Maio/2022 </span><br />
                    02 a 07/05/2022 – NYTRON <br />
                    09 a 14/05/2022 – 3M <br />
                    16 a 21/05/2022 – VETORE <br />
                    23 a 28/05/2022 – CORTECO</p>
                
                <p>•	<span className="underline">Junho e Julho/2022</span> <br />
                    06 a 11/06/2022 – AUTHOMIX GRUPO 721S <br />   
                    27/06 a 02/07/2022 – RIOSULENSE <br />
                    04 a 09/07/2022 – FANIA <br />
                    11 a 15/07/2022 – BORGWARNER <br />
                    18 a 22/07/2022 – AGROSTAHL
                </p>
                <p><span className="texto_bold">2.20.</span> A título exemplificativo, o cliente comprou R$ 5.000,00 por mês dentro do trimestre de set/2021 a nov/2021, sendo que em outubro comprou R$ 3.000,00 dentro dos R$ 5.000,00 em produtos da <span className="texto_bold">marca coringa do mês</span> e no mês de novembro, comprou R$ 1.000,00 em uma <span className="texto_bold">marca coringa da semana</span> e depois mais R$ 1.000,00 em outra <span className="texto_bold">marca coringa da semana.</span> Nesse caso, o participante, terá direito a 03 (três) Números da Sorte <span className="texto_bold">extras</span> (1 referente a marca coringa do mês de out/21 e 2 referentes às marcas coringa da semana do mês de nov/21) para concorrer no sorteio trimestral do dia 15/12/2021 e, caso esses Números da Sorte não sejam sorteados, concorrerão ao Sorteio Final.</p>
                <p><span className="texto_bold">2.20.1</span> Seguindo o exemplo acima, caso no mês de outubro, o valor total das compras, fosse de R$ 4.000,00 (quatro mil reais) e não de R$ 5.000,00 para manter a condição de frequência mínima de compra, ainda que todas as outras condições de compra das “marcas coringa” fossem cumpridas, os participantes não poderiam concorrer com esses 03 (três) Números da Sorte <span className="texto_bold">Extras</span> no <span className="texto_bold">sorteio trimestral,</span> mas estes poderão concorrer no sorteio final. </p>
                <p><span className="texto_bold">C. NÚMEROS DA SORTE EXTRAS – “MAQUININHA PRONTO!”</span></p>
                <p><span className="texto_bold">2.21.</span> O participante que utilizar a <span className="texto_bold">maquininha de pagamento Pronto!,</span> <span className="texto_bold underline">desde que alcançado o volume mínimo de compras trimestral,</span> ganhará, a cada trimestre que cumprir essa condição, 01 (um) número da sorte adicional, ou seja, um número da sorte extra, para participar dos sorteios trimestrais. Caso o participante não seja premiado nos sorteios trimestrais, esses Números da Sorte <span className="texto_bold">extras</span> concorrerão no sorteio final. </p>
                <p><span className="texto_bold">2.22.</span> Nos últimos 02 (dois) meses do período de participação da campanha, equivalentes a junho e julho de 2022, a mesma regra estipulada no item 2.17 acima será aplicada, sendo considerado, entretanto, o <span className="texto_bold underline">bimestre</span> e não o trimestre. Assim sendo, o cliente que utilizar a maquininha Pronto!, desde que efetuada a compra do valor mínimo no valor de R$ 5.000,00 (cinco mil reais) nos 02 (dois) meses finais concorrerão no <span className="texto_bold underline">Sorteio final.</span></p>
                <div>
                    <div className="col-12 col-lg-12"><img className="img-fluid" src={tab21} alt="" /></div>
                    <div className="col-12 col-lg-12"><img className="img-fluid" src={tab22} alt="" /></div>
                    <div className="col-12 col-lg-12"><img className="img-fluid" src={tab23} alt="" /></div>
                    <div className="col-12 col-lg-12"><img className="img-fluid" src={tab24} alt="" /></div>
                    
                    
                    
                    
                </div>
                <p><span className="texto_bold">2.22.2.</span> Com base no exemplo acima, caso o participante deixe de comprar R$ 5.000,00 (cinco mil reais) em produtos participantes em algum mês, não ganha o Número da Sorte extra para participar do Sorteio Trimestral, e também não concorre no Sorteio Final. </p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>3.	DA PREMIAÇÃO</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">3.1.</span>	Serão distribuídos ao todo nesta Promoção, <span className="texto_bold">100 (cem) pacotes de viagem para Dubai e Catar no valor unitário de R$35.000,00 (trinta e cinco mil reais), perfazendo o valor total de R$3.500.000,00 (três milhões e quinhentos mil reais) em premiação.</span></p>
                <p><span className="texto_bold">3.1.1.</span>	<span className="texto_bold underline">O pacote de viagem será composto por:</span> passagem aérea de classe econômica de ida e volta do aeroporto da Capital do Estado do contemplado até o aeroporto internacional de Guarulhos (Capital – Guarulhos – Capital) e passagem aérea de classe econômica ida e volta do aeroporto internacional de Guarulhos para Dubai e um dia no Catar, hospedagem em quarto duplo de 8 (oito) dias e 7 (sete) noites em hotel categoria turística de ao menos 4 (quatro) estrelas, alimentação, exceto a refeição da “noite livre”, traslado de ida e volta do aeroporto/hotel e seguro viagem a ser usufruído pelos contemplados, <span className="texto_bold underline">exclusivamente</span>, no período de novembro e dezembro/2022.</p>
                <p><span className="texto_bold">3.1.2.</span> A quantidade total de prêmios será dividida de acordo com os grupos aos quais pertencem os clientes, a saber:</p>
                <p>•	<span className="texto_bold underline">Grupo A:</span> 30 (trinta) pacotes de viagem; sendo 4 (quatro) destinados a cada sorteio trimestral e 18 (dezoito) para o sorteio final.</p>
                <p>•	<span className="texto_bold underline">Grupo B:</span> 50 (cinquenta) pacotes de viagem; sendo 6 (seis) destinados a cada sorteio trimestral e 32 (trinta e dois) para o sorteio final.</p>
                <p>•	<span className="texto_bold underline">Grupo C:</span> 20 (vinte) pacotes de viagem, sendo 2(dois) destinados a cada sorteio trimestral e 14 (quatorze) para o sorteio final.</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>4.	FORMA DE ATRIBUIÇÃO DOS NÚMEROS DA SORTE </h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">4.1.</span>	Durante o período de participação, serão emitidas 100 (cem) séries numeradas de 00 a 99 e compostas, cada uma, por 100.000 (cem mil) números de ordem (de 00.000 a 99.999).</p>
                <p><span className="texto_bold">4.2.</span>	Os Números da Sorte serão distribuídos de forma concomitante, equitativa e aleatória a todos os CNPJs de todos os Grupos, de acordo com a ordem de cumprimento das condições de participação na promoção.</p>
                <p><span className="texto_bold">4.3.</span>	Os Números da Sorte distribuídos aos participantes serão compostos por <span className="texto_bold">7 (sete) dígitos,</span> sendo os 2 (dois) primeiros algarismos correspondentes ao Número da Série e os 5 (cinco) subsequentes correspondentes ao Número de Ordem, conforme o exemplo a seguir:</p>
                <div className="col-12"> 
                    <img className="img-fluid" src={tab44} alt="" />
                </div>
                <p><span className="texto_bold">4.4.</span>	Findo o período de participação e antes de cada sorteio da Loteria Federal, a Promotora enviará para a SECAP/ME um resumo do banco de dados, contendo os nomes dos participantes e os números da sorte correspondentes distribuídos.</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>5.	DA IDENTIFICAÇÃO DOS POTENCIAIS GANHADORES</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">5.1.</span>	Serão realizados ao longo da promoção, 4 (quatro) sorteios, sendo 03 (três) sorteios trimestrais e 01 (um) sorteio final, de acordo com os resultados das Extrações da Loteria Federal, para fins de identificação dos 100 (cem) ganhadores desta campanha, conforme quadro abaixo:</p>
                <p><span className="texto_bold">Quadro II – Datas dos Sorteios e Apuração dos Ganhadores</span></p>
                <div>
                    <img className="img-fluid" src={tab5} alt="" />
                </div>
                <p><span className="texto_bold">5.2.</span>	Primeiramente, em todos os sorteios a serem realizados, será aplicada a regra para se conhecer a <span className="texto_bold">Série Contemplada</span>, dentre as 100 (cem) séries distribuídas, dentro da qual se identificará o(s) respectivo(s) ganhador(es) dos Grupos A, B e C dos sorteios, nos termos a seguir expostos.</p>
                <p><span className="texto_bold">DOS SORTEIOS TRIMESTRAIS E DO SORTEIO FINAL: </span></p>
                <p><span className="texto_bold">5.3.</span>	Para identificação da <span className="texto_bold">Série Contemplada de cada sorteio</span>, aplicar-se-á exclusivamente a regra para identificação dos 2 (dois) dígitos que compõem a série, sendo que estes correspondem aos algarismos das <span className="texto_bold">DEZENAS SIMPLES dos 1º e 2° prêmios da Loteria Federal</span>, lido verticalmente de cima para baixo, nos termos do exemplo a seguir:</p>
                <div>
                    <img src={tab53} alt="" />
                </div>
                <p><span className="texto_bold">5.4.</span>	Na eventualidade da Série identificada como Vencedora não ter sido distribuída, será considerada vencedora a série <span className="underline">imediatamente superior</span> ou na falta dessa, <span className="texto_bold underline">em ordem crescente, as demais próxima séries superiores, até a Série 99.</span> Esgotadas as possibilidades, se passará à identificação do <span className="texto_bold">Número de Série distribuído</span> <span className="texto_bold underline">imediatamente inferior ao inicialmente encontrado a partir da regra acima, de forma decrescente,</span> até que se identifique 1 (uma) potencial série vencedora. </p>
                <p><span className="texto_bold">5.4.1.</span> Considerado o exemplo de extração da Loteria Federal acima, na hipótese da <span className="texto_bold">Série 45</span> não ter sido distribuída, deverá ser considerada vencedora a <span className="texto_bold">Série 46</span>. Caso a <span className="texto_bold">Série 46</span> também não tenha sido distribuída, deverá ser considerada como vencedora a <span className="texto_bold">Série 47</span>, e assim sucessivamente de maneira crescente, até que se identifique 1 (uma) Série Distribuída, que será considerada a Vencedora.</p>
                <p><span className="texto_bold">5.4.2.</span> Ainda considerando o exemplo da extração da Loteria Federal acima, numa hipótese, de todas as séries da <span className="texto_bold">45 até a 99</span> não terem sido distribuídas, deverá ser considerada como vencedora a <span className="texto_bold">Série 44.</span> Caso a <span className="texto_bold">Série 44</span> também não tenha sido distribuída, deverá ser considerada como vencedora a <span className="texto_bold">Série 43</span>, e assim sucessivamente de maneira decrescente, até que se identifique 1 (uma) Série Distribuída, que será considerada a Vencedora.</p>
                <p><span className="texto_bold">5.5.</span>	A partir da identificação da <span className="texto_bold">Série Contemplada em cada um dos sorteios</span>, partir-se-á para a identificação do <span className="texto_bold">Número de Ordem Base contemplado</span>, utilizada a combinação a seguir descrita.</p>
                <p><span className="texto_bold">5.6.</span>	Será identificado o <span className="texto_bold">Número de Ordem Base</span> por meio da combinação dos algarismos das <span className="texto_bold">UNIDADES SIMPLES</span> dos <span className="texto_bold">5 (cinco) primeiros prêmios da Loteria Federal</span>, lidos verticalmente de cima para baixo, nos termos do exemplo a seguir:</p>
                <div>
                    <img src={tab56} alt="" />
                </div>
                <p><span className="texto_bold">5.7.</span>	Será considerado, portanto, como <span className="texto_bold">Número da Sorte Base</span>, a combinação entre o número da série e o número de ordem identificados nos termos supra previstos. Com base no exemplo aqui citado, o Número da Sorte Base é <span className="texto_bold">45.18073.</span></p>
                <p><span className="texto_bold">5.7.1.</span>	Assim sendo, em todos os sorteios, será considerado o <span className="texto_bold">primeiro potencial ganhador</span>, o portador do <span className="texto_bold">Número de Ordem Base</span> <span className="texto_bold underline">efetivamente distribuído</span>, identificado nos termos supra previstos. </p>
                <p><span className="texto_bold">5.8.</span>	Caso o <span className="texto_bold">Número de Ordem Base</span> não encontre seu correspondente nos Números de Ordem distribuídos na respectiva série, pelo fato de não ter sido atribuído a qualquer participante, será aplicada a seguinte regra:</p>
                <p><span className="texto_bold verde">Regra de Aproximação</span>: Primeiramente será considerado como potencial ganhador do sorteio o portador do <span className="texto_bold">Número de Ordem distribuído</span> dentro da <span className="texto_bold underline">mesma série imediatamente superior ao base encontrado a partir das regras acima, de forma crescente</span>. Esgotadas as possibilidades, se passará à identificação do <span className="texto_bold">Número de Ordem distribuído</span>, dentro da <span className="texto_bold underline">mesma série, imediatamente inferior ao base encontrado a partir das regras acima, de forma decrescente</span>, até que se identifique 1 (um) potencial ganhador.</p>
                <p><span className="texto_bold">5.9.</span>	Estabelecidas as regras gerais de apuração do <span className="texto_bold">Número de Ordem Base</span> <span className="texto_bold underline">distribuído</span> nos <span className="texto_bold">sorteios trimestrais dos dias 15/12/2021, 16/3/2022, 15/6/2022 e no sorteio final do dia 20/8/2022</span>, <span className="texto_bold underline">o sistema fará a checagem, através do CNPJ do participante, a qual grupo ele pertence: A, B ou C.</span> A partir de então, o sistema fará a identificação dos demais ganhadores de cada grupo, na proporção da quantidade de prêmios estabelecida para cada grupo, em cada sorteio. </p>
                <p><span className="texto_bold">5.10.</span>	A título exemplificativo, considerando-se que <span className="texto_bold">nos sorteios trimestrais</span> serão <span className="texto_bold underline">12 (doze) ganhadores</span>, <span className="texto_bold">sendo</span> <span className="texto_bold underline">4 (quatro) do grupo A, 6 (seis) do grupo B e 2 (dois) do grupo C</span>, e que o número de Sorte Base Vencedor é o 45 -18073. Nesse caso, poderão ser consideradas as seguintes hipóteses abaixo, sempre obedecendo a regra de aproximação do item <span className="texto_bold">5.8.</span>:</p> 
                <div className="hip_container">
                    <div className="col-12 col-lg-4"> <img className="img-fluid" src={hip1} alt="" /></div>
                    <div className="col-12 col-lg-4"><img className="img-fluid" src={hip2} alt="" /></div>
                    <div className="col-12 col-lg-4"><img className="img-fluid" src={hip3} alt="" /></div>
                   
                </div>
                <div>
                    <img src={hip4} alt="" />
                    <img src={hip5} alt="" />
                    <img src={hip6} alt="" />
                </div>
                <p><span className="texto_bold">** não considerar pelo fato do número de ganhadores do respectivo grupo já ter sido atingido, devendo ser apurado o próximo número de acordo com o item 5.8.</span></p>
                <p><span className="texto_bold">5.10.1.</span> Assim sendo, após a identificação do Grupo (A, B ou C) a que pertence o titular do Número de Ordem Base distribuído, os demais potenciais ganhadores de cada grupo serão os portadores dos Números da Sorte correspondentes aos Números de Ordem distribuídos imediatamente superiores ao Número de Ordem Base distribuído, respectivamente, dentro da mesma série (<span className="underline">conforme hipótese 1 do item 5.10.</span>), até que se encontre a totalidade de ganhadores por grupo. Esgotadas as possibilidades superiores, serão apurados os números de ordem distribuídos imediatamente inferiores ao Número de Ordem Base Distribuído (conforme regra de aproximação do item 5.8). </p>
                <p><span className="texto_bold">5.10.2.</span> Seguindo o exemplo acima, ainda dentro da Série Vencedora, caso o próximo potencial Número de Ordem não tenha sido distribuído, esse deverá ser desconsiderado (<span className="underline">conforme hipótese 2 do item 5.10.</span>), passando-se para os demais números superiores distribuídos, continuando o mesmo processo de identificação dos números de acordo com seus respectivos grupos, até que se encontre o total de ganhadores de cada grupo, obedecendo-se sempre a regra de aproximação do item 5.8.</p>
                <p><span className="texto_bold">5.10.3.</span> Para fins de apuração dos contemplados, a identificação dos ganhadores terá sempre como ponto de partida o Número de Ordem Base distribuído <span className="underline">independentemente do Grupo</span> a que ele pertencer. A partir da identificação do seu respectivo grupo é que deverá ser observada a ordem dos próximos Números de Ordem de forma crescente, verificando-se a quais grupos eles pertencerem, até o limite da proporção da quantidade de contemplados prevista para cada grupo. </p>
                <p><span className="texto_bold">5.10.4.</span> E ainda, na hipótese de ser encontrado um potencial ganhador de um grupo que já atingiu a sua totalidade de vencedores naquele sorteio, este número deverá ser desconsiderado (<span className="underline">conforme hipótese 3 do item 5.10.</span>), continuando o mesmo processo com base na regra de aproximação do item 5.8, até que se encontre o total de ganhadores de cada grupo.</p>
                <p><span className="texto_bold">5.11.</span>  Fica desde já estabelecido que as mesmas regras dispostas nos itens <span className="texto_bold">5.3</span> e seguintes deverão ser aplicadas para o <span className="texto_bold">Sorteio Final</span>, na proporção e quantidade de pacotes de viagens destinados a cada Grupo, sendo, portanto, identificados os 64 (sessenta e quatro) ganhadores, sendo <span className="texto_bold underline">18 (dezoito) do Grupo A, 32 (trinta e dois) do Grupo B e 14 (quatorze) do Grupo C.</span></p>
                <p><span className="texto_bold">5.12</span>	 Os participantes selecionados nos sorteios serão considerados como <span className="underline">potenciais ganhadores</span> até que suas participações (dados cadastrais) e cumprimento dos requisitos estabelecidos pela Promotora (<span className="underline">valor mínimo mensal de R$5.000,00 (cinco mil reais) em compras de produtos das marcas comercializadas pela</span> <span className="texto_bold">Promotora - sorteio final - </span>e <span className="texto_bold">frequência</span> por, <span className="underline">ao menos, <span className="texto_bold">3 meses</span> do valor mínimo estipulado -  1°, 2º e 3° <span className="texto_bold"> sorteio trimestral</span></span>) para somente então, serem declarados ganhadores da promoção.</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>6.	PROCEDIMENTO DE VALIDAÇÃO DOS POTENCIAIS GANHADORES</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">6.1.</span>	De acordo com as regras acima expostas, a apuração e identificação dos potenciais ganhadores dos sorteios ocorrerá, respectivamente, nos dias <span className="texto_bold"> identificados no Quadro II do item 4.1 supra, às 15h</span>, na sede da <span className="texto_bold">DASA</span> situada na Rua Ernesto de Castro, 37 – Brás - CEP 03042-010 - São Paulo/SP.</p>
                <p><span className="texto_bold">6.1.1.</span> Fica desde já estabelecido que, em hipótese de caso fortuito ou força maior, como é o caso da pandemia de COVID-19, que impeça a realização presencial da apuração, esta será realizada por meio de teleconferência ou outro meio remoto que possibilite sua realização à distância, mantendo-se a data e o horário previstos acima.</p>
                <p><span className="texto_bold">6.2.</span>	Os potenciais ganhadores dos sorteios serão identificados conforme as regras deste Regulamento, <span className="texto_bold">ocasião em que será verificado o cumprimento integral das condições de participação</span>, sob pena de desclassificação.</p>
                <p><span className="texto_bold">6.3.</span>	A <span className="texto_bold">Promotora</span> poderá requerer, ainda, no prazo de apuração/validação dos ganhadores, o envio de documentos a fim de validar a participação e contemplação, tais como, mas não se limitando ao Contrato Social ou Estatuto, Ata de Eleição de Diretoria ou Procuração Pública com poderes de representação, os documentos pessoais do(s) representante(s) legal(is), entre outros.</p>
                <p><span className="texto_bold">No caso de solicitação e não cumprimento em até 72 (setenta e duas) horas corridas após o efetivo contato ou cumprimento incompleto, o Participante será desclassificado.</span> Nessa hipótese, será aplicada a <span className="texto_bold verde">Regra de Aproximação </span>prevista no item 5.8 deste Regulamento para identificar outro participante que comprovando o cumprimento de todas as condições de participação dentro do prazo assinalado pela <span className="texto_bold">DASA</span> será declarado como ganhador desta promoção.</p>
                <p><span className="texto_bold">6.3.1.</span>	Somente os potenciais ganhadores que cumprirem todos os requisitos estabelecidos neste Regulamento serão consagrados vencedores e farão jus à premiação.</p>
                <p><span className="texto_bold">6.3.2.	Cada empresa participante só poderá participar através do CNPJ Raiz, ou seja, as empresas participantes que tiverem filiais, terão todas as suas compras computadas em nome da Matriz, sendo certo que o CNPJ Raiz só poderá ser contemplado uma única vez nesta Promoção.</span></p>
                <p><span className="texto_bold">6.3.3.</span>	Caso a Extração da Loteria Federal não venha a ocorrer em qualquer uma das datas previstas, por qualquer motivo, para efeitos de apuração dos resultados desta Promoção, será considerada a data de Extração da Loteria Federal subsequente, mantidas inalteradas as demais disposições deste Regulamento.</p>
                <p><span className="texto_bold">6.4.</span>	Após a identificação das empresas ganhadoras, seus proprietários, sócios e/ou representantes legais deverão indicar 1 (uma) pessoa para usufruir da viagem, com idade igual ou maior que 18 (dezoito) anos, com inscrição regular no Cadastro de Pessoas Físicas (CPF), residentes e domiciliadas em território nacional, com passaporte com validade de, pelo menos, 6 (seis) meses, para o país de destino, assim como qualquer outro documento que se faça necessário. </p>
                <p><span className="texto_bold">6.5.</span>	Após a validação dos contemplados, serão elaboradas as Atas de cada Sorteio que serão entregues à SECAP/ME, quando da prestação de contas.</p>
                <p><span className="texto_bold">6.6.</span>	Os ganhadores validados serão comunicados sobre a sua contemplação no prazo de até 10 (dez) dias úteis, contados da data de sua identificação e validação, por telefone e/ou e-mail conforme os dados constantes do cadastro.</p>
                <p><span className="texto_bold">6.7.</span>	Será <span className="texto_bold">responsabilidade exclusiva dos Participantes</span> manterem seus dados atualizados no cadastro da Promoção e, ainda, verificar regularmente sua caixa de e-mails (caixa de entrada e de SPAM ou lixo eletrônico) e mensagem no WhatsApp, sendo os únicos responsáveis pela remoção de filtros e/ou solução de problemas de cunho tecnológico, ou outras configurações de segurança, que possam vir a suspender e/ou bloquear o recebimento de mensagens e notificações enviadas pela <span className="texto_bold">DASA</span>. </p>
            </div>
        </div>


        <div className="topic">
            <div className="topic_title">
                <h5>7.	DA ENTREGA DA PREMIAÇÃO</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">7.1</span>	A partir do momento da indicação da pessoa que irá usufruir da viagem, pela Pessoa Jurídica contemplada, os prêmios serão pessoais e intransferíveis, sendo vedado o pedido, pelo contemplado, para a sua troca ou substituição por dinheiro ou outro produto/serviço.</p>
                <p><span className="texto_bold">7.2</span> A formalização da entrega do prêmio será feita pela entrega de <span className="texto_bold">Carta Compromisso</span> ao CNPJ ganhador, no endereço informado no cadastro, em horário comercial, sem ônus, em até 30 (trinta) dias da data da respectiva apuração, e uma cópia desta será enviada às pessoas indicadas por ele para usufruir o prêmio.</p>
                <p><span className="texto_bold">7.3</span>	Considerando a peculiaridade da premiação direcionada especificamente aos clientes da <span className="texto_bold">DASA</span>, a Promotora Mandatária efetuará reservas exclusivas para a realização da viagem pelas pessoas indicadas pelos ganhadores. Por esta razão, <span className="texto_bold">a viagem deverá necessariamente ser usufruída no período supra especificado, conforme será instruído na Carta Compromisso.</span></p>
                <p><span className="texto_bold">7.4</span>	No ato da entrega da referida carta, cada representante legal, nos termos do Contrato Social ou Estatuto, deverá estar munido e entregar cópia de seus documentos pessoais (CPF, RG, comprovante de residência e outros que venham a ser exigidos pela <span className="texto_bold">Promotora</span>  e, ainda, assinar o Termo de Quitação e Entrega de Prêmio (que será preenchido em nome da pessoa jurídica contemplada) em 2 (duas) vias de igual teor e forma, sendo 1 (uma) devolvida para a Promotora.</p>
                <p><span className="texto_bold">7.5</span>	Em razão da pandemia de COVID-19, por caso fortuito ou força maior, eventuais atrasos na disponibilização e usufruto dos pacotes de viagem, serão previamente comunicados aos titulares do usufruto da premiação, cabendo à Promotora envidar todos os esforços para que o usufruto possa ser feito até maio de 2023, dentro das reais possibilidades, a critério da <span className="texto_bold">DASA</span>.</p>
                <p><span className="texto_bold">7.6</span>	É condição para usufruir dos prêmios que as pessoas indicadas detenham todos os documentos e condições necessárias, como passaporte e visto para o país de destino válidos, assim como qualquer outro documento, como vacinas exigidas por este, inclusive em razão da pandemia COVID-19. O prêmio será considerado entregue, após o envio da Carta Compromisso, ainda que o ganhador não venha a obter a documentação necessária para ingressar no país de destino.</p>
                <p><span className="texto_bold">7.7</span>	Fica estabelecido que, caso alguma pessoa indicada não compareça na data estipulada para embarque, será presumida a sua desistência voluntária e consequentemente a sua renúncia à utilização do seu prêmio, nada podendo ser reclamado a respeito.</p>
                <p><span className="texto_bold">7.8</span>	As despesas não descritas neste Regulamento, tais como, mas não se limitando, a qualquer quantia destinada a cobrir despesas pessoais e gerais de viagem do ganhador, telefonemas locais, interurbanos ou internacionais, lavanderia, compras, pay per view, gorjetas para carregadores de malas, tarifas para malas extras e/ou que excedam o peso, a quantidade ou as dimensões máximas permitidas, passeios e traslados não citados, locação de carros, utilização de cofres, lavanderia, bar, frigobar, serviço de quarto, serviço de revelação e fotografias, cabeleireiro, massagens, compras de qualquer natureza, consultas médicas e/ou medicamentos, bebidas e refeições em geral não previstas nas despesas, dentre outras que não tenham sido expressamente previstas neste Regulamento, serão de responsabilidade exclusiva de cada ganhador.</p>
                <p><span className="texto_bold">7.9</span>	Na hipótese de alteração da data do embarque por motivo de força maior, a <span className="texto_bold">DASA</span> se compromete a divulgar esta informação amplamente em todos os canais de divulgação da Promoção, tomando todas as providências necessárias para que as pessoas usufruam da viagem, nos termos estabelecidos neste Regulamento.</p>
                <p><span className="texto_bold">7.10</span>	As Promotoras não se responsabilizarão por quaisquer atos que as pessoas indicadas venham a cometer em afronta aos bons costumes, à moral e às leis, durante a viagem, seja em território nacional ou internacional, ou tampouco poderá ser de qualquer forma responsabilizada caso, por qualquer razão, seja negado seu visto ou ingresso no país de destino ou escalas, ou em qualquer outro país estrangeiro que venham a ingressar em função do prêmio, ou caso, por qualquer razão e a qualquer tempo, seja determinada sua extradição.</p>
                <p><span className="texto_bold">7.11</span>	Ao receber seu prêmio, cada ganhador o aceita sem imposição de qualquer outra obrigação por parte das Promotoras, isentando-as, assim como os seus respectivos empregados, diretores e gerentes ou qualquer outra pessoa diretamente envolvida na Promoção, de qualquer responsabilidade que possa decorrer, direta ou indiretamente, da sua participação nesta Promoção e/ou da aceitação e/ou uso do prêmio. </p>
                <p><span className="texto_bold">7.12</span>	No caso de alguma pessoa indicada vir a falecer antes da entrega do usufruto do prêmio, caberá à pessoa jurídica contemplada fazer nova indicação, nos prazos e nos termos deste regulamento.</p>
                <p><span className="texto_bold">7.13</span>	Caso não haja a entrega efetiva da premiação, o valor correspondente aos prêmios não entregues, recolhido pela <span className="texto_bold">DASA</span>, ao Tesouro Nacional, como renda da União, no prazo legal.</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>8 EXIBIÇÃO E COMPROVAÇÃO DA PROPRIEDADE DOS PRÊMIOS</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">8.1</span>	 A comprovação da propriedade dos prêmios dar-se-á, em <span className="texto_bold">até 8 (oito) dias antes da data de cada sorteio</span>, por meio de documento que ficará disponível na sede da <span className="texto_bold">DASA</span>, para eventual fiscalização e que serão apresentados à SECAP/ME, quando da prestação de contas, conforme determina o Artigo 15, § 1º do Decreto 70.951/72.</p>
                <p><span className="texto_bold">8.2</span>	Os prêmios oferecidos não serão exibidos, em virtude de sua natureza.</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>9 HIPÓTESES DE DESCLASSIFICAÇÃO E EXCLUSÃO</h5>
            </div>
            <div className="topic_text">
                 <p><span className="texto_bold">9.1	Estão impedidos de participar: (i)</span> pessoas físicas; <span className="texto_bold">(ii)</span> pessoas jurídicas que não cumprirem os requisitos deste Regulamento (item 1.2 e seguintes);</p>
                 <p><span className="texto_bold">9.2</span>	<span className="texto_bold underline">Será desclassificada da promoção, a qualquer momento</span>, sem prejuízo, ainda, das medidas cabíveis e/ou ação de regresso a ser promovida pelas Promotoras em face do infrator, a participação com fraude comprovada e/ou apresentando fortes indícios de fraude; efetuada por meio da obtenção de benefício/vantagem de forma ilícita; que não cumprir quaisquer das condições deste Regulamento, incluindo, mas não se limitando, aos seguintes casos, a participação: <span className="texto_bold">(i)</span> com número de inscrição no CNPJ incompatível com o do participante cadastrado na promoção; <span className="texto_bold">(ii)</span> com dados cadastrais de terceiros, incorretos, incompletos ou inverídicos; <span className="texto_bold">(iii)</span> em que não haja a efetiva confirmação da condição de participação; <span className="texto_bold">(iv)</span> que utilize mecanismos que criem condições irregulares e/ou desleais ou, ainda, que atentem contra os objetivos e condições de participação na Promoção; <span className="texto_bold">(v)</span> os participantes que não apresentarem a documentação exigida no momento da validação de sua participação dentro do prazo estabelecido neste Regulamento, quando solicitado; e (vi) participantes que realizarem  o seu cadastro na Campanha após o dia 31/03/2022.</p>
                 <p><span className="texto_bold">9.3</span>	Caso não se verifique a veracidade, correção ou presença das informações e condições exigidas neste Regulamento, o Participante cadastrado perderá o direito de participação nos sorteios, sendo o Número da Sorte correspondente considerado como não distribuído e o respectivo prêmio será atribuído a outro Participante, seguindo-se os critérios descritos neste Regulamento. Nesta hipótese, as Promotoras buscarão tantos números válidos quantos forem necessários, até que identificar um Participante cuja participação seja considerada regular, nos termos deste Regulamento, que será o ganhador do respectivo sorteio. </p>
                 <p><span className="texto_bold">9.4</span>	Caberá exclusivamente às Promotoras, por meio de uma comissão, avaliar e decidir sobre os casos omissos e as dúvidas suscitadas durante a realização da Promoção, sendo suas decisões soberanas e irrecorríveis.</p>
                 <p><span className="texto_bold">9.5</span>	As Promotoras poderão determinar a desclassificação de Participantes que cadastrarem dados irregulares, e/ou, ainda, que não possam ter sua autenticidade comprovada.</p>
                 <p><span className="texto_bold">9.6</span>	Em caso de desclassificação e/ou identificação das pessoas impedidas de participar havida após a realização do sorteio, que incluir o período de apuração do ganhador, mas antes da divulgação do contemplado, será aplicada a <span className="texto_bold verde">Regra de Aproximação</span> do Grupo a que o participante desclassificado pertencer para identificação do novo ganhador.</p>
                 <p><span className="texto_bold">9.7</span>	Em caso de desclassificação ou identificação de pessoas impedidas de participar posteriormente ao término da promoção, o Participante perderá o direito ao prêmio e o respectivo valor será recolhido ao Tesouro Nacional como renda da União Federal, no prazo de 10 (dez) dias contados do término da Promoção.</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>10	DIVULGAÇÃO DA PROMOÇÃO E DOS GANHADORES</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">10.1</span>	Apurados e devidamente validados os contemplados de cada sorteio, a relação dos ganhadores com seus respectivos números da sorte será oportunamente divulgada no Hotsite <a className="link-blue" href="https://www.sama100anos.com.br">www.sama100anos.com.br</a> e nas Redes Sociais da Promotora em até 10 (dez) dias úteis de cada apuração e ficará disponível para consulta, pelo menos, até 30 (trinta) dias contados do término da apuração dos sorteios desta campanha.</p>
                <p><span className="texto_bold">10.2</span>	A divulgação desta ação será feita por meio do hotsite da Promoção <a className="link-blue" href="https://www.sama100anos.com.br">www.sama100anos.com.br</a>, Redes Sociais e outros meios que as Promotoras julgarem necessários.</p>
                <p><span className="texto_bold">10.3</span>	As Promotoras comprometem-se a divulgar o Número do Certificado de Autorização SECAP no Regulamento que estará à disposição para consulta no hotsite <a className="link-blue" href="https://www.sama100anos.com.br">www.sama100anos.com.br</a> durante todo o período de participação, ficando dispensadas de sua aposição nas peças de divulgação.</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>11	TRATAMENTO DE DADOS PESSOAIS DOS REPRESENTANTES LEGAIS E DAS PESSOAS INDICADAS PARA USUFRUIR DO PRÊMIO</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">11.1</span>	Tanto os representantes legais como as pessoas indicadas para usufruir do prêmio ficam cientes que os dados pessoais informados serão tratados especificamente para a finalidade de entrega da premiação, contato e entrega da premiação.</p>
                <p><span className="texto_bold">11.1.1</span>	A <span className="texto_bold">Promotora</span>, neste momento, assume o compromisso de proteger os dados pessoais coletados, mantendo absoluta confidencialidade sobre tais informações, garantindo que, <span className="underline">excetuados os casos previstos em lei e ao fiel cumprimento da execução desta promoção, não serão compartilhados com terceiros a qualquer outro título.</span></p>
                <p><span className="texto_bold">11.2</span>	Internamente, os dados dos Participantes serão acessados somente por colaboradores da <span className="texto_bold">DASA</span> devidamente autorizados, respeitando os princípios inerentes ao tratamento de dados pessoais previstos na Lei Geral de Proteção de Dados, sempre com o objetivo de execução e operacionalização desta promoção, além do compromisso de confidencialidade e preservação da privacidade de acordo com este Regulamento.</p>
                <p><span className="texto_bold">11.3</span>	Os dados pessoais coletados para esta promoção ficarão armazenados para fins operacionais e obedecerão a padrões rígidos de confidencialidade e segurança. Nenhum documento, informação e/ou dado pessoal será divulgado e/ou compartilhado em nenhuma hipótese, salvo os casos acima previstos e/ou mediante ordem judicial e/ou por determinação regulatória ou legal.</p>
                <p><span className="texto_bold">11.4	As obrigações de confidencialidade acima previstas não se aplicam a informações disponibilizadas que (i)</span> forem de domínio público à época em que tiverem sido reveladas; <span className="texto_bold">(ii)</span> passarem a ser de domínio público após sua revelação, sem que a divulgação seja efetuada em violação ao disposto neste Regulamento; <span className="texto_bold">(iii)</span> devam ser reveladas em razão de uma ordem emitida por órgão administrativo ou judiciário com jurisdição sobre uma das partes, somente até a extensão de tal ordem; ou <span className="texto_bold">(iv)</span> forem independentemente obtidas ou desenvolvidas pela <span className="texto_bold">Promotora</span> sem qualquer violação das obrigações previstas neste Regulamento.</p>
                <p><span className="texto_bold">11.5</span>	As pessoas indicadas para usufruir da viagem deverão autorizar a utilização de seus nomes, imagens e sons de voz, em qualquer um dos meios escolhidos pela <span className="texto_bold">Distribuidora Automotiva</span>, para divulgação desta promoção, <span className="texto_bold">pelo período de 12 (doze) meses, contados da data de término da promoção, sem que, no entanto, haja obrigação da DASA.</span> Neste sentido, os participantes autorizam, desde já, a divulgação de sua logomarca, razão social e nome fantasia com ganhadores desta promoção. </p>
                <p><span className="texto_bold">11.6</span>	Serão mantidos na base de dados da <span className="texto_bold">DASA</span> apenas para fins de cumprimento legal e defesa em eventual processo administrativo ou judicial, (i) os dados dos representantes legais e pessoas indicadas para usufruir do prêmio, pelo prazo de 5 (cinco) anos, contados do término da promoção e (ii) os dados dos demais participantes, inclusive daqueles que eventualmente solicitarem o cancelamento da adesão ao Regulamento, até o recebimento do Ofício de Homologação do Processo de Prestação de Contas da presente promoção emitido pela SECAP/ME. Findo os prazos ora estipulados, os dados poderão ser eliminados.</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>12	DISPOSIÇÕES GERAIS</h5>
            </div>
            <div className="topic_text">
                <p><span className="texto_bold">12.1</span>	A presente distribuição de prêmios é gratuita, não cabendo qualquer ônus aos contemplados.</p>
                <p><span className="texto_bold">12.2</span>	As Promotoras não se responsabilizarão por eventuais prejuízos que os Participantes possam ter, oriundos da participação na Promoção, da aceitação, recebimento e/ou utilização do prêmio ou, ainda, de situações que estejam fora do seu controle, contanto que não fique demonstrada a responsabilidade das Promotoras em situações, tais como, em caráter meramente exemplificativo: <span className="texto_bold">(i)</span> por qualquer impedimento do Participante em se conectar à Internet, não garantindo o acesso ininterrupto ou livre de erros à Internet e ao hotsite da Promoção; <span className="texto_bold">(ii)</span> pelos cadastros que não forem realizados por problemas na transmissão de dados no servidor, em provedores de acessos dos usuários ou ainda por falta de energia elétrica, sem exclusão das demais situações decorrentes de caso fortuito ou força maior; <span className="texto_bold">(iii)</span> oscilações, interrupções, falhas de transmissão dos serviços de internet; <span className="texto_bold">(iv)</span> por danos de qualquer espécie de falhas causadas em virtude de acesso ao hotsite ou por impossibilidade de acessá-lo; <span className="texto_bold">(v)</span> por cadastros de dados perdidos, atrasados, incompletos, inválidos, extraviados ou corrompidos, os quais serão desconsiderados; e <span className="texto_bold">(vi)</span> por fraudes ou prejuízos ocasionados pela quebra de sigilo por parte do Participante em relação a seu login e senha.</p>
                <p><span className="texto_bold">12.3</span>	As Promotoras não poderão ser responsabilizadas pelo preenchimento incorreto dos requisitos obrigatórios por parte do Participante ou por qualquer situação que decorra de sua culpa exclusiva. As Promotoras também não serão responsáveis por transmissões de computador que estejam incompletas ou que falhem, bem como por falha técnica de qualquer tipo, incluindo, mas não se limitando, ao mau funcionamento eletrônico de qualquer rede, "hardware" ou "software", a disponibilidade e acesso à Internet ou ao hotsite desta Promoção, assim como por qualquer informação incorreta, vírus, falha de programação (bugs) ou violação por terceiros (hackers), falha humana, técnica ou de qualquer outro tipo que possa ocorrer durante o processamento do cadastro na Promoção, desde que tais acontecimentos não estejam direta ou indiretamente sujeitos ao controle das Promotoras, eximindo-se, por conseguinte, de qualquer responsabilidade proveniente de tais fatos e/ou atos.</p>
                <p><span className="texto_bold">12.4</span>	As dúvidas, controvérsias ou omissões decorrentes do regulamento desta Promoção serão, preliminarmente, dirimidas por seus respectivos organizadores e, posteriormente, submetidas ao órgão responsável pela autorização. </p>
                <p><span className="texto_bold">12.5</span>	Após o encerramento da campanha, a prestação de contas será encaminhada à SECAP dentro do prazo legal estabelecido na Portaria nº 41/2008, por intermédio do escritório de advocacia M.Godoy Consultoria Jurídica em Comunicação Publicitária com a documentação fornecida pela <span className="texto_bold">DASA</span>.</p>
                <p><span className="texto_bold">12.6</span>	Esta campanha está de acordo com a legislação vigente (Lei nº. 5.768/71, regulamentada pelo Decreto nº 70.951/72 e pela Portaria nº 41/08) e demais Atos Complementares, tendo obtido o Certificado de Autorização da Secretaria de Avaliação, Planejamento, Energia e Loteria do Ministério da Economia (SECAP/ME).</p>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>Certificado de Autorização SECAP/ME nº 04.014803/2021.</h5>
            </div>
        </div>

        <div className="topic">
            <div className="topic_title">
                <h5>ANEXO I – LISTA DE EMPRESAS ADERENTES</h5>
            </div>
            <div className="d-12">
                <img className="img-fluid" src={empresas} alt="Empresas aderentes" />
            </div>
        </div>

        
    </div>
   
   )
}