import React from 'react'
import './Footer.css'
// import seloPellegrinoBr from '../../assets/images/pellegrino-80.svg';
import dasa from '../../assets/images/sama/dasafooter.png'
import sama from '../../assets/images/sama/samafooter.png'
export default function Footer(){
    return(
        <div className="footer">
           <div className="container containre-box_center">
               <div className="container-main_footer col-12 col-sm-12 col-md-12">
               <div className="footer-logo d-block d-sm-block d-lg-none">
                   <img src={dasa} alt="Dasa Logo" />
                   <img src={sama} alt="Sama Logo" />
               </div>
               <div className="footer-info">
                   <p className="parag1 color-red">Dúvidas? Escreva para:</p>
                   <span><a href="mailto:atendimento@sama100anos.com.br" className="link-red_footer color-white">atendimento@sama100anos.com.br</a></span>
                   <p className="parag2 desktop">Promoção válida de 01/09/2021 a 31/07/2022. Certificado de autorização <br />
                    SECAP nº 04.014803/2021.  Imagens meramente ilustrativas.
                    </p>

                   <p className="parag2 mobile">Promoção válida de 01/09/2021 a 31/07/2022. Certificado de autorização
                    SECAP nº 04.014803/2021.  Imagens meramente ilustrativas.</p>    
               </div>
               <div className="footer-logo d-none d-sm-none d-lg-block">
                   <img src={dasa} alt="Dasa Logo" />
                   <img src={sama} alt="Sama Logo" />
               </div>
               </div>
           </div>
        </div>
    )
}