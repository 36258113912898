// import Bosch from '../../assets/images/carousel-imgs-1/bosch.svg'
// import Controil from '../../assets/images/carousel-imgs-1/controil.svg'
// import Frasle from '../../assets/images/carousel-imgs-1/frasle.svg'
// import Fremax from '../../assets/images/carousel-imgs-1/fremax.svg'

// import Lemforder from '../../assets/images/carousel-imgs-1/lemforder.svg'
// import Luk from '../../assets/images/carousel-imgs-1/luk.svg'
// import Ina from '../../assets/images/carousel-imgs-1/ina.svg'
// import Fag from '../../assets/images/carousel-imgs-1/fag.svg'

// import MetalLeve from '../../assets/images/carousel-imgs-1/metaleve.svg'
// import Mahle from '../../assets/images/carousel-imgs-1/mahle.svg'
// import Nakata from '../../assets/images/carousel-imgs-1/nakata.svg'
// import Sachs from '../../assets/images/carousel-imgs-1/sachs.svg'

// import Trw from '../../assets/images/carousel-imgs-1/trw.svg'
// import Varga from '../../assets/images/carousel-imgs-1/varga.svg'
// import Zf from '../../assets/images/carousel-imgs-1/zf.svg'


// import AxiosMonroe from '../../assets/images/carousel-imgs-2/axios.svg'
// import Sabo from '../../assets/images/carousel-imgs-2/sabo.svg'
// import Eaton from '../../assets/images/carousel-imgs-2/eaton.svg'
// import Gates from '../../assets/images/carousel-imgs-2/gates.svg'
// import Sampel from '../../assets/images/carousel-imgs-2/sampel.svg'
// import Skf from '../../assets/images/carousel-imgs-2/skf.svg'
// import Philips from '../../assets/images/carousel-imgs-2/philips.svg'
// import Spicer from '../../assets/images/carousel-imgs-2/spicer.svg'
// import Tecfil from '../../assets/images/carousel-imgs-2/tecfil.svg'
// import Timken from '../../assets/images/carousel-imgs-2/timken.svg'

// import Aplic from '../../assets/images/carousel-imgs-3/aplic.svg'
// import AuthoMix from '../../assets/images/carousel-imgs-3/authomix.svg'
// import Bomber from '../../assets/images/carousel-imgs-3/bomber.svg'
// import Dyna from '../../assets/images/carousel-imgs-3/dyna.svg'
// import Fania from '../../assets/images/carousel-imgs-3/fania.svg'
// import Loctite from '../../assets/images/carousel-imgs-3/loctite.svg'
// import MaxGear from '../../assets/images/carousel-imgs-3/maxgear.svg'
// import Taramps from '../../assets/images/carousel-imgs-3/taramps.svg'
// import Urba from '../../assets/images/carousel-imgs-3/urba.svg'
// import Brosol from '../../assets/images/carousel-imgs-3/brosol.svg'
// import Valeo from '../../assets/images/carousel-imgs-3/valeo.svg'
// import Viemar from '../../assets/images/carousel-imgs-3/viemar.svg'
// import Wahler from '../../assets/images/carousel-imgs-3/wahler.svg'
import React from 'react'
import allLogos from '../../assets/images/sama/patrocinadores.jpg'
import allLogosMobile from '../../assets/images/sama/patrocinadores_mobile.jpg'

import './AllLogosHome.css'

export default function AllLogosHome(){
    return(
    <div className="logo-all-section">
        <div className="container container_paddings">
            <img className="d-none d-lg-block" src={allLogos} alt=""  />
            <img className="d-md-block d-lg-none" src={allLogosMobile} alt="" />
            {/* <div className="row">
            <div className="sec1">
                    <img src={Bosch} alt="" />
                    <img src={Controil} alt="" />
                    <img src={Frasle} alt="" />
                    <img src={Fremax} alt="" />
                    <img src={Lemforder} alt="" />
                    <img src={Luk} alt="" />
                    <img src={Ina} alt="" />
                    <img src={Fag} alt="" />
            </div>
            <div className="sec1">
                    <img src={MetalLeve} alt="" />
                    <img src={Mahle} alt="" />
                    <img src={Nakata} alt="" />
                    <img src={Sachs} alt="" />
                    <img src={Trw} alt="" />
                    <img src={Varga} alt="" />
                    <img src={Zf} alt="" />
            </div>
            </div>
            
            <div className="row sec-middle-allLogos">
                <div className="sec1">
                    <img src={AxiosMonroe} alt="AxiosMonroe" />
                    <img src={Sabo} alt="Sabo" />
                    <img src={Eaton} alt="Eaton" />
                    <img src={Gates} alt="Gates" />
                    <img src={Sampel} alt="Sampel" />
                    <img src={Skf} alt="Skf" />
                </div>
            </div>

            <div className="row sec-three">
                <div className="col-2 col-2-border">
                    <div className="row">
                        <div className="sec1">
                            <img src={Philips} alt="Philips" />
                            <img src={Spicer} alt="Spicer" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="sec1">
                            <img src={Tecfil} alt="Tecfil" />
                            <img src={Timken} alt="Timken" />
                        </div>
                    </div>
                </div>


                <div className="col-7">
                <div className="row">
                        <div className="sec1">
                            <img src={Aplic} alt="Aplic" />
                            <img src={AuthoMix} alt="AuthoMix" />
                            <img src={Bomber} alt="Bomber" />
                            <img src={Dyna} alt="Dyna" />
                            <img src={Fania} alt="Fania" />
                            <img src={Loctite} alt="Loctite" />
                            <img src={MaxGear} alt="MaxGear" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="sec1">
                            <img src={Taramps} alt="Taramps" />
                            <img src={Urba} alt="Urba" />
                            <img src={Brosol} alt="Brosol" />
                            <img src={Valeo} alt="Valeo" />
                            <img src={Viemar} alt="Viemar" />
                            <img src={Wahler} alt="Wahler" />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
    )
}