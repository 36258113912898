import React from 'react'
import Aazul from '../../assets/images/sama/Aazul.png'
import Bazul from '../../assets/images/sama/Bazul.png'
import Cazul from '../../assets/images/sama/Cazul.png'

import Aamarelo from '../../assets/images/sama/Aamarelo.png'
import Bamarelo from '../../assets/images/sama/Bamarelo.png'
import Camarelo from '../../assets/images/sama/Camarelo.png'

import Afinal from '../../assets/images/sama/Afinal.png'
import Bfinal from '../../assets/images/sama/Bfinal.png'
import Cfinal from '../../assets/images/sama/Cfinal.png'


import mala from '../../assets/images/sama/mala.png'
import passagem from '../../assets/images/sama/passagem.png'
import tabelaTrismestral from '../../assets/images/sama/tri.png'
import tabelaFinal from '../../assets/images/sama/final.png'

export default function TabelaSorteios(){
    return(
        
        <div className="texto-regulamento tabelas">

            <div className="tabela_trimestral">
                <div className="tri_titulo">
                <div className="">
                    <img src={mala} alt="" />
                </div>  
                <div className="tabela_titulo">
                    <p className="tabela_titulo-p">SORTEIOS TRIMESTRAIS</p>
                    <p className="texto_cinza_tabela"><span>Serão sorteadas 36 viagens:</span></p>
                </div>  
                </div>
                <div className="row">
                    <div className="row">

                    <div className="col-12 col-lg-4">
                    <div className="">
                        <p className="tabela_titulo-p">1º TRIMESTRE</p>
                        <p className="texto_amarelo_tabela"><span className="texto_amarelo">SET / OUT / NOV 21</span></p>
                    </div> 
                    <div className="grupos">
                        <img src={Aazul} alt="A" title="Grupo A - valor mensal de compra &gt; (acima de) R$ 25.000,00"/>
                        <img src={Bazul} alt="B" />
                        <img src={Cazul} alt="C" />
                    </div>
                    </div>

                    <div className="col-12 col-lg-4">
                    <div className="">
                        <p className="tabela_titulo-p">2º TRIMESTRE</p>
                        <p className="texto_amarelo_tabela"><span className="texto_amarelo">DEZ 21 / JAN 22 / FEV 22</span></p>
                    </div> 
                    <div className="grupos">
                        <img src={Aazul} alt="A" />
                        <img src={Bazul} alt="B" />
                        <img src={Cazul} alt="C" />
                    </div>
                    </div>

                    <div className="col-12 col-lg-4">
                    <div className="">
                        <p className="tabela_titulo-p">3º TRIMESTRE</p>
                        <p className="texto_amarelo_tabela"><span className="texto_amarelo">MAR / ABR / MAI 22</span></p>
                    </div> 
                    <div className="grupos">
                        <img src={Aazul} alt="A" />
                        <img src={Bazul} alt="B" />
                        <img src={Cazul} alt="C" />
                    </div>
                    </div>

                    </div>
                    
                    <div className="row mg_tabela">
                        <div className="col-12">
                        <div className="">
                            <p className="tabela_titulo-p">TOTAL DE VIAGENS NOS SORTEIOS TRIMESTRAIS</p>
                        </div>  
                        <div className="grupos">
                            <img src={Aamarelo} alt="A" />
                            <img src={Bamarelo} alt="B" />
                            <img src={Camarelo} alt="C" />
                        </div>     
                        </div>
                    </div>



                </div>
                
            </div>

            <div className="tabela_final">
            <div className="tri_titulo">
                <div className="">
                    <img src={passagem} alt="" />
                </div>  
                <div className="tabela_titulo">
                    <p className="tabela_titulo-p">SORTEIO FINAL</p>
                    <p className="texto_cinza_tabela"><span>No fim da campanha, mais 64 viagens serão sorteadas:</span></p>
                </div>  
                <div className="grupos">
                            <img src={Afinal} alt="A" />
                            <img src={Bfinal} alt="B" />
                            <img src={Cfinal} alt="C" />
                        </div>  
                </div>
            </div>
        </div>

    
    )
}