import React from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

import '../Home/Home.css'

import './Ganhadores.css'

import AllLogosHome from '../../components/AllLogosHome/AllLogosHome'
import Footer from '../../components/Footer/Footer'

import { Link, useHistory } from 'react-router-dom'

import { logout } from '../../services/auth'

import { useEffect, useState } from 'react'


import api from '../../services/api'

import Modal from '../../components/Modal/Modal'
import Menu from '../../components/Menu/Menu'

import Regulamento from '../../components/Regulamento/Regulamento'
import MenuMobile from '../../components/MenuMobile/MenuMobile'

import ganhadoresImg from '../../assets/images/sama/ganhadoresimg.png'

import {isAuthenticated} from '../../services/auth'

export default function Home(props){
    const history = useHistory()
    const [classNameOpenMenu, setClassNameOpenMenu] = useState("hideMenu")
    const [activeClass, setActiveClass] = useState(false)
    const [activeClassMobile, setActiveClassMobile] = useState(false)


    const [profile, setProfile] = useState({});
    const [AddDarkBarMenuMobile, setAddDarkBarMenuMobile] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [isOpenModalSucess, setIsOpenModalSucess] = useState(false)
    const [sucessMessage, setSucessMessage] = useState('')
    const [messageTitile, setMessageTittle] = useState('')
    const [isToggleMobile, setIsToggleMobile] = useState(false);
    const [isOpenRegulamento, setIsOpenRegulamento] = useState(false)




    const [simbolRazao, setSimbolRazao] = useState(false)
    const [simbolCNPJ, setSimbolCNPJ] = useState(false)
    const [simbolCumpom, setSimbolCupom] = useState(false)


    const [final, setFinal] = useState([ 
        {name:'AUTO PEC BRASILANDIA LTDA', cnpj: '44742617', cupomSorteado:'94-94471'},
        {name:'PERIM COMERCIO DE AUTO PECAS LTDA', cnpj: '51195790', cupomSorteado:'94-95548'},
        {name:'EXPRESSO NORDESTE LINHAS ROD LTDA', cnpj: '76533777', cupomSorteado:'94-98014'},
        {name:'MERCADOCAR MERCANTIL DE PECAS LTDA', cnpj: '43158302', cupomSorteado:'94-98044'},
        {name:'MOTOR AUTO UBERABA EIRELI', cnpj: '41718941', cupomSorteado:'94-98072'},
        {name:'COMERCIAL AUTOMOTIVA S/A', cnpj: '45987005', cupomSorteado:'94-98343'},
        {name:'DEVANIR DE FIGUEIREDO EPP', cnpj: '47843891', cupomSorteado:'94-99321'},
        {name:'JOSE CARLOS AGAPITO & CIA LTDA', cnpj: '59800144', cupomSorteado:'94-93168'},
        {name:'DORIVAL AUTO PECAS LTDA EPP', cnpj: '55483440', cupomSorteado:'94-92771'},
        {name:'PAULO KAZUNORI ITO EPP', cnpj: '1621013', cupomSorteado:'94-92532'},
        {name:'RD COM AUTO PEC LTDA ME', cnpj: '81811143', cupomSorteado:'94-92471'},
        {name:'MIRO AUTO PECAS LTDA', cnpj: '97435382', cupomSorteado:'94-92045'},
        {name:'ROBERTO CARLOS LUCATELLI & CIA LTDA', cnpj: '67596445', cupomSorteado:'94-91722'},
        {name:'GAPLAN CAMINHOES LESTE LTDA.', cnpj: '65409872', cupomSorteado:'94-91423'},
        {name:'TRANSWOLFF TRANSPORTES E TURISMO LTDA', cnpj: '58322512', cupomSorteado:'94-91413'},
        {name:'MMARRA DISTRIBUIDORA AUTOMOTIVA LTDA', cnpj: '67380170', cupomSorteado:'94-91313'},
        {name:'MARCO GILBERTO MULLER BECKER', cnpj: '87082905', cupomSorteado:'94-91188'},
        {name:'DIESEL SETE PECAS LTDA', cnpj: '64435670', cupomSorteado:'94-90950'},
        
        {name:'TRANSP COLET GLORIA LTDA', cnpj: '76491109', cupomSorteado:'94-98878'},
        {name:'JOAO PRETTE  CIA LTDA - EPP', cnpj: '45661303', cupomSorteado:'94-92947'},
        {name:'APT AUTO PECAS EIRELI', cnpj: '41873662', cupomSorteado:'94-92286'},
        {name:'AUTOGERAL COM PEC LTDA', cnpj: '48857700', cupomSorteado:'94-91333'},
        {name:'COMERCIO DE AUTO PECAS BADU LTDA EPP', cnpj: '76344696', cupomSorteado:'94-88616'},
        {name:'MARCIO ANTONIO LAURANO', cnpj: '97533859', cupomSorteado:'94-86468'},
        {name:'TRANSPORTADORA VANTROBA LTDA', cnpj: '78147105', cupomSorteado:'94-83937'},
        {name:'TRANSPLAN AUTO PECAS LTDA', cnpj: '44763589', cupomSorteado:'94-82603'},
        {name:'BAUXITA PECAS E PNEUS LTDA EPP', cnpj: '64304199', cupomSorteado:'94-82473'},
        {name:'SOLA E GIRALDI LTDA EPP', cnpj: '72556871', cupomSorteado:'94-82290'},
        {name:'D S L MEC TECNODIESEL COM PECAS LTDA ME', cnpj: '62602396', cupomSorteado:'94-81311'},
        {name:'CENTRODIESEL RET MEC VEIC LTDA', cnpj: '78586906', cupomSorteado:'94-77827'},
        {name:'DUARTE DIESEL LTDA ME', cnpj: '85291458', cupomSorteado:'94-75271'},
        {name:'V M H TRANSPORTES LTDA', cnpj: '79745139', cupomSorteado:'94-73232'},
        {name:'ROSSINI TRANSPORTES LTDA', cnpj: '80831696', cupomSorteado:'94-71886'},
        {name:'BIGUACU TRANSP COL ADM E PART', cnpj: '83875005', cupomSorteado:'94-71354'},
        {name:'POSTO DE SERV N SRA APARECIDA', cnpj: '49805963', cupomSorteado:'94-70207'},
        {name:'AUTO PECAS NETUNO LTDA', cnpj: '81526345', cupomSorteado:'94-70016'},
        {name:'AUTO VIAC S JOSE PINHAIS LTDA', cnpj: '81305377', cupomSorteado:'94-68886'},
        {name:'NEREU RETIF MOTORES LTDA', cnpj: '82974643', cupomSorteado:'94-67012'},
        {name:'TRANSPORTADORA TUR NATAL LTDA', cnpj: '47202007', cupomSorteado:'94-66590'},
        {name:'DA CAZ AUTO PECAS LTDA', cnpj: '85349579', cupomSorteado:'94-61514'},
        {name:'CHEVE DIST. DE AUTO PECAS LTDA', cnpj: '61349890', cupomSorteado:'94-58787'},
        {name:'MOTRIX TRANSP SERV LTDA', cnpj: '73717415', cupomSorteado:'94-58658'},
        {name:'VIACAO TAMANDARE LTDA', cnpj: '77525673', cupomSorteado:'94-55130'},
        {name:'RICARDO LAURO SANTOS COSTA ME', cnpj: '74015215', cupomSorteado:'94-55065'},
        {name:'MICROTUR TRANSPORTADORA TURISTICA LTDA', cnpj: '53124228', cupomSorteado:'94-52732'},
        {name:'PARDIESEL PECAS LTDA', cnpj: '41765215', cupomSorteado:'94-46842'},
        {name:'G. C. DE MEDEIROS - EIRELI', cnpj: '40783565', cupomSorteado:'94-40170'},
        {name:'DOMINGOS LUIZ PEDROTTI - EPP', cnpj: '92039387', cupomSorteado:'94-40014'},
        {name:'TWM TRANSPORTES ESPECIAIS LTDA', cnpj: '65271314', cupomSorteado:'94-36922'},
        {name:'OURO DIESEL PECAS E SERVICOS LTDA - EPP', cnpj: '70077391', cupomSorteado:'94-33782'},

        {name:'MEC E AUTO PECAS ANGEVAL LTDA', cnpj: '02718361', cupomSorteado:'94-93438'},
        {name:'PERDIESEL SERV E PEC LTDA', cnpj: '22080501', cupomSorteado:'94-93443'},
        {name:'D IMPORT COM PEC ACESS AUTOM LTDA ME', cnpj: '07222024', cupomSorteado:'94-93457'},
        {name:'GILSON DE SOUZA SILVA', cnpj: '1371811', cupomSorteado:'94-93463'},
        {name:'GL-LISPECAL DISTR DE PECAS EIRELI', cnpj: '3672208', cupomSorteado:'94-93469'},
        {name:'ADEMIR TADEU LOPES', cnpj: '37222239', cupomSorteado:'94-93535'},
        {name:'DIOGO PANKA - EPP', cnpj: '18963772', cupomSorteado:'94-93582'},
        {name:'AUTOPECAS E MECANICA SODIESEL LTDA ME', cnpj: '12410024', cupomSorteado:'94-93625'},
        {name:'CARECA AUTO PECAS LTDA ME', cnpj: '11709160', cupomSorteado:'94-93675'},
        {name:'SOUZA COELHO COM AUTO PEC ACESS LTDA', cnpj: '10679873', cupomSorteado:'94-93801'},
        {name:'BORGHI E COELHO LTDA', cnpj: '32987770', cupomSorteado:'94-93885'},
        {name:'OLIMPO AUTO CENTER LTDA', cnpj: '04157225', cupomSorteado:'94-93887'},
        {name:'UNI-FREIOS DIADEMA AUTO PEC E SER DIESEL', cnpj: '04042547', cupomSorteado:'94-93952'},
        {name:'MIGLIATI E LUMINI LTDA ME', cnpj: '07088863', cupomSorteado:'94-93960'},
    ])

    const [trimestral, setTrimestral] = useState([
        {name:'SERMI AUTO PECAS LTDA', cnpj: '58419227', cupomSorteado:'56-41593'},
        {name:'J L FURLAN & CIA LTDA', cnpj: '45109691', cupomSorteado:'56-42158'},
        {name:'K S OKAMOTO E CIA LTDA EPP', cnpj: '82446121', cupomSorteado:'56-43920'},
        {name:'COM AUTO PEC SALAZAR LTDA', cnpj: '94906294', cupomSorteado:'56-44011'},
        {name:'TRANSPORTE COLETIVO CIDADE CANCAO LTDA', cnpj: '79118311', cupomSorteado:'56-55115'},
        {name:'TRANSPORTE COLETIVO ESTRELA LTDA', cnpj: '83050781', cupomSorteado:'56-59334'},
        {name:'ICAVEL VEICULOS LTDA', cnpj: '84938430', cupomSorteado:'56-63577'},
        {name:'REGIONAL COMERCIO DE PECAS DIESEL LTDA', cnpj: '93688380', cupomSorteado:'56-69249'},
        {name:'SEM LIMITES ROLS ACESS LTDA', cnpj: '67374470', cupomSorteado:'56-69772'},
        {name:'AUTO PECAS TUNICAR LTDA', cnpj: '53537460', cupomSorteado:'56-86318'},
        {name:'VITORIA PECAS E SERV DE AUT EIRELI', cnpj: '7312947', cupomSorteado:'56-41598'},
        {name:'A. LIMA E CIA LTDA', cnpj: '3419771', cupomSorteado:'56-42039'},
    ])

    const [trimestral2, setTrimestral2] = useState([
        {name:'AUTO MECANICA M Z LTDA - ME', cnpj: '66084377', cupomSorteado:'13-59358'},
        {name:'CONQUISTA PECAS E ACESSORIOS LTDA', cnpj: '42893818', cupomSorteado:'13-59825'},
        {name:'AUTO PECAS OLAVIO LTDA – EPP', cnpj: '66447277', cupomSorteado:'13-60101'},
        {name:'RENATA AUTO PECAS LTDA', cnpj: '71504856', cupomSorteado:'13-60518'},
        {name:'AUTO ELETRICA CARRETEIRO LTDA ME', cnpj: '59819052', cupomSorteado:'13-60777'},
        {name:'MEC DIESEL IND MEC CRUZ LTDA EPP', cnpj: '77064038', cupomSorteado:'13-74354'},
        {name:'BRASAUTO CACADOR LTDA', cnpj: '83054312', cupomSorteado:'13-82801'},
        {name:'GUARINAO PECAS E SERVICOS LTDA – ME', cnpj: '66748120', cupomSorteado:'13-84050'},
        {name:'AUTO ELET KIAN DE PIRAPOZINHO LTDA EPP', cnpj: '54798673', cupomSorteado:'13-90439'},
        {name:'COMBREK AUTO PECAS E SRV LTDA  ME', cnpj: '62954318', cupomSorteado:'13-98869'},
        {name:'NRD - NORDESTE DISTRIBUIDORA DE PECAS LT', cnpj: '26737000', cupomSorteado:'13-58989'},
        {name:'J S DISTR PECAS S/A', cnpj: '4185877', cupomSorteado:'13-59060'},
    ])


    const [trimestral3, setTrimestral3] = useState([
        {name:'BORMANA COMERCIO DE AUTOPECAS LTDA', cnpj: '93527794', cupomSorteado:'12-39190'},
        {name:'MOLAS ASA BRANCA LTDA ME', cnpj: '83658443', cupomSorteado:'12-40568'},
        {name:'MARCELO CELUPPI EIRELI', cnpj: '95887766', cupomSorteado:'12-41340'},
        {name:'TIETE VEICULOS LTDA', cnpj: '68857085', cupomSorteado:'12-44577'},
        {name:'COML AUTO PECAS WINGERT LTDA EPP', cnpj: '87790036', cupomSorteado:'12-44152'},
        {name:'COML A P UNIVERSO DE ADAM LTDA', cnpj: '43007731', cupomSorteado:'12-54598'},
        {name:'JOSE ROBERTO LEITE FRANCA EPP', cnpj: '53884540', cupomSorteado:'12-62420'},
        {name:'TRATORAMA PECAS E SERVICOS DE MANUTENCAO', cnpj: '83776054', cupomSorteado:'12-63922'},
        {name:'NOVA DISTRIBUIDORA DE VEICULOS LTDA', cnpj: '72855505', cupomSorteado:'12-64008'},
        {name:'INSULAR TRANSP COLETIVOS LTDA', cnpj: '83874867', cupomSorteado:'12-64353'},
        {name:'MULT DIESEL AUTO PEC E SERV LTDA', cnpj: '9005279', cupomSorteado:'12-39510'},
        {name:'M G B VELASQUES E CIA LTDA EPP', cnpj: '17784171', cupomSorteado:'12-40038'},

    ])
    // const [final, setFinal] = useState([])


    const [disabledWinnerFinal, setDisabledWinnerFinal] = useState(false)
    const [disabledWinnerTrismestral, setDisabledWinnerTrismestral] = useState(false)
    const [isAsc, setIsAsc] = useState(false)

    const [itensPerPage, setItensPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    const pages = Math.ceil(final.length / itensPerPage);

    const startIndex = currentPage * itensPerPage;
    const endIndex = startIndex + itensPerPage;
    const currentItens = final.slice(startIndex, endIndex)

    function filterRazaoSocial(type){
        if(isAsc){ 
            type.sort((a, b) => (a.name > b.name) ? 1 : -1);
    
            }else{
            type.sort((a, b) => (a.name > b.name) ? -1 : 1);
    
            }
            setIsAsc(!isAsc)
            setSimbolRazao(!simbolRazao)
        }
    
        function filterRazaoCupom(type){
        if(isAsc){ 
            type.sort((a, b) => (a.cupomSorteado > b.cupomSorteado) ? 1 : -1);
            }else{
            type.sort((a, b) => (a.cupomSorteado > b.cupomSorteado) ? -1 : 1);
            }
            setIsAsc(!isAsc)
            setSimbolCupom(!simbolCumpom)
        }
        
        function filterCNPJ(type){
        if(isAsc){ 
            type.sort((a, b) => a.cnpj - b.cnpj);
            }else{
            type.sort((a, b) => b.cnpj - a.cnpj);
            }
            setIsAsc(!isAsc)
            setSimbolCNPJ(!simbolCNPJ)
        }


    useEffect(()=>{

        if(trimestral.length === 0){
            setDisabledWinnerTrismestral(true);
        }else{
            setDisabledWinnerTrismestral(false);
        }
        if(final.length === 0){
            setDisabledWinnerFinal(true);
        }else{
            setDisabledWinnerFinal(false);
        }
        

        if(props.location.pathname === '/ganhadores'){
            window.scrollTo(0, 2930)
        }

            
        window.onscroll = () => handleScroll()   

        const token = localStorage.getItem("sama-token");
        const headers = {
            'Authorization': 'Bearer' + token
        }  
        async function loadApiProfle(){


            try{
                const response = await api.get(`/v1/account/profile`,{
                    headers: headers
                })  
                
                setProfile(response.data.data)

            }catch(e){
                if(e.response?.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            
        }
        loadApiProfle();

        setTimeout(() => {
            const ghostDiv = document.querySelector('.space_fix')
            if (!ghostDiv) {
                const textSlider = document.querySelector('#text-slider')
                textSlider.classList.add('mt-ghost-div')
            }
        }, 500)

    }, [])
    // console.log(profile)

    const handleLogout = function(){
        if(localStorage.getItem("sama-token")){  
            logout()
        }
    }

    function onClickCloseError(){
        setIsOpen(false)
    }
    function onClickOpen(){
        setIsOpen(true)
    }
    function handleScroll() { 
        if (document.documentElement.scrollTop >= 30) {
            setAddDarkBarMenuMobile(true)
            setClassNameOpenMenu('showMenu d-none d-sm-none d-md-block d-lg-block')

         }else{
             setClassNameOpenMenu('hideMenu')
             setAddDarkBarMenuMobile(false)
         }

         if(document.documentElement.scrollTop >= 750 && document.documentElement.scrollTop < 1700){
            setActiveClass(true)
        }else{
            setActiveClass(false)
        }
         if(document.documentElement.scrollTop >= 400 && document.documentElement.scrollTop < 1700){
            setActiveClassMobile(true)
        }else{
            setActiveClassMobile(false)
        }
    }


    function onClickOpen(){
        setIsOpenRegulamento(true)
        setIsToggleMobile(prevToggle => !prevToggle)
    }



  
    function onClickCloseRegulamento(){
        setIsOpenRegulamento(false)
        

    }
    function toggleMobile(){
        setIsToggleMobile(prevToggle => !prevToggle)
    }


    return(
        <>
        
            {/* MENU MOBILE */}
            {/* <div className={`${AddDarkBarMenuMobile ? 'addDark': 'clearDark'} ${document.documentElement.scrollTop >= 30 ? 'fixed-menu' : ''}`}> */}
            <div className={`${AddDarkBarMenuMobile ? 'addDark': 'clearDark'} fixed-menu`}>
            <MenuMobile>
            <ul className="navbar-nav me-auto mb-lg-0 ">
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <a href="/inicio#como-participar"><span>COMO PARTICIPAR</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <Link  className="active" href="/ganhadores"><span>GANHADORES</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item" >
                        <Link onClick={()=> onClickOpen()} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-expanded="true" aria-label="Toggle navigation"><span>REGULAMENTO</span></Link>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li ">
                        <div className="mobile_item">
                            <a  href="/contato"><span>CONTATO</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <Link aria-current="page" to="/seus-numeros-da-sorte"><span>SEUS NÚMEROS DA SORTE</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item" >
                            
                            <Link   onClick={handleLogout} to="/#"><span>SAIR</span></Link>
                        </div>
                    </li>
                </ul>                
            </MenuMobile>
            </div>
            <div className="space_fix d-block d-lg-none"></div>
            <div id="menuHeader" className={`${classNameOpenMenu} d-none d-sm-none d-md-block d-lg-block`}>
                <Menu>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu_home">
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <a className={`menu-link`} aria-current="page" href="/inicio#como-participar"><span>COMO PARTICIPAR</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <Link className={`menu-link active`} aria-current="page" href="/ganhadores"><span>GANHADORES</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div  className="div-one">
                            <Link className="menu-link" onClick={()=> onClickOpen()}><span>REGULAMENTO</span></Link>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <a className="menu-link" href="/contato"><span>CONTATO</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <Link className="menu-link" aria-current="page" to="/seus-numeros-da-sorte"><span>SEUS NÚMEROS DA SORTE</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div  className="div-one">
                            
                            <Link className="menu-link" onClick={handleLogout} to="/#"><span>SAIR</span></Link>
                        </div>
                    </li>
                </ul>
                </Menu>
            </div>
            
        <div className="ganhadores">
            <img src={ganhadoresImg} alt="Contato" />
            <h1>Ganhadores</h1>
            <p className="subT1">Veja as empresas premiadas</p>
            <p className="subT2">Confira quem já garantiu presença nesse <br /> embarque pra lá de emocionante!</p>
        </div>


            <div className="second-section ganhadores_page">
                <div className="container position-relative">
                    {/* <div className="card_ganhadores">
                        <h1>FIQUE DE OLHO!</h1>
                        <p className="parag_ganhadores">O primeiro sorteio trimestral será realizado no dia <span className='data_ganhadores'>15/12/2021</span>  e o resultado será <br /> divulgado aqui pra todo mundo ver. Enviaremos um e-mail quando ele for publicado! </p>
                    </div> */}


                <div className="card-ganhadores">
                    <h1>Sorteio trimestral 1</h1>
                    <p className="texto_amarelo">Setembro a novembro de 2021 (realizado em 15/12/2021)</p>
                    <p className="numero_base_azul">Número do sorteio base</p>
                    <p className="numero_base_num">56-41058</p>
                    <div className="ganhadores_main">


                        <div class="" id="" >

                            <div class="">
                            {trimestral.length > 0 && (
                                <>
                            <div className="tabela_overflow">
                            <table class="table table-striped tabela_ganhadores">
                                <thead>
                                    <tr>

                                    <th scope="col"><button className="btn_filter" onClick={()=>filterRazaoSocial(trimestral)}>{simbolRazao ? <div>RAZÃO SOCIAL <span>&#9650;</span></div> : <div>RAZÃO SOCIAL  <span>&#9660;</span></div>}</button> </th>
                                    <th scope="col"><button className="btn_filter" onClick={()=>filterCNPJ(trimestral)}>{simbolCNPJ ? <div>CNPJ <span>&#9650;</span></div> : <div>CNPJ <span>&#9660;</span></div>}</button></th>
                                    <th scope="col"><button className="btn_filter" onClick={()=>filterRazaoCupom(trimestral)}>{simbolCumpom ? <div>CUPOM SORTEADO <span>&#9650;</span></div> : <div>CUPOM SORTEADO <span>&#9660;</span></div>}</button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trimestral.map((e)=>{
                                        return(
                                    <tr >
                                    <td className="td_name">{e.name}</td>
                                    <td>{e.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
                                    <td>{e.cupomSorteado}</td>
                                    </tr>

                                        )
                                    })}
                    
                                </tbody>
                                </table>
                            </div>

                                </>
                            )}
                            </div>
                        </div>
                    </div>
                    </div>


                    <div className="card-ganhadores">
                    <h1>Sorteio trimestral 2</h1>
                    <p className="texto_amarelo">Dezembro de 2021 a Fevereiro de 2022 (realizado em 16/03/22.)</p>
                    <p className="numero_base_azul">Número do sorteio base</p>
                    <p className="numero_base_num">13-58974</p>
                    <div className="ganhadores_main">


                        <div class="" id="" >

                            <div class="">
                            {trimestral2.length > 0 && (
                                <>
                            <div className="tabela_overflow">
                            <table class="table table-striped tabela_ganhadores">
                                <thead>
                                    <tr>

                                    <th scope="col"><button className="btn_filter" onClick={()=>filterRazaoSocial(trimestral2)}>{simbolRazao ? <div>RAZÃO SOCIAL <span>&#9650;</span></div> : <div>RAZÃO SOCIAL  <span>&#9660;</span></div>}</button> </th>
                                    <th scope="col"><button className="btn_filter" onClick={()=>filterCNPJ(trimestral2)}>{simbolCNPJ ? <div>CNPJ <span>&#9650;</span></div> : <div>CNPJ <span>&#9660;</span></div>}</button></th>
                                    <th scope="col"><button className="btn_filter" onClick={()=>filterRazaoCupom(trimestral2)}>{simbolCumpom ? <div>CUPOM SORTEADO <span>&#9650;</span></div> : <div>CUPOM SORTEADO <span>&#9660;</span></div>}</button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trimestral2.map((e)=>{
                                        return(
                                    <tr >
                                    <td className="td_name">{e.name}</td>
                                    <td>{e.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
                                    <td>{e.cupomSorteado}</td>
                                    </tr>

                                        )
                                    })}
                    
                                </tbody>
                                </table>
                            </div>

                                </>
                            )}
                            </div>
                        </div>
                    </div>
                    </div>




                    <div className="card-ganhadores">
                    <h1>Sorteio trimestral 3</h1>
                    <p className="texto_amarelo">Março a maio de 2022 (realizado em 15/06/22.)</p>
                    <p className="numero_base_azul">Número do sorteio base</p>
                    <p className="numero_base_num">12-39148</p>
                    <div className="ganhadores_main">


                        <div class="" id="" >

                            <div class="">
                            {trimestral3.length > 0 && (
                                <>
                            <div className="tabela_overflow">
                            <table class="table table-striped tabela_ganhadores">
                                <thead>
                                    <tr>

                                    <th scope="col"><button className="btn_filter" onClick={()=>filterRazaoSocial(trimestral3)}>{simbolRazao ? <div>RAZÃO SOCIAL <span>&#9650;</span></div> : <div>RAZÃO SOCIAL  <span>&#9660;</span></div>}</button> </th>
                                    <th scope="col"><button className="btn_filter" onClick={()=>filterCNPJ(trimestral3)}>{simbolCNPJ ? <div>CNPJ <span>&#9650;</span></div> : <div>CNPJ <span>&#9660;</span></div>}</button></th>
                                    <th scope="col"><button className="btn_filter" onClick={()=>filterRazaoCupom(trimestral3)}>{simbolCumpom ? <div>CUPOM SORTEADO <span>&#9650;</span></div> : <div>CUPOM SORTEADO <span>&#9660;</span></div>}</button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trimestral3.map((e)=>{
                                        return(
                                    <tr >
                                    <td className="td_name">{e.name}</td>
                                    <td>{e.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
                                    <td>{e.cupomSorteado}</td>
                                    </tr>

                                        )
                                    })}
                    
                                </tbody>
                                </table>
                            </div>

                                </>
                            )}
                            </div>
                        </div>
                    </div>
                    </div>

                <div className="card-ganhadores">
                    <h1>Final</h1>
                    <p className="texto_amarelo">Realizado em 20/08/2022</p>

                    <p className="numero_base_azul">Número do sorteio base</p>
                    <p className="numero_base_num">94-93366</p>
                    <div className="ganhadores_main">


                        <div class="" id="" >

                            <div class="">
                            {final.length > 0 && (
                                <>
                            <div className="tabela_overflow">
                            <table class="table table-striped tabela_ganhadores">
                                <thead>
                                    <tr>

                                    <th scope="col"><button className="btn_filter" onClick={()=>filterRazaoSocial(final)}>{simbolRazao ? <div>RAZÃO SOCIAL <span>&#9650;</span></div> : <div>RAZÃO SOCIAL  <span>&#9660;</span></div>}</button> </th>
                                    <th scope="col"><button className="btn_filter" onClick={()=>filterCNPJ(final)}>{simbolCNPJ ? <div>CNPJ <span>&#9650;</span></div> : <div>CNPJ <span>&#9660;</span></div>}</button></th>
                                    <th scope="col"><button className="btn_filter" onClick={()=>filterRazaoCupom(final)}>{simbolCumpom ? <div>CUPOM SORTEADO <span>&#9650;</span></div> : <div>CUPOM SORTEADO <span>&#9660;</span></div>}</button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItens.map((e)=>{
                                        return(
                                    <tr >
                                    <td className="td_name">{e.name}</td>
                                    <td>{e.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
                                    <td>{e.cupomSorteado}</td>
                                    </tr>

                                        )
                                    })}
                    
                                </tbody>
                                </table>
                            </div>
                            {final.length > 2 && (

                                <div className="pagination">{Array.from(Array(pages), (item, index)=> {
                                    return <button 
                                    className="btn_pagination"
                                    value={index}
                                    style={index === currentPage ? {backgroundColor: '#30569C', color:'#FFF'}: null} 
                                    onClick={(e)=> setCurrentPage(Number(e.target.value))}>{index + 1}</button>
                                })}</div>                              
                            )}
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                    </div>

                </div>


            </div>


			<AllLogosHome />
			<Footer />
            <Modal isOpen={isOpen} close={onClickCloseError}>
                <p className="modal-title">{messageTitile}</p>
                <p className="erro-msg">{messageError}</p>
                <div className="btn-area_next">
                            <button type="submit" onClick={onClickCloseError}>
                                <span className="button-content">FECHAR</span>
                            </button>                           
                </div>
            </Modal>
            <Modal isOpen={isOpenRegulamento} close={onClickCloseRegulamento}>
                <div className="btn-area_next regulamento">
                    <div className="texto-regulamento">
                    <Regulamento/>
                    </div>
                    <button type="submit" onClick={onClickCloseRegulamento}>
                        <span className="button-content">FECHAR</span>
                    </button>                           
                </div>
            </Modal>
		</>
	)
}