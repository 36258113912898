import React from 'react';
import OwlCarousel from 'react-owl-carousel2'
import 'react-owl-carousel2/lib/styles.css'
import './Banner.css'
import diamante from '../../assets/images/sama/carousel-desk/DIAMANTE2.jpg'
import ouro from '../../assets/images/sama/carousel-desk/OURO2.jpg'
import prata from '../../assets/images/sama/carousel-desk/PRATA2.jpg'
import bronze from '../../assets/images/sama/carousel-desk/BRONZE2.jpg'
import apoiadores from '../../assets/images/sama/carousel-desk/APOIADORES2.jpg'

import BannerLogon from '../../assets//images/sama/banner-login.jpg'

export default function Banner(){
    const options = {
        items:1,
        nav: true,
        navText:["<",">"],
        rewind: true,
        autoplay: true,
        loop:true,
        dots:false,
        
    };

    return(
    <div className="banner-side d-none d-sm-none d-md-none d-lg-block col-12 col-sm-12 col-lg-7 col-md-6 ">
        <div className="banner col-12 col-sm-12">
            <img src={BannerLogon} alt="" />
        </div>
        <div className="sponsors d-none d-sm-none d-md-none d-lg-block col-12 col-sm-12 col-md-12 col-lg-12">
        <OwlCarousel  options={options}>

            <div className="container-carousel">
                <img src={diamante} alt="Logos 1" />
            </div>
            <div className="container-carousel">
                <img src={ouro} alt="Logos 2" />
            </div>
            <div className="container-carousel">
                <img src={prata} alt="Logos 3" />
            </div>
            <div className="container-carousel">
                <img src={bronze} alt="Logos 4" />
            </div>
            <div className="container-carousel">
                <img src={apoiadores} alt="Logos 5" />
            </div>
        </OwlCarousel>
        </div>   
    </div>        
    )
}