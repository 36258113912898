import React, {useState, useEffect} from 'react'
import diamante from '../../assets/images/sama/carousel-mobile/diamante_mobile.png'
import ouro from '../../assets/images/sama/carousel-mobile/ouro_mobile.png'
import prata from '../../assets/images/sama/carousel-mobile/prata_mobile.png'
import bronze from '../../assets/images/sama/carousel-mobile/bronze_mobile.png'
import apoiadores from '../../assets/images/sama/carousel-mobile/apoiadores_mobile.jpg'

import OwlCarousel from 'react-owl-carousel2'

import Dasa from '../../assets/images/sama/dasa.png'
import Sama from '../../assets/images/sama/sama.png'


export default function CarouselMobile(){
    const [lowResolution, setLowResolution] = useState(false);

    useEffect(()=> {
        if(window.innerHeight <= 670){
            setLowResolution(true)
        }
    }, [])


    const optionsMobile = {
        items:1,
        nav: false,
        rewind: true,
        autoplay: true,
        dots:false,
        loop:true
    };
    return(
        <>
        <OwlCarousel  options={optionsMobile}>
        <div className="container-carousel">
            <img src={diamante} alt="Logos 1" />
        </div>
        <div className="container-carousel">
            <img src={ouro} alt="Logos 2" />
        </div>
        <div className="container-carousel">
            <img src={prata} alt="Logos 3" />
        </div>
        <div className="container-carousel">
            <img src={bronze} alt="Logos 4" />
        </div>
        <div className="container-carousel">
            <img src={apoiadores} alt="Logos 5" />
        </div>
        </OwlCarousel>  
        <div className="logosOfc">
            <img src={Dasa} alt="" />
            <img src={Sama} alt="" />
        </div>
        <div className="line_divider"></div>
        <div className="container">
        <div className="d-lg-none">
        <div className="text-footer responsive-txt footer-relative">
            Dúvidas? Escreva para: <a className="link-blue" href="mailto:atendimento@sama100anos.com.br">atendimento@sama100anos.com.br</a>
        </div>                            
        </div>
        <div className="d-none d-lg-block text-footer responsive-txt" style={{position: lowResolution ? 'relative' : 'absolute'}}>
            Dúvidas? Escreva para: <a className="link-blue" href="mailto:atendimento@sama100anos.com.br">atendimento@sama100anos.com.br</a>
        </div>  

        <p className="text-footer responsive-txt last-text">
        Promoção válida de 01/09/2021 a 31/07/2022. <br />
        Certificado de autorização SECAP nº 04.014270/2021. <br />
        Imagens meramente ilustrativas.

        </p>      
        </div>
        </>
    )
}