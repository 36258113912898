import React from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

import './Home.css'

import Cesto from '../../assets/images/sama/cestosama.png'
import mala from '../../assets/images/sama/mala.png'
import passagem from '../../assets/images/sama/passagem.png'
import tabelaTrismestral from '../../assets/images/sama/tri.png'
import tabelaFinal from '../../assets/images/sama/final.png'




import grafico from '../../assets/images/sama/grafico.png'
import cupom from '../../assets/images/sama/cupom.png'

import catar from '../../assets/images/sama/catar.png'
import AllLogosHome from '../../components/AllLogosHome/AllLogosHome'
import Footer from '../../components/Footer/Footer'

import { Link, useHistory } from 'react-router-dom'

import { logout } from '../../services/auth'

import { useEffect, useState } from 'react'
import api from '../../services/api'

import Modal from '../../components/Modal/Modal'
import Menu from '../../components/Menu/Menu'
import Regulamento from '../../components/Regulamento/Regulamento'
import MenuMobile from '../../components/MenuMobile/MenuMobile'
import TabelaSorteios from '../../components/TabelaSorteios/TabelaSorteios'
import Calendario from '../../components/Calendario/Calendario'


import BannerHome from '../../assets/images/sama/banner_desk.png'
import BannerHomeMobile767 from '../../assets/images/sama/banner_mobile.jpg'
import {isAuthenticated} from '../../services/auth'
export default function Home(props){
    const history = useHistory()
    
    const [classNameOpenMenu, setClassNameOpenMenu] = useState("hideMenu")
    const [profile, setProfile] = useState({});
    const [AddDarkBarMenuMobile, setAddDarkBarMenuMobile] = useState(false)
    const [activeClass, setActiveClass] = useState(false)
    const [activeClassMobile, setActiveClassMobile] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenTabelaModal, setIsOpenTabelaModal] = useState(false)
    const [isOpenCalendario, setIsOpenCalendario] = useState(false)

    const [modalInfo, setModalInfo] = useState(true);
    useEffect(()=>{

        

        if(props.location.pathname === '/inicio'){
            window.scrollTo(0, 0)
        }

        if(props.location.hash === '#como-participar'){
            document.getElementById('como-participar').scrollIntoView()
        }
        
        window.onscroll = () => handleScroll()        
        const token = localStorage.getItem("sama-token");
        const headers = {
            'Authorization': 'Bearer' + token
        }  
        async function loadApiProfle(){


            try{
                const response = await api.get(`/v1/account/profile`,{
                    headers: headers
                })  
                setProfile(response.data.data)
            }catch(e){
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
            }
            
        }
        loadApiProfle();



    }, [])
    // console.log(profile)

    const handleLogout = function(){
        if(localStorage.getItem("sama-token")){            
            logout()
        }
    }

    function onClickCloseError(){
        setIsOpen(false)
    }
    function onClickCloseTabela(){
        setIsOpenTabelaModal(false)
    }
    function onClickCloseCalendario(){
        setIsOpenCalendario(false)
    }

    function onClickCloseInfo(){
        setModalInfo(false)
    }

    



    function onClickOpen(){
        setIsOpen(true)
    }
    function onClickOpenTabela(){
        setIsOpenTabelaModal(true)
    }
    function onClickOpenCalendario(){
        setIsOpenCalendario(true)
    }


    function handleScroll() { 
        if (document.documentElement.scrollTop >= 30) {
            setAddDarkBarMenuMobile(true)
            setClassNameOpenMenu('showMenu d-none d-sm-none d-md-block d-lg-block')

         }else{
             setClassNameOpenMenu('hideMenu')
             setAddDarkBarMenuMobile(false)
         }

         if(document.documentElement.scrollTop >= 750 && document.documentElement.scrollTop < 1700){
            setActiveClass(true)
        }else{
            setActiveClass(false)
        }
         if(document.documentElement.scrollTop >= 400 && document.documentElement.scrollTop < 1700){
            setActiveClassMobile(true)
        }else{
            setActiveClassMobile(false)
        }
    }


     
    function clickGoParticipe() {
        window.location.hash = "";
        window.location.hash = "#como-participar";
        
    }


    return(
        <>
        
            {/* MENU MOBILE */}
            {/* <div className={`${AddDarkBarMenuMobile ? 'addDark': 'clearDark'} ${document.documentElement.scrollTop >= 30 ? 'fixed-menu' : ''}`}> */}
            <div className={`${AddDarkBarMenuMobile ? 'addDark': 'clearDark'} fixed-menu`}>
            <MenuMobile>
            <ul className="navbar-nav me-auto mb-lg-0 ">
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <a  onClick={()=> clickGoParticipe()}  className={`${activeClassMobile ? 'active':''}`}  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-expanded="true" aria-label="Toggle navigation" href="#"><span>COMO PARTICIPAR</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item">
                            <a   href="/ganhadores"><span>GANHADORES</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item" >
                        <Link onClick={()=> onClickOpen()} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-expanded="true" aria-label="Toggle navigation"><span>REGULAMENTO</span></Link>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item">
                            <a   href="/contato"><span>CONTATO</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div >
                            <div className="mobile_item">
                            <Link aria-current="page" to="/seus-numeros-da-sorte"><span>SEUS NÚMEROS DA SORTE</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="mobile_item" >
                            
                            <Link   onClick={handleLogout} to="/#"><span>SAIR</span></Link>
                        </div>
                    </li>
                </ul>                
            </MenuMobile>
            </div>
            
            <div id="menuHeader" className={`${classNameOpenMenu} d-none d-sm-none d-md-block d-lg-block`}>
                <Menu>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu_home">
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <a className={`${activeClass ? 'active':''} menu-link`} aria-current="page" href="/inicio#como-participar"><span>COMO PARTICIPAR</span></a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <a className="menu-link" href="/ganhadores"><span>GANHADORES</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div  className="div-one">
                            <Link className="menu-link" onClick={()=> onClickOpen()}><span>REGULAMENTO</span></Link>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <a className="menu-link" href="/contato"><span>CONTATO</span></a>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div className="div-one">
                            <div className="div-reset-border">
                            <Link className="menu-link" aria-current="page" to="/seus-numeros-da-sorte"><span>SEUS NÚMEROS DA SORTE</span></Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-item-li">
                        <div  className="div-one">
                            
                            <Link className="menu-link" onClick={handleLogout} to="/#"><span>SAIR</span></Link>
                        </div>
                    </li>
                </ul>
                </Menu>
            </div>
            <div className="home container-banner_head"  id="top">
            <img src={BannerHome} alt="" className="d-none d-lg-block"/>
            <img src={BannerHomeMobile767} alt="" className="d-lg-none"/>
        </div>
        <div className="second-section">
            <div className="container position-relative">
            <span  id="como-participar" className="position-absolute"></span>
                <div className="title-red">
                    <h1 className="d-none d-lg-block">SAMA LADO A LADO <br /> COM VOCÊ NO CATAR EM 2022.</h1>
                    <h1 className="d-block d-sm-block d-md-block d-lg-none mobile_title">SAMA LADO A LADO <br /> COM VOCÊ NO CATAR EM 2022.</h1>
                </div>
                <div className="indormations header_text">
                   <p className="desktop">
                   Em 2022, a Sama completará um século de muita história no mercado automotivo. <br /> <span>E para comemorar, queremos 
                   levar você para aproveitar experiências inesquecíveis em Dubai</span>, <br /> um dos lugares mais incríveis do mundo,
                    com um dia especial no Catar. Haja coração!
                   
                   </p>
                   <p className="mobile">
                   Em 2022, a Sama completará um século de muita história no mercado automotivo. <span>E para comemorar, queremos 
                   levar você para aproveitar experiências inesquecíveis em Dubai</span>, um dos lugares mais incríveis do mundo,
                    com um dia especial no Catar. Haja coração!
                   </p>
                </div>

            <div className="row itens-list">
                <div className="col-md-4 col-sm-6 col-xs-12 item_icon">
                    <div className="top">
                        <img src={Cesto} alt="Cesto" />
                    </div>
                    <div className="title-how">
                        <p>VEJA COMO PARTICIPAR</p>
                    </div>
                    <div className="bottom">
                        <p>De 01/09/2021 a 31/07/2022, os participantes serão <br /> divididos em 3 grupos: <span className="text-span-bld">A</span>, <span className="text-span-bld">B</span> e <span className="text-span-bld">C</span> de acordo com a <br /> média mensal de compras. A cada <span className="text-span-bld">R$5 mil</span><br /> em compras na Sama ou na Laguna, a empresa ganha <span className="text-span-bld">01 número da sorte</span> para participar dos sorteios. Serão três sorteios trimestrais e um grande sorteio no final da campanha. <br /> <span className="text-span-bld">São 100 chances de ganhar!</span></p>
                        
                    </div>
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12 item_icon">
                <div className="top">
                    <img src={mala} alt="" />
                </div>  
                <div className="title-how">
                    <p>SORTEIOS TRIMESTRAIS</p>
                </div>
                <div className="bottom">
                <p>Para participar, sua empresa precisa comprar <br /> o mínimo de <span className="text-span-bld">R$5 mil em todos os meses <br /> do trimestre.</span> Caso não consiga em um dos <br /> meses, a empresa ficará de fora do sorteio <br /> relativo àquele período.</p>
                </div>  
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12 item_icon">
                <div className="top">
                    <img src={passagem} alt="" />
                </div>  
                <div className="title-how">
                    <p>SORTEIO FINAL</p>
                </div>
                <div className="bottom">
                <p>Para participar do sorteio final, sua empresa <br /> precisa comprar, pelo menos, <span className="text-span-bld">R$5 mil</span> <br /> na Sama ou na Laguna. Lembrando que a cada R$5 mil <br />em compras, ela ganha <span className="text-span-bld">01 número <br /> da sorte</span> para concorrer.</p>
                </div>  
                </div>
                <div className="row">
                    <div className="container text-center">
                        <div className="red-shape-bt home">
                            <div>
                                <Link to="#" onClick={()=> onClickOpenTabela()}>VEJA A TABELA DOS SORTEIOS</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            </div>



            <div className="how-participe">
                <div className="text-imp">
                    <p className="textp-red">Importante!</p>
                </div>
                <div className="indormations">
                    
                  <p>Cada empresa poderá ser premiada somente uma vez. Ou seja, se ela for contemplada <br className="d-none d-lg-block" /> nos
                    sorteios trimestrais, não poderá concorrer nos demais sorteios. Caso ela não seja contemplada <br className="d-none d-lg-block"/>
                    em nenhum sorteio trimestral, os cupons serão utilizados no sorteio final.</p>  
                </div>
            </div>
            
        </div>

        <div className="second-section">
            <div className="container position-relative">
            <span  id="como-participar" className="position-absolute"></span>
                <div className="title-red">
                    <h1 className="d-none d-lg-block">ACELERADORES: <br /> GANHE NÚMEROS DA SORTE EXTRAS.</h1>
                    <h1 className="d-block d-sm-block d-md-block d-lg-none mobile_title">ACELERADORES: <br /> GANHE CUPONS EXTRAS.</h1>
                </div>
                <div className="indormations header_text">
                   <p className="desktop">
                   Você pode aumentar as chances de ganhar essa viagem aproveitando os aceleradores. Veja como!
                   
                   </p>
                   <p className="mobile">
                   Você pode aumentar as chances de ganhar essa viagem aproveitando os aceleradores. Veja como!
                   </p>
                </div>

            <div className="row itens-list">
                <div className="col-md-4 col-sm-6 col-xs-12 item_icon">
                    <div className="title-how">
                        <p>CLIENTES PRONTO</p>
                    </div>
                    <div className="bottom">
                        <p>Os clientes "Pronto" que mantiverem a <br className="d-none d-lg-block"/> <span className="text-span-bld">frequência de compras trimestral de R$5 mil</span>,<br className="d-none d-lg-block"/> ganharão <span className="text-span-bld">01 número da sorte</span> a mais por trimestre para participar dos sorteios. Caso haja falha em um <br className="d-none d-lg-block"/> dos meses, o cupom extra não será válido.</p>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12 item_icon">
                <div className="title-how">
                    <p>MARCA BÔNUS DA SEMANA</p>
                </div>
                <div className="bottom">
                <p>Toda semana uma das marcas patrocinadoras <br className="d-none d-lg-block"/> será a <span className="text-span-bld">Marca Bônus da Semana.</span> A cada <br className="d-none d-lg-block"/> <span className="text-span-bld">R$1 mil</span> em compras dessa marca na Sama ou na Laguna,<br className="d-none d-lg-block"/> sua empresa ganha <span className="text-span-bld">01 número da sorte extra.</span></p>
                </div>  
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12 item_icon"> 
                <div className="title-how">
                    <p>MARCA BÔNUS DO MÊS</p>
                </div>
                <div className="bottom">
                <p>Todos os meses também teremos uma <br className="d-none d-lg-block"/> <span className="text-span-bld">Marca Bônus do Mês.</span> A cada <span className="text-span-bld">R$3 mil</span> <br className="d-none d-lg-block"/> comprados dessa marca na Sama ou na Laguna, <br className="d-none d-lg-block"/> sua empresa ganha <span className="text-span-bld">01 número da sorte extra.</span></p>
                </div>  
                </div>
                <div className="row">
                    <div className="container text-center">
                        <div className="red-shape-bt home">
                            <div>
                                <Link className="d-none d-lg-block"  to="#" onClick={()=> onClickOpenCalendario()}>VEJA O CALENDÁRIO  DAS MARCAS BÔNUS SEMANAIS E MENSAIS</Link>
                                <Link className="d-md-block d-lg-none" to="#" onClick={()=> onClickOpenCalendario()}>VEJA O CALENDÁRIO  DAS MARCAS BÔNUS</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <br />
            </div>
            
        </div>

            <div className="poster">
                <img src={catar} alt="poster" className="d-none d-lg-block" />
                <img src={catar} alt="poster" className="d-block d-sm-block d-md-block d-lg-none" />
            </div>

            <div className="second-section">
                <div className="container position-relative">
                <span  className="position-absolute"></span>

                    <div className="title-red">
                        <h1 className="d-none d-lg-block">MAS, ATENÇÃO!</h1>
                        <h1 className="d-block d-sm-block d-md-block d-lg-none mobile_title">MAS, ATENÇÃO!</h1>
                    </div>
                    
                    <div className="indormations header_text">
                    <p className="desktop">
                    Os aceleradores serão válidos apenas se a sua empresa <br /> atingir a meta de <span className="text-span-bld">R$5 mil</span> em compras no mês.
                    
                    </p>
                    <p className="mobile">
                    Os aceleradores serão válidos apenas se a sua empresa atingir a meta de <span className="text-span-bld">R$5</span> mil em compras no mês.
                    </p>
                    </div>
                    <br />
                    <div className="row itens-list">
                    <div className="container text-center">
                        <div className="red-shape-bt home">
                            <div>
                                <Link to="#" onClick={()=> onClickOpen()}>CONSULTE O REGULAMENTO COMPLETO</Link>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
            </div>

        <div className="cupons-section">
            <div className="cupons-section_box col-12 col-sm-12 col-md-12">
                <div className="cupons-img">
                    <img src={cupom} alt="Cupom" />
                    {/* <h4>AGUARDE!</h4> */}
                    <h4>CONSULTE AQUI SUA QUANTIDADE <br /> DE NÚMEROS DA SORTE</h4>
                    <p className="">Confira as compras realizadas e quantos números da sorte sua empresa já acumulou.</p>
                    {/* <p>Em breve você poderá acompanhar o extrato de compras <br className="d-none d-lg-block" /> e números da sorte conquistados pela sua empresa nesta área.</p> */}
                </div>
                <div className="white-shape-bt">
                        <div>
                            <Link to="/seus-numeros-da-sorte">VER NÚMEROS DA SORTE</Link>
                        </div>
					</div>
				</div>
			</div>
			<Modal isOpen={isOpen} close={onClickCloseError}>
				<div className="btn-area_next regulamento">
					<div className="texto-regulamento">
						<Regulamento />
					</div>
					<button type="submit" onClick={onClickCloseError}>
						<span className="button-content">FECHAR</span>
					</button>
				</div>
			</Modal>

			<Modal isOpen={isOpenTabelaModal} close={onClickCloseTabela}>
            <div className="btn-area_next regulamento">
                <TabelaSorteios />
                <button type="submit" onClick={onClickCloseTabela}>
                    <span className="button-content">FECHAR</span>
                </button>
            </div>   
			</Modal>

			<Modal isOpen={isOpenCalendario}   close={onClickCloseCalendario}>
            <div className="btn-area_next regulamento">
                <Calendario />
                <button type="submit" onClick={onClickCloseCalendario}>
                    <span className="button-content">FECHAR</span>
                </button>
            </div>   
			</Modal>

            
			<Modal isOpen={modalInfo}   close={onClickCloseInfo}>
            <div className="btn-area_next regulamento">
                <h1 className='modalInfo-title'>ATENÇÃO</h1>
                <p className='modalInfoP'>Todas as condições e informações referentes à campanha <br /> <span className='modalInfo-text'>Sama 100 Anos</span> são veiculadas em nosso site. <br/> 
                Não é permitida a divulgação desta campanha em qualquer outro <br /> site ou veículo que não pertença à Sama ou ao Grupo Comolatti. 
                </p>
                <button type="submit" onClick={onClickCloseInfo}>
                    <span className="button-content">VOLTAR</span>
                </button>
            </div>   
			</Modal>

			<AllLogosHome />
			<Footer />

		</>
	)
}