import React, { useEffect, useState } from 'react'
import Modal from '../../components/Modal/Modal'
import { Link } from 'react-router-dom'
import {logout} from '../../services/auth'
import Regulamento from '../../components/Regulamento/Regulamento'
import Logo from '../../assets/images/sama/logo-home.png'
import LogoSmall from '../../assets/images/sama/sama_logo_small.png'
import './Menu.css'

export default function Menu({children}){

    const [isOpen, setIsOpen] = useState(false)

    function goTop(){
        window.scrollTo(0, 0)
    }

    function onClickCloseError(){
        setIsOpen(false)
    }
    function onClickOpen(){
        setIsOpen(true)
    }
    
    const handleLogout = function(){
        if(localStorage.getItem("sama-token")){   
            logout()
        }
    }
    return(
        <>
       
        <div className="container-fluid full d-md-none d-lg-block">
        <div className="container">
        <nav className="navbar nav-bar-reset navbar-expand-lg navbar-light">
        <div className="container-fluid">
                    {document.documentElement.scrollTop >= 30 ?
                     (
                        <Link className="navbar-brand menu-home" to="/inicio" onClick={goTop}>
                        <img src={LogoSmall} alt="" />
                        </Link>
                     ):
                     (
                        <Link className="navbar-brand menu-home" to="/inicio" onClick={goTop}>
                        <img src={Logo} alt="" />
                        </Link>
                     )}
               

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu_home">
                <li className="nav-item dropdown">
                </li>
            </ul>
            <div className="d-flex">
                {children}
            </div>
            </div>
        </div>
        </nav>
        </div>
        <Modal isOpen={isOpen} onClickCloseError={onClickCloseError}>
                <div className="btn-area_next regulamento">
                    <div className="texto-regulamento">
                    <Regulamento/>
                    </div>
                    <button type="submit" onClick={onClickCloseError}>
                        <span className="button-content">FECHAR</span>
                    </button>                           
                </div>
        </Modal>
        </div>
        </>
    )

}