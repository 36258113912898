import React, { useState } from 'react';
import LogoMobilePreto from '../../assets/images/logo_mobile_preto.svg'
import Logo from '../../assets/images/sama/sama-logo.png'
import { Link } from 'react-router-dom';
import {logout} from '../../services/auth'

import Modal from '../../components/Modal/Modal'
import Regulamento from '../Regulamento/Regulamento';
import './MenuMobile.css'

export default function MenuMobile({children}){
    const menu_hide = document.querySelector('#navbarSupportedContent')
    const [isToggleMobile, setIsToggleMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    function toggleMobile(){
        setIsToggleMobile(prevToggle => !prevToggle)
    }
    function onClickOpen(){
        setIsOpen(true)
        setIsToggleMobile(prevToggle => !prevToggle)
    }
    const handleLogout = function(){
        if(localStorage.getItem("sama-token")){
            logout()
        }
    }
    function onClickCloseError(){
        setIsOpen(false)
    }

    function clickGoTop() {
        window.location.hash = "";
        window.location.hash = "#top";
        menu_hide.classList.add('hide');
        menu_hide.classList.remove('show');
        
    }
    return(
        <div id="menuHeader2" className={`d-block d-lg-none`}>
        <nav className="navbar navbar-expand-lg nav-bar-reset navbar-light">
            <div className={`${isToggleMobile ? 'isToggle':''} container-fluid mobile`}>
                
                <a onClick={()=> clickGoTop()} className="navbar-brand hide_menu menu_paddings" href="/inicio#top"  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"><img className="logo_mobile_l" src={Logo} alt="" /></a>
                <button className="mobile_toggler navbar-toggler"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                
                <span className="white-dot navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-lg-0 menu_home">
                    <li className="nav-item dropdown">
                    </li>
                </ul>
                <div className="d-flex">
                {children}
                </div>
                </div>
            </div>
        </nav>
    <Modal isOpen={isOpen} onClickCloseError={onClickCloseError}>
                <div className="btn-area_next regulamento">
                    <div className="texto-regulamento">
                    <Regulamento/>
                    </div>
                    <button type="submit" onClick={onClickCloseError}>
                        <span className="button-content">FECHAR</span>
                    </button>                           
                </div>
        </Modal>
        </div>
    )
}